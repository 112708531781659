// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState, useContext } from 'react'
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useParams } from 'react-router-dom'
// We use Auth0 as our current authentication and authorization solution
import { AuthContext } from '../Auth'
// All interactions with the remote server are driven from this file
import { useCountryProfiles, getCountryRiskAssessmentPackageDownload, getMethodologyDownload } from '../Utils/api'
// We use the Material-UI React library as our front end design framework
import { Grid, Tooltip, Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
// We load our application css by only bringing in the styles needed for the current page
import { countryProfilesStyles } from '../Config/theme'
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from '../Components/Loading/LoadingRing'
// The following are HTML/CSS components that are present on this page
import AnimatedButton from '../Components/Button/AnimatedButton'

// The core function for the Country Profiles "web page"
export default function CountryProfiles () {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = countryProfilesStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // When loading.geoPackage = true the "Download Package" button is disabled and shows a spinner.
  // When loading.geoMethodology = true the "Download Methodology" button is disabled and shows a spinner.
  const [loading, setLoading] = useState({
    geoPackage: false,
    geoMethodology: false
  })
  // When downloaded.geoPackage = true the "Download Package" button is green and shows a says "Downloaded".
  // When downloaded.geoMethodology = true the "Download Methodology" button is green and shows a says "Downloaded".
  const [downloaded, setDownloaded] = useState({
    geoPackage: false,
    geoMethodology: false
  })
  // "countryData" holds the country list dynamically set with API call within useCountryProfiles hook.
  // When a country is selected the country object has a boolean print which signals to the backend which countries to include in the package.
  // "allChecked" and "label are used to hold the state of the check all button. (If allchecked = true, button says 'Uncheck All' and vice versa).
  const [countryData, setCountryData] = useState({
    data: [],
    allChecked: true,
    label: 'Uncheck All'
  })
  // Auth context allows us to access our headers and authenticate with the API when calling handleSubmitSchemaMatch
  const contextValue = useContext(AuthContext)

  // Tracks UI state for countryData. When then user makes new selection, we mutate countryData.
  const handleToggle = (value) => () => {
    const newState = countryData.data.map((country) => {
      if (country.id === value.id) {
        if (country.print === false) {
          country.print = true
        } else {
          country.print = false
        }
      }
      return country
    })
    setCountryData((prevState) => ({
      ...prevState,
      data: newState
    }))
  }

  // Allows user to select and unselect every country. Mutates countryData
  const toggleCheckAll = () => {
    let allChecked, label, newState
    if (countryData.allChecked === true) {
      newState = countryData.data.map((country) => {
        country.print = false
        return country
      })
      allChecked = false
      label = 'Check All'
    } else {
      newState = countryData.data.map((country) => {
        country.print = true
        return country
      })
      allChecked = true
      label = 'Uncheck All'
    }
    setCountryData((prevState) => ({
      ...prevState,
      data: newState,
      allChecked: allChecked,
      label: label
    }))
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useCountryProfiles(params, setCountryData, setIsLoading)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (<LoadingScreen />)
    : (
      <div className={classes.root}>
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='center'
          spacing={3}
        >
          <Grid item>
            <Link
              to={{
                pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/countries`,
                state: {}
              }}
            >
              <Button title='Back to Countries' className='file-uploads-engagements-button'>
                <ArrowBackIcon /> {'Back to Countries'}
              </Button>
            </Link>
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={() => toggleCheckAll()}
            >
              {countryData.label}
            </Button>
          </Grid>

          <Grid item>

            <AnimatedButton
              tooltip='Click to download report'
              iconStyle={<CloudDownloadIcon />}
              onClick={() => getCountryRiskAssessmentPackageDownload(setLoading, countryData, setDownloaded, contextValue, params)}
              loading={loading.geoPackage}
              success={downloaded.geoPackage}
              fab={false}
              className={classes.button}
              buttonName='Download GeoRI Results'
            />
          </Grid>

          <Grid item>

            <AnimatedButton
              tooltip='Click to download methodology'
              iconStyle={<PictureAsPdfIcon />}
              onClick={() => getMethodologyDownload(setLoading, setDownloaded, contextValue, params)}
              loading={loading.geoMethodology}
              success={downloaded.geoMethodology}
              fab={false}
              className={classes.button}
              buttonName='Download GeoRI Methodology'
            />
          </Grid>

          <Grid item xs={2}>
            <div />
          </Grid>
          <Grid item xs={10}>
            <fieldset className='group'>
              <legend>
              Default is to set all countries. To make changes, uncheck specific
              countries.
              </legend>
              <ul className={classes.checkbox}>
                {countryData.data.map((value) => {
                  return (
                    <Tooltip title={value.country_name} key={value.id}>
                      <li className={classes.country}>
                        <input
                          id={value.id}
                          type='checkbox'
                          checked={value.print}
                          onChange={handleToggle(value)}
                        />
                        <label htmlFor={value.id}>{value.country_name}</label>
                      </li>
                    </Tooltip>
                  )
                })}
              </ul>
            </fieldset>
          </Grid>
        </Grid>
      </div>
    )
}
