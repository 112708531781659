// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import { useContext, useEffect } from 'react'
// We keep track of anything that is context dependent on Development, Staging, or Production environment in one file
import env from '../Config/environment'
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from '../Auth/AuthContext'
// We use React Notifications to show pop up messages to the user
import { NotificationManager } from 'react-notifications'
// We use Axios to make RESTful calls to our backend server. This API.js file is intended to contain all calls to the backend in
// one convenient place, however note that File Uploads are an exception and contain an API call in their architecture
import axios from 'axios'

// A helper function that constructs the environment dependent section
const API = axios.create({
  baseURL: env.API_BASE_URL
})

// These functions are hooks and represent the API calls needed on initial load or refresh of one of our core "web pages"
// They represent the "R" in "CRUD"
// Web Page 0) App
function useSidebar (setIsLoading, setData) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData () {
      return await API.get('clients', {
        params: {},
        headers: contextValue.getTokens()
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setData((prevState) => ({
            ...prevState,
            clients: r.data
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [setData, contextValue, setIsLoading])
}
// Web Page 1) Home
// No persistent data associated with this web page at this time. Landing page API read calls will be executed in this hook.
// Web Page 2) Clients
function useClients (params, setGridData, setIsLoading) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData () {
      return await API.get('clients', {
        params: {},
        headers: contextValue.getTokens()
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          NotificationManager.error(
            error.data.error_message,
            error.data.error_title,
            5000
          )
        }
      })
    return () => (mounted = false)
  }, [params, setGridData, setIsLoading, contextValue])
}
// Web Page 3) Engagements
function useEngagements (params, setGridData, setIsLoading) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData () {
      return await API.get('engagements', {
        params: {
          client_id: params.clientId
        },
        headers: contextValue.getTokens()
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          NotificationManager.error(
            error.data.error_message,
            error.data.error_title,
            5000
          )
        }
      })
    return () => (mounted = false)
  }, [params, setGridData, setIsLoading, contextValue])
}
// Web Page 4) File Uploads
function useFileUploads (params, setGridData, setIsLoading, setHasFile) {
  const contextValue = useContext(AuthContext)
  console.log(params)
  useEffect(() => {
    let mounted = true
    async function getData () {
      return await API.get('file_uploads', {
        params: {
          engagement_id: params.engagementId
        },
        headers: contextValue.getTokens()
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          const data = []
          if (r.data) {
            setHasFile(true)
            data.push(r.data)
          }
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: data
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setIsLoading, setGridData, setHasFile, contextValue])
}
// Web Page 5) Countries
function useCountries (params, setGridData, setIsLoading, setSettings) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData () {
      return await API.get('file_uploads', {
        params: {
          engagement_id: params.engagementId
        },
        headers: contextValue.getTokens()
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          if (r.data) {
            setGridData((prevState) => ({
              ...prevState,
              data: r.data.country_profiles
            }))
            setSettings((prevState) => ({
              ...prevState,
              settings: r.data
            }))
          } else {
            NotificationManager.error(
              'You must upload a file with Country Data before editing Country Information or selecting Countries to Download.',
              5000
            )
          }
        }
      })
      .catch((error) => {
        if (mounted) {
          NotificationManager.error(
            'You must upload a file with Country Data before editing Country Information or selecting Countries to Download.',
            5000
          )
        }
      })
    return () => (mounted = false)
  }, [params, setGridData, setIsLoading, setSettings, contextValue])
}
// Web Page 6) Country Profiles
function useCountryProfiles (params, setGridData, setIsLoading) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData () {
      return await API.get('country_profiles', {
        params: {
          engagement_id: params.engagementId
        },
        headers: contextValue.getTokens()
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          NotificationManager.error(
            'You must upload a file with Country Data before editing Country Information or selecting Countries to Download.',
            5000
          )
        }
      })
    return () => (mounted = false)
  }, [params, setGridData, contextValue, setIsLoading])
}

// These functions service our integration of Material-Table and represent the rest of the "CUD" actions in "CRUD" for all of our database
// driven persistent resources

function CreateFileUploadRows (gridData, setGridData, setHasFile, model, records, subType) {
  return (newData) =>
    new Promise((resolve) => {
      const data = [...gridData.data]

      if (model === 'fileUploads') {
        records.forEach((record) => {
          // Add the newly uploaded files to the browser state
          data.unshift(record)
        })
        setHasFile(true)
        setGridData({ ...gridData, data, resolve })
        resolve({
          data: gridData.data
        })
      }
    })
}

function CreateRow (gridData, setGridData, model, params, props) {
  const contextValue = useContext(AuthContext)

  return (newData) =>
    new Promise((resolve) => {
      const data = [...gridData.data]

      if (
        // Some data tables are used without any user input values (like the stat run one) in which case the newData field will be
        // undefined instead of a blank {}.  We want to allow the create function to go ahead as usual in this circumstance
        newData !== undefined &&
        newData !== null &&
        Object.keys(newData).length === 0
      ) {
        NotificationManager.error(
          'You have not entered any data.  You must enter data for at least one field before clicking the checkmark.',
          'Unable to Create With No Data',
          5000
        )
        resolve({
          data: gridData.data
        })
      } else {
        if (model === 'clients') {
          if (newData.name === undefined) {
            newData.name = ''
          }
          if (newData.krco_number === undefined) {
            newData.krco_number = ''
          }
          API.post(
            'clients',
            {
              name: newData.name,
              krco_number: newData.krco_number
            },
            { headers: contextValue.getTokens() }
          )
            .then((response) => {
              if (response.data.error_message === undefined) {
                // The sidebar nav is driven by clients/engagements so we update its state as well
                props.sidebarData.clients.unshift(response.data)

                props.setSidebarData((prevState) => ({
                  ...prevState,
                  clients: props.sidebarData.clients
                }))
              } else {
                setGridData({ ...gridData, data, resolve })
                resolve({
                  data: gridData.data
                })
                return NotificationManager.error(
                  response.data.error_message,
                  response.data.error_title,
                  5000
                )
              }
            })
            .catch((error) => console.log(error))
        } else if (model === 'engagements') {
          API.post(
            'engagements',
            {
              name: newData.name,
              client_id: params.clientId
            },
            { headers: contextValue.getTokens() }
          )
            .then((response) => {
              if (response.data.error_message === undefined) {
                // The sidebar nav is driven by clients/engagements so we update its state as well
                const newClients = []

                props.sidebarData.clients.forEach((client) => {
                  if (client.id === response.data.client_id) {
                    client.engagements.unshift(response.data)
                    newClients.push(client)
                  } else {
                    newClients.push(client)
                  }
                })

                props.setSidebarData((prevState) => ({
                  ...prevState,
                  clients: props.sidebarData.clients
                }))
              } else {
                setGridData({ ...gridData, data, resolve })
                resolve({
                  data: gridData.data
                })
                return NotificationManager.error(
                  response.data.error_message,
                  response.data.error_title,
                  5000
                )
              }
            })
            .catch((error) => console.log(error))
        } else if (model === 'tagTable') {
          if (newData.bucket_name === undefined) {
            newData.bucket_name = ''
          }
          if (newData.bucket_value === undefined) {
            newData.bucket_value = ''
          }
          API.post(
            'file_tags',
            {
              bucket_name: newData.bucket_name,
              bucket_value: newData.bucket_value,
              file_upload_id: props.fileUploadId
            },
            { headers: contextValue.getTokens() }
          )
            .then((response) => {
              if (response.data.error_message === undefined) {
                data.unshift(
                  response.data.file_tags.find(
                    (tag) => tag.file_upload_id === props.fileUploadId
                  )
                )
                setGridData({ ...gridData, data, resolve })
                resolve({
                  data: gridData.data
                })
              } else {
                setGridData({ ...gridData, data, resolve })
                resolve({
                  data: gridData.data
                })
                return NotificationManager.error(
                  response.data.error_message,
                  response.data.error_title,
                  5000
                )
              }
            })
            .catch((error) => console.log(error))
        }
      }
    })
}

function EditRow (gridData, setGridData, model) {
  const contextValue = useContext(AuthContext)
  return (newData, oldData) =>
    new Promise((resolve) => {
      const data = [...gridData.data]

      data[data.indexOf(oldData)] = newData

      if (model === 'clients') {
        API.put(
          `clients/${newData.id}`,
          {
            name: newData.name,
            krco_number: newData.krco_number
          },
          { headers: contextValue.getTokens() }
        )
          .then((response) => {
            if (response.data.error_message === undefined) {
              // The sidebar nav is driven by clients/engagements so we update its state as well
              // const newClients = []
              // props.sidebarData.clients.forEach((client) => {
              //   if (client.id === response.data.id) {
              //     newClients.push(response.data)
              //   } else {
              //     newClients.push(client)
              //   }
              // })
              // props.setSidebarData((prevState) => ({
              //   ...prevState,
              //   clients: newClients
              // }))
            } else {
              resolve({})
              return NotificationManager.error(
                response.data.error_message,
                response.data.error_title,
                5000
              )
            }
          })
          .catch((error) => console.log(error))
      } else if (model === 'engagements') {
        API.put(
          `engagements/${newData.id}`,
          {
            name: newData.name
          },
          { headers: contextValue.getTokens() }
        )
          .then((response) => {
            if (response.data.error_message === undefined) {
              // The sidebar nav is driven by clients/engagements so we update its state as well
              // const newClients = []
              //
              // props.sidebarData.clients.forEach((client) => {
              //   if (client.id === response.data.client_id) {
              //     const newEngagements = []
              //     client.engagements.forEach((engagement) => {
              //       if (engagement.id === response.data.id) {
              //         newEngagements.push(response.data)
              //       } else {
              //         newEngagements.push(engagement)
              //       }
              //     })
              //     client.engagements = newEngagements
              //     newClients.push(client)
              //   } else {
              //     newClients.push(client)
              //   }
              // })
              //
              // props.setSidebarData((prevState) => ({
              //   ...prevState,
              //   clients: props.sidebarData.clients
              // }))
            } else {
              resolve({})
              return NotificationManager.error(
                response.data.error_message,
                response.data.error_title,
                5000
              )
            }
          })
          .catch((error) => console.log(error))
      } else if (model === 'users') {
        API.put(
          `users/${newData.id}`,
          {
            email: newData.email,
            admin: newData.admin,
            first_name: newData.first_name,
            last_name: newData.last_name,
            client_manager: newData.client_manager
          },
          { headers: contextValue.getTokens() }
        )
          .then((response) => {})
          .catch((error) => console.log(error))
      }

      setGridData({ ...gridData, data, resolve })
      resolve({
        data: gridData.data
      })
    })
}

function DeleteRow (gridData, setGridData, model, setHasFile) {
  const contextValue = useContext(AuthContext)
  return (oldData) =>
    new Promise((resolve) => {
      const data = [...gridData.data]
      data.splice(data.indexOf(oldData), 1)

      if (model === 'clients') {
        axios
          .create({
            baseURL: env.API_BASE_URL,
            headers: contextValue.getTokens()
          })
          .delete(`clients/${oldData.id}`)
          .then((response) => {
          })
      } else if (model === 'engagements') {
        axios
          .create({
            baseURL: env.API_BASE_URL,
            headers: contextValue.getTokens()
          })
          .delete(`engagements/${oldData.id}`)
          .then((response) => {
          })
      } else if (model === 'users') {
        axios
          .create({
            baseURL: env.API_BASE_URL,
            headers: contextValue.getTokens()
          })
          .delete(`users/${oldData.id}`)
          .then((response) => {
          })
      } else if (model === 'fileUploads') {
        axios
          .create({
            baseURL: env.API_BASE_URL,
            headers: contextValue.getTokens()
          })
          .delete(`file_uploads/${oldData.id}`)
          .then((response) => {
            setHasFile(false)
          })
      }

      setGridData({ ...gridData, data, resolve })
      resolve({
        data: gridData.data
      })
    })
}

// These functions are not hooks and represent API calls triggered by user action

// This function is called from the File Upload page and downloads a template for the user to use in case they do not have their own.
const handleDownloadRequest = (contextValue, params) => {
  const method = 'GET'
  const url = `${env.API_BASE_URL}/download_file`
  axios
    .request({
      url,
      method,
      params: {
        engagement_id: params.engagementId
      },
      responseType: 'blob',
      headers: contextValue.getTokens()
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'GeoRI_Template.csv') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}

// This function is called from AdminSettings to save any changes to the attribute names or weights
function saveAdminAttribute (model, event, keyName, contextValue, props) {
  const doNotTouch = [
    'tableData',
    'temp'
  ]
  const adjust = props.gridData.data.filter(country => country[keyName.slice(0, -7)] === 1)
  const sendToBackend = adjust.map(a => { return { id: a.id } })
  if (model === 'weight') {
    const oldGridData = props.gridData.data
    const oldSettings = props.settings.settings
    const attr = keyName.slice(0, -7)
    const adjust = oldGridData.filter(country => country[attr] === 1)
    const sendToBackend = adjust.map(a => { return { id: a.id } })
    adjust.map(needsAdjusting => {
      needsAdjusting.total_score = needsAdjusting.total_score - oldSettings[keyName] + parseInt(event)
      needsAdjusting.adjusted_score = needsAdjusting.adjusted_score - oldSettings[keyName] + parseInt(event)
      if (needsAdjusting.adjusted_score >= 50) {
        needsAdjusting.proposed_risk_rating = 'HIGH'
      } else if (needsAdjusting.adjusted_score > 19) {
        needsAdjusting.proposed_risk_rating = 'MODERATE'
      } else {
        needsAdjusting.proposed_risk_rating = 'LOW'
      }
      Object.entries(needsAdjusting).map(([key, value]) => {
        if (doNotTouch.includes(key) === false) {
          sendToBackend.find(country => country.id === needsAdjusting.id)[key] = value
        }
        return null
      })
      return null
    })
  }
  axios.create({
    baseURL: env.API_BASE_URL,
    headers: contextValue.getTokens()
  })
    .put(
      `${env.API_BASE_URL}/file_uploads/${props.gridData.data[0].file_upload_id}`,
      {
        [keyName]: event,
        country_profiles: sendToBackend
      }
    )
    .then(response => {
      const data = props.settings.settings

      Object.entries(data).forEach(([key]) => {
        data[key] = response.data[key]
      })
      props.setSettings(prevState => ({
        ...prevState,
        settings: data
      }))
    })
}

// This function is called from the CreateCountryProfiles file within the Country Profiles page to save any changes to the number of Affirmative AML Risk Factors
function saveCountryProfilesAttribute (event, key, contextValue, props) {
  const doNotTouch = [
    'tableData',
    'temp'
  ]

  const keyWeight = key + '_weight'
  let score = 0
  let adjustedScore = 0
  let numberOfYes = 0
  let riskRating = ''
  if (key === 'judgemental_addition') {
    numberOfYes = props.rowData.number_of_attributes
    score = props.rowData.total_score
    adjustedScore = props.rowData.adjusted_score - props.rowData.judgemental_addition + parseInt(event)
  } else if (key === 'judgemental_factors_considered') {
    numberOfYes = props.rowData.number_of_attributes
    score = props.rowData.total_score
    adjustedScore = props.rowData.adjusted_score
  } else if (key.includes('att')) {
    if (event === 1) {
      numberOfYes = props.rowData.number_of_attributes + 1
      score = props.rowData.total_score + props.settings.settings[keyWeight]
      adjustedScore = props.rowData.adjusted_score + props.settings.settings[keyWeight]
    } else {
      numberOfYes = props.rowData.number_of_attributes - 1
      score = props.rowData.total_score - props.settings.settings[keyWeight]
      adjustedScore = props.rowData.adjusted_score - props.settings.settings[keyWeight]
    }
  } else {
    console.log('Something in the code above ths is broken.')
  }

  if (adjustedScore >= 50) {
    riskRating = 'HIGH'
  } else if (adjustedScore > 19) {
    riskRating = 'MODERATE'
  } else {
    riskRating = 'LOW'
  }
  axios.create({
    baseURL: env.API_BASE_URL,
    headers: contextValue.getTokens()
  })
    .put(
      `${env.API_BASE_URL}/country_profiles/${props.rowData.id}`,
      {
        [key]: event,
        total_score: score,
        adjusted_score: adjustedScore,
        proposed_risk_rating: riskRating,
        number_of_attributes: numberOfYes
      }
    )
    .then(response => {
      const data = props.rowData
      Object.entries(data).map(([key, value]) => {
        if (doNotTouch.includes(key) === false) {
          data[key] = response.data[key]
        }
        return null
      })
      props.updateGridData(props.rowData.id, data)
    })
}

// This function is called from the Country Profiles web page and handles downloading a zip file with the final engagement content
function getCountryRiskAssessmentPackageDownload (setLoading, countryData, setDownloaded, contextValue, params) {
  setLoading((prevState) => ({
    ...prevState,
    geoPackage: true
  }))

  const returnValue = []

  countryData.data.forEach((country) => {
    returnValue.push({
      country_name: country.country_name,
      print: country.print
    })
  })

  axios
    .post(
      `${env.API_BASE_URL}/download_package`,
      {
        country_data: returnValue
      },
      {
        params: {
          engagement_id: params.engagementId
        },
        responseType: 'blob',
        headers: contextValue.getTokens()
      }
      // headers: contextType.context.getTokens(),
    )
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'GeoRI_Package.zip')
      document.body.appendChild(link)
      link.click()
      setLoading((prevState) => ({
        ...prevState,
        geoPackage: false
      }))
      setDownloaded((prevState) => ({
        ...prevState,
        geoPackage: true
      }))
      link.remove()
    })
    .catch((error) => {
      console.log(error)
    })
}

// This function is called from the Country Profiles web page and handles downloading a file with the final engagement methodology
function getMethodologyDownload (setLoading, setDownloaded, contextValue, params) {
  setLoading((prevState) => ({
    ...prevState,
    geoMethodology: true
  }))

  const method = 'GET'
  const url = `${env.API_BASE_URL}/download_methodology`
  axios
    .request({
      url,
      method,
      params: {
        engagement_id: params.engagementId
      },
      responseType: 'blob',
      headers: contextValue.getTokens()
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'GeoRI-Country_Risk_Assessment_Methodology.pdf') // any other extension
      document.body.appendChild(link)
      link.click()
      setLoading((prevState) => ({
        ...prevState,
        geoMethodology: false
      }))
      setDownloaded((prevState) => ({
        ...prevState,
        geoMethodology: true
      }))
      link.remove()
    })
}

export {
  API,
  useSidebar,
  useClients,
  useEngagements,
  useFileUploads,
  useCountries,
  useCountryProfiles,
  CreateFileUploadRows,
  CreateRow,
  EditRow,
  DeleteRow,
  handleDownloadRequest,
  saveAdminAttribute,
  saveCountryProfilesAttribute,
  getCountryRiskAssessmentPackageDownload,
  getMethodologyDownload
}
