import React, { useContext, useState } from 'react'
import { AccordionSummary, AccordionDetails, Accordion, Grid } from '@material-ui/core'
import AutosaveInput from '../Form/AutosaveInput'
import { saveAdminAttribute } from '../../Utils/api'
import { AuthContext } from '../../Auth'

function AdminSettings (props) {
  const contextValue = useContext(AuthContext)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    open ? setOpen(false) : setOpen(true)
  }

  return (
    <>
      <Accordion expanded={open}>
        <AccordionSummary className='accordion-wrapper' onClick={() => handleOpen()}>
          <p className='country_name'><b>Admin Settings</b></p>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={3}
            direction='row'
            justify='space-evenly'
            alignItems='flex-start'
            style={{ width: '100%', margin: 'auto' }}
          >
            <Grid item xs={10}>
              <p className='bigger_text'><b>Title</b></p>
            </Grid>
            <Grid item xs={2}>
              <p className='bigger_text'><b>Weight</b></p>
            </Grid>
            {props.settings.settings && props.gridData.data[0]
              ? Object.entries(props.settings.settings)
                .map(([key, value], i) => {
                  if (key.includes('att') && key.includes('weight')) {
                    const keyName = key.slice(0, -6) + 'name'
                    return (
                      <React.Fragment key={i}>
                        <Grid item className='admin-input-title' xs={10}>
                          <AutosaveInput
                            saveInputValue={(title) => { saveAdminAttribute('title', title, keyName, contextValue, props) }}
                            defaultValue={props.settings.settings[keyName]}
                            model='att_name'
                          />
                        </Grid>
                        <Grid className='autosave-input-wrapper' item xs={2}>
                          <AutosaveInput
                            saveInputValue={(percent) => { saveAdminAttribute('weight', percent, key, contextValue, props) }}
                            defaultValue={value}
                            model='att_weight'
                          />
                        </Grid>
                      </React.Fragment>
                    )
                  } else {
                    return <div key={key} />
                  }
                }) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AdminSettings
