// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState } from 'react'
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useParams } from 'react-router-dom'
// We use Auth0 as our current authentication and authorization solution
import { AuthContext } from '../Auth'
// All interactions with the remote server are driven from this file
import { useCountries } from '../Utils/api'
// We use the Material-UI React library as our front end design framework
import { Button, Grid } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// We load our application css by only bringing in the styles needed for the current page
import { countriesStyles } from '../Config/theme'
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from '../Components/Loading/LoadingRing'
// We use Material-Table for CRUD operations on application resources
import MaterialTable, { MTableToolbar } from 'material-table'
import DataTableIcons from '../Components/Icons/DataTableIcons'
// The following are HTML/CSS components that are present on this page
import CreateCountryProfiles from '../Components/Table/CreateCountryProfile'
import AdminSettings from '../Components/Table/AdminSettings'
import { colors } from '../Config/colors'

// The core function for the Countries "web page"
export default function Countries () {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = countriesStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // The AML Risk Factors and their respective weights are stored in "settings." Each file_upload has "settings" associated with it.
  const [settings, setSettings] = useState({ settings: [] })
  // The CRUD operations are handled by Material-Table which has its entire state driven by this gridData variable. You can
  // force a rerender of the HTML and CSS by using the setGridData function
  // The long term persistent data retrieved from your database goes into the data attribute
  // The display information for the table goes in the columns attribute. You need to look in the npm modules to see the default
  // HTML and CSS used by Material-Table. If you see a render: attribute that will contain custom HTML and CSS written by our
  // team to override the default Material-Table HTML and CSS
  const [gridData, setGridData] = useState({
    isLoading: true,
    data: [],
    columns: [
      {
        title: 'Name',
        field: 'country_name',
        grouping: false
      },
      {
        title: 'Country Code',
        field: 'iso_code',
        grouping: false
      },
      {
        title: 'Risk Level',
        field: 'proposed_risk_rating',
        editable: 'never'
      },
      {
        title: 'Adjusted Score',
        field: 'adjusted_score',
        grouping: false
      },
      {
        title: 'Number of Bank Customers',
        field: 'att_13',
        grouping: false
      },
      {
        title: 'Total Wire Activity (In/Out)',
        field: 'att_14',
        grouping: false
      }
    ],
    resolve: () => {}
  })

  // Material-Table options
  const options = {
    headerStyle: {
      backgroundColor: colors.MIDNIGHT_BLUE.hex,
      color: colors.BACKGROUND_GRAY.hex
    },
    sorting: true,
    search: true,
    rowHover: true,
    actionsColumnIndex: -1,
    filterType: 'dropdown',
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 300],
    exportButton: true,
    addRowPosition: 'first'
  }
  // Material-Table names and labels overrides
  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}'
    },
    toolbar: {
      nRowsSelected: '{0} row(s) selected'
    },
    header: {
      actions: 'Admin Actions'
    },
    body: {
      emptyDataSourceMessage: 'No Countries to display',
      filterRow: {
        filterTooltip: 'Filter'
      }
    }
  }
  // Material-Table popout below the context row whenever you click on the chevron next to the row
  const detailPanel = [
    rowData => {
      rowData.temp = gridData.data.find(row => row.id === rowData.id)
      return {
        tooltip: 'Expand Country Information',
        render: rowData =>
          <CreateCountryProfiles
            rowData={rowData.temp}
            settings={settings}
            updateGridData={updateGridData}
          />
      }
    }
  ]
  // Material-Table toolbar with search feature
  const components = {
    Toolbar: (props) => (
      <div>
        <MTableToolbar {...props} />
      </div>
    )
  }

  // This function updates the material-table gridData when the user changes an attribute of the AML risk factors list
  const updateGridData = (rowId, newRow) => {
    const oldData = gridData.data
    oldData[oldData.findIndex(row => row.id === rowId)] = newRow
    setGridData(prevState => ({
      ...prevState,
      data: oldData
    })
    )
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useCountries(params, setGridData, setIsLoading, setSettings)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <>
          <Grid
            container
            direction='row'
            justify='space-evenly'
            alignItems='flex-start'
            spacing={3}
            style={{ width: '100%', margin: 'auto' }}
          >
            <Grid item>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/fileUploads`,
                  state: {}
                }}
              >
                <Button title='Back to File Upload' className={classes.leftSideNavigationButton}>
                  <ArrowBackIcon /> {'Back to File Upload'}
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/countryProfiles`,
                  state: {}
                }}
              >
                <Button title='Select Countries for Download' className={classes.rightSideNavigationButton}>
                  {'Select Countries for Download'} <ArrowForwardIcon />
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              {auth.user.admin ? <AdminSettings
                settings={settings}
                setSettings={setSettings}
                gridData={gridData}
                                 /> : <div />}
            </Grid>
            <Grid item xs={12}>
              <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                  isLoading={isLoading}
                  data={gridData.data}
                  icons={DataTableIcons}
                  title=' Countries'
                  columns={gridData.columns}
                  detailPanel={detailPanel}
                  options={options}
                  localization={localization}
                  components={components}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </AuthContext.Consumer>
  )
}
