// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from 'react'
// Each time the user navigates to a new "page" we switch out the HTML and CSS inside of Main using the Router path
import { Switch } from 'react-router'
// Other than the landing page, every part of our application is required to locked behind authenticated private routes
import { PrivateRoute } from '../Auth'

// These are the web pages in this application:
// 1) The home page in the STAT app is currently not designed and has only a link to the clients - low hanging fruit
import Home from './Home'
// 2) All resources in the application ultimately belong to a Client which we tie all users to via the UserClient model for multi-tenanting
import Clients from './Clients'
// 3) We enable our clients to use the tool as many times as they want and will tie a core 'loop' of the business logic to a unique engagement
import Engagements from './Engagements'
// 4) Their are numerous types of input data required to be obtained from the client and transferred to the remote server for processing
import FileUploads from './FileUploads'
// 5) The user has to guide us through combining their information into one unique Transaction model for running statistics via
import CountryProfiles from './CountryProfiles'
// 6) Each database can have statistics run against it in dozens of different patterns in a comprehensive dashboard contained here
import Countries from './Countries'

// The core function for the body of our web application
export default function Main (props) {
  const parentProps = props
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <Switch>
      <PrivateRoute exact path='/' render={(props) => <Home {...props} />} />
      <PrivateRoute
        exact
        path='/clients'
        render={(props) => (
          <Clients
            {...props}
            cableApp={parentProps.cableApp}
            sidebarData={parentProps.sidebarData}
            setSidebarData={parentProps.setSidebarData}
          />
        )}
      />
      <PrivateRoute
        exact
        path='/clients/:clientId/engagements'
        render={(props) => (
          <Engagements
            {...props}
            cableApp={parentProps.cableApp}
            sidebarData={parentProps.sidebarData}
            setSidebarData={parentProps.setSidebarData}
          />
        )}
      />
      <PrivateRoute
        exact
        path='/clients/:clientId/engagements/:engagementId/fileUploads'
        render={(props) => (
          <FileUploads {...props} cableApp={parentProps.cableApp} />
        )}
      />
      <PrivateRoute
        exact
        path='/clients/:clientId/engagements/:engagementId/countryProfiles'
        render={(props) => (
          <CountryProfiles {...props} cableApp={parentProps.cableApp} />
        )}
      />
      <PrivateRoute
        exact
        path='/clients/:clientId/engagements/:engagementId/countries'
        render={(props) => (
          <Countries {...props} cableApp={parentProps.cableApp} />
        )}
      />
    </Switch>
  )
}
