import React from 'react'
import Copyright from './Copyright'
import { Typography } from '@material-ui/core'

const Footer = () => {
  return (
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <Copyright />

      <Typography
        component='h1'
        variant='body2'
        color='textSecondary'
        align='center'
        gutterBottom
      >
        {'GeoRI'}
      </Typography>
    </div>
  )
}

export default Footer
