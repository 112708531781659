import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import Auth0 from "auth0-js";
import axios from "axios";
import {AuthContext} from "../Auth/AuthContext";
import env from "../Config/environment";

const auth0 = new Auth0.WebAuth({
    domain: env.AUTH0_DOMAIN,
    audience: env.AUTH0_AUDIENCE,
    clientID: env.AUTH0_CLIENT_ID,
    redirectUri: env.AUTH0_REDIRECT_URI,
    responseType: "token id_token",
    scope: "openid profile email"
});

class AuthProviderComponent extends Component {
    renewSession = () =>
        new Promise((res, rej) => {
            auth0.checkSession({}, (err, authResult) => {
                if (err) {
                    rej(err);
                } else {
                    this.setSession(authResult, () => res(authResult));
                }
            });
        });

    login = () => {
        auth0.authorize();
    };

    handleAuthentication = () =>
        new Promise((res, rej) => {
            auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult, res);
                } else if (err) {
                    rej(err);
                } else {
                    res();
                }
            });
        });

    isAuthenticated = () =>
        this.getSession().accessToken !== null && this.isNotExpired();
    isAuthorized = () => this.isAuthenticated() && this.state.auth.user;

    getSession = () => this.state.auth;

    setSession = (authResult, cb) => {
        const {accessToken, idToken, expiresIn} = authResult;
        if (accessToken && idToken) {
            this.setState(
                {
                    auth: {
                        accessToken,
                        idToken,
                        expiresAt: expiresIn * 1000 + new Date().getTime()
                    }
                },
                cb
            );
        }
    };

    logout = () => {
        this.setState(
            {
                auth: {
                    accessToken: null,
                    idToken: null,
                    expiresAt: null
                }
            },
            () => {
                auth0.logout({
                    clientID: env.AUTH0_CLIENT_ID,
                    returnTo: env.AUTH0_REDIRECT_URI
                });
            }
        );
    };

    isNotExpired = () => new Date().getTime() < this.getSession().expiresAt;

    getTokens = () => {
        const session = this.getSession();
        return {
            Authorization: `Bearer ${session.accessToken}`,
            Identification: `Bearer ${session.idToken}`
        };
    };

    state = {
        auth: {
            user: {},
            accessToken: null,
            idToken: null,
            expiresAt: null
        },
        loading: false,
        renewSession: this.renewSession,
        login: this.login,
        handleAuthentication: this.handleAuthentication,
        isAuthenticated: this.isAuthenticated,
        isAuthorized: this.isAuthorized,
        logout: this.logout,
        getTokens: this.getTokens
    };

    render() {
        const {children} = this.props;
        return (
            <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>
        );
    }

    UNSAFE_componentWillMount() {
        const redirect = () => {
            const {history, location} = this.props;
            const headers = this.getTokens();

            const setStateAndRedirect = userData => {
                const user = userData
                    ? {
                        id: userData.id,
                        email: userData.email,
                        admin: userData.admin,
                        client_manager: userData.client_manager,
                        ...userData.attributes
                    }
                    : null;

                this.setState(
                    state => ({
                        auth: {...state.auth, user}
                    }),
                    async () => {
                        if (this.isAuthenticated()) {
                            history.replace((location.pathname || "/") + location.search);
                        }
                        this.setState({loading: false});
                    }
                );
            };

            axios
                .get(`${env.API_BASE_URL}/users/current`, {headers})
                .then(({data}) => setStateAndRedirect(data))
                .catch(() => setStateAndRedirect());
        };

        this.setState({loading: true}, async () => {
            await this.handleAuthentication();

            this.isAuthenticated()
                ? redirect()
                : this.renewSession().finally(redirect);
        });
    }
}

AuthProviderComponent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export const AuthProvider = withRouter(AuthProviderComponent);
