// We use the Material-UI React library as our front end design framework
import { createMuiTheme } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
// There are two hooks 'makeStyles' for working with React functions and 'withStyles' for working with React classes
import makeStyles from '@material-ui/core/styles/makeStyles'
import { colors } from "./colors"


// This is a global variable because we use it across several styles to make our left hand nav bar work
const drawerWidth = 290
/// ///////////////////////////////////////////////////////////////
// IMPORTANT NOTE!!                                             //
/// ///////////////////////////////////////////////////////////////
// This is an example of how themes were being exported. This works 100% fine in development. In production this
// was causing our custom classes to be injected in the middle of the material-ui library classes which was causing
// all kinds of styling issues. see https://github.com/mui-org/material-ui/issues/15214 for details
// export const homeStyles = makeStyles((theme) => ({
// root: {
//   display: "flex",
// },
// }))
// const classes = homeStyles()
// This is an example of how it needs to be done to ensure that our styles in production are loading identical to dev
// export const fileTagsTableStyles = () => {
//   return makeStyles((theme) => ({
//     root: {
//       display: "flex",
//     },
//   }))
// }
// const classes = fileTagsTableStyles()()  !Note the second function call

// The theme specifies the color of the components, darkness of the surfaces, level of shadow, appropriate
// opacity of ink elements, etc. This lets you apply a consistent tone to your app. There are light and dark
// themes available but MUI uses light by default. The theme has 6 high level areas, not all of which have
// been explored by our team to date
// https://material-ui.com/customization/theming/
// 1) Palette
// 2) Typography
// 3) Spacing
// 4) Breakpoints
// 5) z-index
// 6) Globals
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.CHARCOAL_GRAY.hex,
      dark: colors.CHARCOAL_GRAY.hex,
      light: colors.CHARCOAL_GRAY.hex
    },
    secondary: {
      main: colors.MIDNIGHT_BLUE.hex,
      dark: colors.MIDNIGHT_BLUE,
      light: ''
    }
  },
  paper: {
    padding: 20,
    margin: 20,
    textAlign: 'center'
  }
})

/// ///////////////////////////////////////////////////////////////
// These contain the styles for each of our primary "web pages" //
/// ///////////////////////////////////////////////////////////////

// Page 0) App.js
export const appStyles = () => {
  return makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    customizeToolbar: {
      minHeight: 36
    }
  }))
}
// Page 1) Home.js
export const homeStyles = () => {
  return makeStyles((theme) => ({
    root: {}
  }))
}
// Page 2) Clients.js
export const clientsStyles = () => {
  return makeStyles((theme) => ({
    root: {
      margin: 12
    }
  }))
}
// Page 3) Engagements.js
export const engagementsStyles = () => {
  return makeStyles((theme) => ({
    leftSideNavigationButton: {
      float: 'left'
    }
  }))
}
// Page 4) FileUploads.js
export const fileUploadsStyles = () => {
  return makeStyles((theme) => ({
    rightSideNavigationButton: {
      float: 'right'
    },
    leftSideNavigationButton: {
      float: 'left'
    }
  }))
}
// Page 5) Countries.js
export const countriesStyles = () => {
  return makeStyles((theme) => ({
    rightSideNavigationButton: {
      float: 'right'
    },
    leftSideNavigationButton: {
      float: 'left'
    }

  }))
}
// Page 6) Countries.js
export const countryProfilesStyles = () => {
  return makeStyles((theme) => ({
    checkbox: {
      columnCount: 3,
      margin: 0,
      padding: 0,
      marginLeft: '20px',
      listStyle: 'none'
    },
    country: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    button: {
      width: '120px',
      fontSize: '12px'
      // marginBottom: "10px",
    }
    // li: {},
    // columncount: {
    //   columnCount: 2
    // }
    // fieldsetGroup: {
    //   margin: 0,
    //   padding: 0,
    //   marginBottom: '1.25 em',
    //   paddingBottom: '.125 em'
    // },
    // fieldsetGroupLegend: {
    //   margin: 0,
    //   padding: 0,
    //   fontWeight: 'bold',
    //   marginLeft: '20px',
    //   fontSize: '100%',
    //   color: 'black'
    // }
  }))
}

// components/TopNavWithSideDrawer/ClientListItem.js
export const clientListStyles = () => {
  return makeStyles((theme) => ({
    listItemIcon: {
      minWidth: '30px;'
    },
    header: {
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    subHeader: {
      marginLeft: '15px',
      paddingTop: '0px',
      paddingBottom: '0px',
      height: '36px',
      textDecoration: 'underline'
    },
    manageResourceButton: {
      marginLeft: '5px',
      marginTop: '15px',
      display: 'inline-block'
    }
  }))
}
// components/TopNavWithSideDrawer/EngagementListItem.js
export const engagementListStyles = () => {
  return makeStyles((theme) => ({
    listItemIcon: {
      minWidth: '30px;'
    },
    subHeaderItem: {
      marginLeft: '15px',
      paddingTop: '0px',
      paddingBottom: '0px',
      maxWidth: '250px'
    },
    subHeader: {
      marginLeft: '30px',
      paddingTop: '0px',
      paddingBottom: '0px',
      height: '36px',
      textDecoration: 'underline'
    },
    thirdLevel: {
      marginLeft: '30px',
      paddingTop: '0px',
      paddingBottom: '0px',
      maxWidth: '235px'
    }
  }))
}
// components/TopNavWithSideDrawer/TopNavWithSideDrawer.js
export const topNavWithSideDrawerStyles = () => {
  return makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch'
      }
    },
    grow: {
      flexGrow: 1
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    logo: {
      maxWidth: 240
    },
    statLogo: {
      maxWidth: 140
    },
    statMenu: {
      marginRight: 160
    },
    drawerPaper: {
      position: 'fixed',
      width: drawerWidth,
      borderRadius: 0,
      borderTop: 'none',
      borderBottom: 'none',
      top: theme.spacing(8), // push content down to fix scrollbar position
      height: `calc(100% - ${theme.spacing(8)}px)`, // subtract appbar height
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    button: {
      margin: theme.spacing(1),
      color: 'lightgrey'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen
      }),
      zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    sideBarList: {
      width: '100%',
      maxWidth: 360
    },
    sidebarSubHeader: {
      paddingTop: '0px',
      paddingBottom: '0px',
      height: '36px',
      textDecoration: 'underline'
    },
    manageResourceButton: {
      marginLeft: '45px',
      marginTop: '15px',
      display: 'inline-block'
    }
  }))
}
