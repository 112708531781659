// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from 'react'
// This is the only file in the application with this import which is used to create the initial React application
import ReactDOM from 'react-dom'
// This is the only file in the application with this import which is used to set up the single page app router we use to switch "web pages"
import { BrowserRouter as Router } from 'react-router-dom'
// We keep track of anything that is context dependent on Development, Staging, or Production environment in one file
import env from './Config/environment'
// We use Auth0 as our current authentication and authorization solution
import { AuthProvider, PrivateRoute } from './Auth'
// The following are HTML/CSS components that are always present regardless of which page in the app our user is currently visiting
import App from './Main/App'
// Service workers are included by create-react-app by default and are supposed to be good for push notifications and other things that dont
// require user interaction. We are not currently using this even though we do use push notifications. Left in here because at some point in
// the future we will circle back on these and most likely start using
import * as serviceWorker from './Utils/serviceWorker'
import './assets/styles/master.scss'
// Our React Single Page Application
const WrappedApp = () => (
  <Router basename={env.ROUTER_BASE_NAME}>
    <AuthProvider>
      <PrivateRoute to='/' component={App} />
    </AuthProvider>
  </Router>
)
// Takes the only element "root" on the index.html file and puts our React Single Page Application inside of it
ReactDOM.render(<WrappedApp />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
