import React from 'react'

class AutosaveInput extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      value: '',
      changed: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleBlur () {
    if (this.props.model === 'att_weight' && this.state.value === '' && !this.props.textArea) {
      this.props.saveInputValue(0)
      this.setState({
        value: 0
      })
    } else {
      this.props.saveInputValue(this.state.value)
    }
  }

  handleChange (event) {
    event.preventDefault()
    this.setState({
      changed: true,
      value: event.target.value
    })
  }

  componentDidMount () {
    if (this.props.defaultValue !== null) {
      this.setState({ value: this.props.defaultValue })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        value: this.props.defaultValue
      })
    }
  }

  render () {
    return (
      <>
        {this.props.textArea
          ? <textarea
            name='autosaveInput'
            type='text'
            value={this.state.value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            rows={4}
            columns={60}
          />
          : <input
            name='autosaveInput'
            type='text'
            value={this.state.value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            />}
      </>
    )
  }
}

export default AutosaveInput
