import React from 'react'
import FileUpload from './FileUpload'

class FileUploader extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      uniqueStamp: `${this.props.engagementId}${Date.now()}`
    }
    this.handleFinished = this.handleFinished.bind(this)
  }

  handleFinished (fileUploads) {
    this.props.handleFilesUploaded(fileUploads, this.state.uniqueStamp)
  }

  render () {
    return (
      <>
        <FileUpload
          uploadParams={{
            engagement_id: this.props.engagementId
          }}
          handleFinished={this.handleFinished}
          setGridData={this.props.setGridData}
          gridData={this.props.gridData}
        />
      </>
    )
  }
}

export default FileUploader
