import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { AuthContext } from '../../Auth'
import AutosaveInput from '../Form/AutosaveInput'
import Paper from '@material-ui/core/Paper'
import krLogo from '../../assets/images/firm_logo.png'
import { Divider } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import { CountryInfo, CountrySource } from '../../assets/StaticCountryData'
import { saveCountryProfilesAttribute } from '../../Utils/api'

function CreateCountryProfiles (props) {
  const contextValue = useContext(AuthContext)

  return (
    <React.Fragment key={props.rowData.id}>
      <Grid container className='attribute_name'>
        <Grid item xs={6}>
          <Grid container>

            {
              Object.entries(props.settings.settings)
                .map(([key, value], i) => {
                  let findAttribute
                  if (key.includes('att') && key.includes('name')) {
                    findAttribute = key.slice(0, -5)
                    return (
                      <React.Fragment key={i}>
                        <Grid container>
                          <Grid item className='risk_text_wrapper' xs={2}>
                            <FormControl>
                              <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={props.rowData[findAttribute]}
                                onChange={(e) => {
                                  e.persist()
                                  saveCountryProfilesAttribute(e.target.value, findAttribute, contextValue, props)
                                }}
                              >
                                <MenuItem name={findAttribute} value={1}>Yes</MenuItem>
                                <MenuItem name={findAttribute} value={0}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={10}>
                            <p className='attribute_name'>{(value)}</p>
                          </Grid>
                        </Grid>
                        <Divider />
                      </React.Fragment>
                    )
                  } else {
                    return <div key={i} />
                  }
                })
            }
          </Grid>
          <Grid container>
            <Grid className='autosave-input-wrapper' item xs={4}>
              <FormLabel className='autosave-input-label'>Judgemental Modifier</FormLabel>
              <AutosaveInput
                saveInputValue={(percent) => { saveCountryProfilesAttribute(percent, 'judgemental_addition', contextValue, props) }}
                defaultValue={props.rowData.judgemental_addition}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <FormLabel className='autosave-input-label'>Judgemental Explanation</FormLabel>
              </Grid>
              <Grid className='autosave-different-wrapper' item xs={12}>
                <AutosaveInput
                  saveInputValue={(explanation) => { saveCountryProfilesAttribute(explanation, 'judgemental_factors_considered', contextValue, props) }}
                  defaultValue={props.rowData.judgemental_factors_considered}
                  textArea
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* FIXME: pull into separate file */}

        <Grid className='pdf_paper' item xs={6}>
          <Paper elevation={2} className='pdf_paper'>
            <Grid container>
              <Grid item xs={3}>
                <Grid container>
                  {
                    props.rowData.country_name === 'Cte d\'Ivoire'
                      ? <p className='country_name_longer'>Côte d'Ivoire</p>
                      : props.rowData.country_name.length > 11
                        ? <p className='country_name_longer'>{props.rowData.country_name}</p>
                        : <p className='country_name'>{props.rowData.country_name}</p>
                  }
                </Grid>
                <Grid>
                  <p className='country_code'>
                    ISO Country Code: <b>{props.rowData.iso_code}</b>
                  </p>
                </Grid>
              </Grid>
              <Grid item className='max_width'>
                <Grid container className='text_wrapper'>
                  <p className='smaller_text'>
                    CPI Score: {props.rowData.cpi_score}
                  </p>
                </Grid>
                <Grid container className='text_wrapper'>
                  <p className='smaller_text'>
                    Basel AML Index: {props.rowData.basel_index}
                  </p>
                </Grid>
              </Grid>
              <Divider orientation='vertical' flexItem />
              <Grid item xs={3}>
                <Grid container className='text_wrapper'>
                  <p className='smaller_text'>
                    # of Bank Customers: {props.rowData.att_13}
                  </p>
                </Grid>
                <Grid className='text_wrapper' container>
                  <p className='smaller_text'>
                    Total Wire Activity (In/Out): {props.rowData.att_14}
                  </p>
                </Grid>
              </Grid>
              <Grid className='risk_text_wrapper' item xs={3}>
                {props.rowData.proposed_risk_rating === 'HIGH' ? <p className='risk_rating-high'>HIGH</p> : null}
                {props.rowData.proposed_risk_rating === 'MODERATE' ? <p
                  className='risk_rating-moderate'
                >MODERATE
                </p> : null}
                {props.rowData.proposed_risk_rating === 'LOW' ? <p className='risk_rating-low'>LOW</p> : null}
              </Grid>
            </Grid>
            <Divider variant='middle' />
            <Grid container>
              <Grid item className='description-map' xs={9}>
                <Grid container className='description_wrapper'>
                  <p className='country_description'>
                    <b><u>Description of Country:</u></b> {CountryInfo(props.rowData.country_name)}
                  </p>
                </Grid>
                <Grid container className='source_wrapper'>
                  <p className='country_description'><b>*Source: </b> {CountrySource(props.rowData.country_name)}</p>
                </Grid>
              </Grid>
              <Grid item className='description-map autosave-input-wrapper' xs={3}>
                <img
                  className='country_map' alt='Insert Map Here'
                  src={require(`../../assets/images/${props.rowData.country_name}.gif`)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid container className='title-row'>
                <p className='text-margin'><b>AML Risk Factor</b></p>
              </Grid>
              {
                Object.entries(props.settings.settings)
                  .map(([key, value], i) => {
                    let findAttribute
                    if (key.includes('att') && key.includes('name')) {
                      findAttribute = key.slice(0, -5)
                      return (
                        <React.Fragment key={i}>
                          {(i - 3) % 4 === 2
                            ? <Grid container className='shading'>
                              <Grid item xs={9}>
                                <p className='text-margin'>{value}</p>
                              </Grid>
                              <Grid item xs={3}>
                                {props.rowData[findAttribute] === 1 ? <p className='text-margin-bin'>Yes</p> :
                                  <p className='text-margin-bin'>
                                    No
                                 </p>}
                              </Grid>
                              </Grid>
                            : <Grid container>
                              <Grid item xs={9}>
                                <p className='text-margin'>{value}</p>
                              </Grid>
                              <Grid item xs={3}>
                                {props.rowData[findAttribute] === 1 ? <p className='text-margin-bin'>Yes</p> :
                                  <p className='text-margin-bin'>
                                    No
                                  </p>}
                              </Grid>
                              </Grid>}
                          {key !== Object.entries(props.settings.settings) - 1
                            ? <Divider className='editor_style' />
                            : null}
                        </React.Fragment>
                      )
                    } else {
                      return <div key={i} />
                    }
                  }
                  )
              }
            </Grid>
            <Grid container className='title-row' />
            <Grid container className='shading'>
              <Grid item xs={10}>
                <p className='text-margin'><b>Total Number of Affirmative AML Risk Factors</b></p>
              </Grid>
              <Grid item xs={2}>
                <p className='text-margin-bin'>{props.rowData.number_of_attributes}</p>
              </Grid>
            </Grid>
            <Divider
              className='editor_style'
            />
            <Grid container>
              <Grid item xs={10}>
                <p className='text-margin'><b>Unadjusted RQ Score</b></p>
              </Grid>
              <Grid item xs={2}>
                <p className='text-margin-bin'>{props.rowData.total_score}</p>
              </Grid>
            </Grid>
            <Divider
              className='editor_style'
            />
            <Grid container className='shading'>
              <Grid item xs={10}>
                <p className='text-margin'><b>Professional Judgement Modifier**:</b></p>
              </Grid>
              <Grid item xs={2}>
                <p className='text-margin-bin'>{props.rowData.judgemental_addition}</p>
              </Grid>
            </Grid>
            <Divider
              className='editor_style'
            />
            <Grid container>
              <Grid item xs={10}>
                <p className='text-margin'><b>Total RQ Score:</b></p>
              </Grid>
              <Grid item xs={2}>
                <p className='text-margin-bin'><b>{props.rowData.adjusted_score}</b></p>
              </Grid>
            </Grid>
            <Divider
              className='editor_style'
            />
            <Grid className='shading' container>
              <Grid item xs={10}>
                <p className='text-margin'><b>Perceived AML Risk Level:</b></p>
              </Grid>
              <Grid item xs={2}>
                <p className='text-margin-bin'><b>{props.rowData.proposed_risk_rating}</b></p>
              </Grid>
            </Grid>
            <Grid container className='judgement'>
              <Grid item xs={4}>
                <p className='text-margin-judgement'>
                  <b>**Professional Judgement
                    Explanation:
                  </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p className='text-margin-judgement'>
                  {(props.rowData.judgemental_factors_considered)}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item className='positioning' xs={3}>
                <img className='image' src={krLogo} alt='logo' />
              </Grid>
              <Grid item className='positioning-copyright copyright_text' xs={6}>
                <p className='image-1'>Copyright © Kaufman Rossin & Co.</p>
                <p className='image'>PRIVILEGED AND CONFIDENTIAL</p>
              </Grid>
              <Grid item xs={3}>
                <p className='text-margin'><b><u>Risk Rating Key</u></b></p>
                <Card variant='outlined'>
                  <p className='text-margin'>High ≥ 50</p>
                  <p className='text-margin'>Moderate 20 to 49 </p>
                  <p className='text-margin'>Low ≤ 19</p>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

export default CreateCountryProfiles
