import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'

export default function LoadingRing ({ color, className, style, size }) {
  return (
    <Grid container direction='row' justify='center'>
      <Box
        className={`lds-dual-ring  ${className}`}
        justifyContent='center'
        style={{ width: size, height: size, ...style }}
      >
        <div
          className='lds-dual-ring-after'
          style={{
            borderColor: `${color} transparent`,
            borderWidth: size * 0.1,
            width: size * 0.7 - 6,
            height: size * 0.7 - 6
          }}
        />
      </Box>
    </Grid>
  )
}

//
// export default function LoadingRing({color, className, style, size}) {
//     return (
//         <div className={`lds-dual-ring ${className}`} style={{width: size, height: size, ...style}}>
//             <div
//                 className="lds-dual-ring-after"
//                 style={{
//                     borderColor: `${color} transparent`,
//                     borderWidth: size * 0.1,
//                     width: size * 0.7 - 6,
//                     height: size * 0.7 - 6,
//                 }}
//             />
//         </div>
//     )
// }

LoadingRing.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number
}

LoadingRing.defaultProps = {
  color: 'rgb(174, 209, 54)',
  className: '',
  style: {},
  // size: 200,
  size: 250
}
