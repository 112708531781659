export const colors = {
    CHARCOAL_GRAY: {
      hex: "#3c3c3c",
      rgb: "rgb(60,60,60)",
    },
    MIDNIGHT_BLUE: {
      hex: "#1d4c7e",
      rgb: "rgb(30,76,126)",
    },
    APPLE_GREEN: {
      hex: "#aed136",
      rgb: "rgb(174,209,54)",
    },
    BACKGROUND_GRAY: {
      hex: "#f9f9f9",
      rgb: "rgb(249,249,249)",
    },
    BORDER_GRAY: {
      hex: "#bdbdbd",
    },
    TEXT_COLOR: {
      hex: "#000",
    },
  }
  