function CountryInfo (props) {
  switch (props) {
    case 'Afghanistan':
      return 'Terrorist and insurgent financing, money laundering, bulk cash smuggling, Afghanistan’s inability to collect exact customs’ revenue, fraud, and abuse of informal value transfer systems continue to threaten Afghanistan’s security and development. Afghanistan remains the world’s largest opium producer, and methamphetamine production is rising. Corruption remains a significant obstacle to the nation’s progress. The Afghan government has enacted laws and regulations to combat financial crimes but faces a significant challenge in implementing and enforcing existing laws and regulations. Political uncertainty in the coming year stemming from a twice-delayed presidential election could further complicate AML efforts.'
    case 'Aland Islands':
        return 'Finland has a highly industrialized, largely free-market economy with per capita GDP almost as high as that of Austria, Belgium, the Netherlands, or Sweden. Trade is important, with exports accounting for over one-third of GDP in recent years. Finland is not a regional center for money laundering, financial crime, or illegal commerce. The major sources of illegal proceeds in Finland relate to financial crimes, and the majority of investigated suspicious financial activities have an international dimension. The number of organized criminal groups has grown slightly in the past few years, as has the number of their members (totaling approximately 1,000). Illicit funds are normally laundered through currency exchanges and gaming establishments. According to the National Bureau of Investigation, the use of virtual currency, such as bitcoin, as well as phone transactions have become more common in money laundering cases. The report found that the key risk items of money laundering and terrorist financing in Finland are associated with real estate investments, transport of cash, front companies, online services, online shadow financing markets, and customer fund accounts.'
    case 'Albania':
      return 'Albania, a formerly closed, centrally planned state, is a developing country with a modern open-market economy. Albania managed to weather the first waves of the global financial crisis but, the negative effects of the crisis caused a significant economic slowdown. Close trade, remittance, and banking sector ties with Greece and Italy make Albania vulnerable to spillover effects of possible debt crises and weak growth in the euro zone. Complex tax codes and licensing requirements, a weak judicial system, endemic corruption, poor enforcement of contracts and property issues, and antiquated infrastructure contribute to Albania\'s poor business environment making attracting foreign investment difficult. Since 2015, Albania has launched an ambitious program to increase tax compliance and bring more businesses into the formal economy. In July 2016, Albania passed constitutional amendments reforming the judicial system in order to strengthen the rule of law and to reduce deeply entrenched corruption.'
    case 'Algeria':
      return 'The extent of money laundering through Algeria’s formal financial system is understood to be minimal due to stringent regulations and a banking sector dominated by state-owned banks. Algerian authorities monitor the banking system closely. The Algerian financial system is highly bureaucratic and provides for numerous checks on all money transfers. The continued prevalence of archaic, paper-based systems and banking officials not trained to function in the modern international financial system further deter money launderers, who are more likely to use sophisticated transactions. However, a large informal, cash-based economy, estimated at 40 percent of GDP, is vulnerable to abuse by criminals. The real estate market is particularly vulnerable to money laundering. Notable criminal activity includes trafficking, particularly of bulk cash, drugs, cigarettes, arms, and stolen vehicles; theft; extortion; and embezzlement. Public corruption and terrorism remain serious concerns. Porous borders allow smuggling to flourish. The country is generally making progress in its efforts to combat money laundering and financial crimes.'
    case 'American Samoa':
      return 'American Samoa is a traditional Polynesian economy in which more than 90% of the land is communally owned. Economic activity is strongly linked to the US with which American Samoa conducts most of its commerce. Tuna fishing and processing are the backbone of the private sector with processed fish products as the primary exports. The fish processing business accounted for 15.5% of employment in 2015. In late September 2009, an earthquake and the resulting tsunami devastated American Samoa and nearby Samoa, disrupting transportation and power generation, and resulting in about 200 deaths. The US Federal Emergency Management Agency oversaw a relief program of nearly $25 million. Transfers from the US Government add substantially to American Samoa\'s economic well-being. Attempts by the government to develop a larger and broader economy are restrained by Samoa\'s remote location, its limited transportation, and its devastating hurricanes. Tourism has some potential as a source of income and jobs.'
    case 'Andorra':
      return 'Tourism, retail sales, and finance are the mainstays of Andorra\'s tiny, well-to-do economy, accounting for more than three-quarters of GDP. Andorra\'s duty-free status for some products and its summer and winter resorts attract millions of visitors annually, although the economic downturn in neighboring countries has curtailed the number of tourists. Agricultural production is limited - only about 5% of the land is arable - and most food has to be imported, making the economy vulnerable to changes in fuel and food prices. The principal livestock is sheep. Manufacturing output and exports consist mainly of perfumes and cosmetic products, products of the printing industry, electrical machinery and equipment, clothing, tobacco products, and furniture. Andorra is a member of the EU Customs Union and is treated as an EU member for trade in manufactured goods (no tariffs) and as a non-EU member for agricultural products. Andorra uses the euro and is effectively subject to the monetary policy of the European Central Bank. Andorra\'s comparative advantage as a tax haven eroded case borders of neighboring France and Spain opened; its bank secrecy laws have been relaxed under pressure from the EU and OECD.'
    case 'Angola':
      return 'Angola\'s economy is overwhelmingly driven by its oil sector. Oil production and its supporting activities contribute about 50% of GDP, more than 70% of government revenue, and more than 90% of the country\'s exports. Diamonds contribute an additional 5% to exports. Subsistence agriculture provides the main livelihood for most of the people, but half of the country\'s food is still imported. Some of the country\'s infrastructure is still damaged or undeveloped from the 27-year-long civil war. However, the government since 2005 has used billions of dollars in credit lines from China, Brazil, Portugal, Germany, Spain, and the EU to help rebuild Angola\'s public infrastructure. Angola formally abandoned its currency peg in 2009, and in November 2009 signed onto an IMF Stand-By Arrangement loan of $1.4 billion to rebuild international reserves. Consumer inflation declined from 325% in 2000 to less than 9% in 2014. Angola has responded by reducing government subsidies and by proposing import quotas and a more restrictive licensing regime. Corruption, especially in the extractive sectors, is a major long-term challenge.'
    case 'Anguilla':
      return 'Anguilla has few natural resources, and the economy depends heavily on luxury tourism, offshore banking, lobster fishing, and remittances from emigrants. Increased activity in the tourism industry has spurred the growth of the construction sector contributing to economic growth. Anguillan officials have put substantial effort into developing the offshore financial sector, which is small but growing. In the medium term, prospects for the economy will depend largely on the tourism sector and, therefore, on revived income growth in the industrialized nations as well as on favorable weather conditions.'
    case 'Antarctica':
      return 'Scientific undertakings rather than commercial pursuits are the predominant human activity in Antarctica. Offshore fishing and tourism, both based abroad, account for Antarctica\'s limited economic activity. Antarctic Fisheries, within the area covered by the Convention on Conservation of Antarctic Marine Living Resources currently target Patagonian toothfish, Antarctic toothfish, mackerel icefish and Antarctic krill. The Commission for the Conservation of Antarctic Marine Living Resources (CCAMLR) manages these fisheries using the ecosystem-based and precautionary approach. The Commission’s objective is conservation of Antarctic marine living resources and it regulates the fisheries based on the level of information available, and maintaining existing ecological relationships. While Illegal, Unreported and Unregulated (IUU) fishing has declined in the Convention area since 1990, it remains a concern.'
    case 'Antigua and Barbuda':
      return 'Antigua and Barbuda has made some progress on its AML regime. The country finalized a National Risk Assessment (NRA) in 2018, but has yet to implement all the recommendations. As of December 2019, the financial sector includes six domestic banks, nine international banks (offshore banks), 18 insurance companies, eight international insurance companies, four MSBs, one development bank, 37 citizenship by investment agents, and six credit unions. There are eight online gaming and sports betting businesses. As of December 2016, the offshore sector hosted 5,102 IBCs, of which 3,635 were active.'
    case 'Argentina':
      return 'Argentina faces many of the same challenges confronted throughout the region, including stemming the tide of illicit proceeds from narcotics trafficking and public corruption. Illicit finance threats posed by corruption, including proceeds generated domestically and stemming from Venezuela, remain high. The Tri-Border Area (TBA) shared with Brazil and Paraguay is one of the principal routes into Argentina for multi-billion dollar TBML, counterfeiting, drug trafficking, and other smuggling offenses. In addition, many of the money laundering organizations in the TBA have known or suspected links to the terrorist organization Hizballah. In recent years, Argentina has taken significant steps to strengthen its AML/CFT regime. The government also created a National Committee for Combating Money Laundering and Terrorist Financing to coordinate the country’s money laundering and terrorist financing policies, including the development of national risk assessments (NRA) and a national strategy to combat those crimes. Despite these positive steps, limited regulatory and criminal enforcement capabilities continue to raise concerns about the government’s ability to reduce significantly the flow of illicit proceeds.'
    case 'Armenia':
      return 'Armenia does not limit the conversion and transfer of money or the repatriation of capital and earnings, including branch profits, dividends, interest, royalties, and management or technical service fees. The banking system in Armenia is sound and well-regulated, but Armenia\'s financial sector is not highly developed. Although Armenian legislation complies with the Trade Related Aspects of Intellectual Properties (TRIPS) Agreement and offers protection of intellectual property rights (IPR), enforcement efforts need improvement. The Armenian regulatory system lacks transparency. Corruption remains a significant obstacle: although the government has introduced a number of reforms over the last few years, and the overall investment climate seems to be incrementally improving, corruption remains a problem in critical areas such as the judiciary, tax and customs operations, health, education, military and law enforcement. Tax and customs procedures, while having improved, still lack transparency. Although the use of reference prices during customs clearance has reduced, it is still not uncommon to see manipulation of the classification of goods that increases costs for economic operators.'
    case 'Aruba':
      return 'Aruba is not considered a regional financial center. Because of its location, Aruba is a transshipment point for drugs and gold from South America bound for the United States and Europe, and for currency flowing in the opposite direction. Aruba is an autonomous entity within the Kingdom of the Netherlands (Kingdom). The Kingdom retains responsibility for foreign policy and defense, including signing international conventions with the approval of the local parliament. The law enforcement MOU between the Kingdom and the United States for joint training activities and sharing of information in the area of criminal investigation, law enforcement, and interdicting money laundering operations remains active and includes Aruba.'
    case 'Australia':
      return 'The services sector is the largest part of the Australian economy, accounting for about 70% of GDP and 75% of jobs. Australia was comparatively unaffected by the global financial crisis as the banking system has remained strong and inflation is under control. Australia benefited from a dramatic surge in its terms of trade in recent years, although this trend has reversed due to falling global commodity prices. Australia is a significant exporter of natural resources, energy, and food. Australia\'s abundant and diverse natural resources attract high levels of foreign investment and include extensive reserves of coal, iron, copper, gold, natural gas, uranium, and renewable energy sources. A series of major investments, such as the US$40 billion Gorgon Liquid Natural Gas project, will significantly expand the resources sector. Australia is an open market with minimal restrictions on imports of goods and services. The process of opening up has increased productivity, stimulated growth, and made the economy more flexible and dynamic.'
    case 'Austria':
      return 'Austria, with its well-developed market economy, skilled labor force, and high standard of living, is closely tied to other EU economies, especially Germany\'s. Its economy features a large service sector, a relatively sound industrial sector, and a small, but highly developed agricultural sector. Austria\'s 5.8% unemployment rate, while low by European standards, is at its highest rate since the end of World War II, driven by an increased number of refugees and EU migrants entering the labor market. Without extensive vocational training programs and generous early retirement, the unemployment rate would be even higher. Although Austria\'s fiscal position compares favorably with other euro-zone countries, it faces several external risks, such as unexpectedly weak world economic growth threatening the export market, Austrian banks\' continued exposure to Central and Eastern Europe, repercussions from the Hypo Alpe Adria bank collapse, political and economic uncertainties caused by the European sovereign debt crisis, the current refugee crisis, and continued unrest in Russia/Ukraine.'
    case 'Azerbaijan':
      return 'Weak regulatory oversight and political involvement in Azerbaijan’s banking sector, coupled with Azerbaijan’s physical location between Iran and Russia, create an environment conducive to the transit of illicit funds. The majority of foreign investment and international trade in Azerbaijan continues to be in the energy sector. Azerbaijan’s government is working to diversify the economy away from energy by prioritizing investments in agriculture, tourism, trade logistics, and telecommunications and information technology. '
    case 'Bahamas':
      return 'The Bahamas remains a transit point for trafficking in illegal drugs, firearms, and persons to and from the United States. As an international financial center, the country is vulnerable to money laundering in various sectors, such as financial services, real estate, casino gambling, and online gaming. Although The Bahamas has taken significant steps toward strengthening its AML regime, potential vulnerabilities in the online gaming and money transfer business sectors are further exacerbated by certain regulators’ reluctance to acknowledge them.'
    case 'Bahrain':
      return 'Oil comprises 86% of Bahraini budget revenues, despite past efforts to diversify its economy and to build communication and transport facilities for multinational firms with business in the Gulf. As part of its diversification plans, Bahrain implemented a Free Trade Agreement (FTA) with the US in August 2006, the first FTA between the US and a Gulf state. Other major economic activities are production of aluminum - Bahrain\'s second biggest export after oil - finance, and construction. Bahrain continues to seek new natural gas supplies as feedstock to support its expanding petrochemical and aluminum industries. In 2011, Bahrain experienced economic setbacks as a result of domestic unrest driven by the majority Shia population, however, the economy recovered in 2012-15, partly as a result of improved tourism. In addition to addressing its current fiscal woes, Bahraini authorities face the long-term challenge of boosting Bahrain’s regional competitiveness—especially regarding industry, finance, and tourism—and reconciling revenue constraints with popular pressure to maintain generous state subsidies and a large public sector.'
    case 'Bangladesh':
      return 'Bangladesh\'s economy has grown roughly 6% per year since 1996 despite political instability, poor infrastructure, corruption, insufficient power supplies, slow implementation of economic reforms, and the 2008-09 global financial crisis and recession. Although more than half of GDP is generated through the services sector, almost half of Bangladeshis are employed in the agriculture sector, with rice as the single-most-important product. Garment exports, the backbone of Bangladesh\'s industrial sector, account for more than 80% of total exports. The sector continues to grow, despite a series of factory accidents that have killed more than 1,000 workers, and crippling strikes, including a nationwide transportation blockade implemented by the political opposition. Steady garment export growth combined with remittances from overseas Bangladeshis are the largest contributors to Bangladesh\'s sustained economic growth and rising foreign exchange reserves.'
    case 'Barbados':
      return 'Barbados made some progress on its AML system. Barbados has completed an initial risk assessment, which identifies drug trafficking as the main source of money laundering in the country. Barbados continues its work on a more comprehensive national risk assessment (NRA) amid concerns the previous NRA may not have been sufficient in identifying significant national money laundering risks and vulnerabilities. Barbados has an active international financial services sector. It does not have FTZs or an economic citizenship program.'
    case 'Belarus':
      return 'About 80% of all industry remains in state hands, and foreign investment has been hindered by a climate hostile to business. A few banks, which had been privatized after independence, were renationalized. State banks account for 75% of the banking sector. Economic output, which had declined for several years following the collapse of the Soviet Union, revived in the mid-2000s due to the boom in oil prices. Belarus has only small reserves of crude oil, though it imports most of its crude oil and natural gas from Russia at prices substantially below the world market. Belarus exported refined oil products at market prices produced from Russian crude oil purchased at a steep discount. Recently, the plunge in global oil prices heavily reduced revenues. Little new foreign investment has occurred in recent years.'
    case 'Belgium':
      return 'This modern, open, and private-enterprise-based economy has capitalized on its central geographic location, highly developed transport network, and diversified industrial and commercial base. Industry is concentrated mainly in the more heavily-populated region of Flanders in the north. With few natural resources, Belgium imports substantial quantities of raw materials and exports a large volume of manufactures, making its economy vulnerable to shifts in foreign demand, particularly with Belgium’s EU trade partners. Roughly three-quarters of Belgium\'s trade is with other EU countries. Low wage growth and high unemployment promise to curtail a more robust recovery in private consumption. The government has pledged to pursue a reform program to improve Belgium’s competitiveness, including changes to tax policy, labor market rules, and welfare benefits. These changes risk worsening tensions with trade unions and triggering extended strikes.'
    case 'Belize':
      return 'Belize has made significant efforts to improve its AML/CFT regime. Through a series of legislative reforms and a proactive approach to addressing risk, Belize made the strides necessary to improve its financial regulatory capacity and be removed from the EU tax haven “blacklist.”  Belize is still primarily a cash economy, and its location, porous borders, poverty, and limited material and personnel resources leave it vulnerable to illicit trafficking, illegal migration, transnational criminal organizations, and corruption. Belize has an active offshore financial sector but is not a key regional financial player. The government is taking steps to close these vulnerabilities. Belize continues to build its FIU’s capacity. The FIU gave an education seminar for DNFBPs on AML/CFT legislation.'
    case 'Benin':
      return 'Benin’s main east-west road forms part of the high-volume Abidjan-Lagos transportation corridor, and the Port of Cotonou is a shipping hub for the sub-region, serving Nigeria and landlocked countries in the Sahel. Criminal networks exploit the volume of goods and people moving through Benin. Benin is a transit point for a significant volume of drugs and precursors moving from South America, Pakistan, and Nigeria into Europe, Southeast Asia, and South Africa. It is difficult to estimate the extent of drug-related money laundering in Benin, believed to be done through the purchase or construction of real estate for rent or re-sale, casinos, bulk cash smuggling, and payments to officials. Benin has a specialized trial court, the Economic Crimes and Terrorism Court (CRIET), for economic and financial crime. The majority of financial crime cases before the CRIET recently were related to financial scams, including organized crime- and cyber-based.'
    case 'Bermuda':
      return 'Tourism accounts for about 5% of Bermuda\'s GDP, but a much larger share of employment. Over 80% of its visitors come from the US. The sector struggled in the wake of the global recession of 2008-09. International business, which consists primarily of reinsurance and other financial services, is the real bedrock of Bermuda\'s economy, consistently accounting for about 85% of the island\'s GDP. Even this sector, however, has lost high-paying expatriate jobs since 2008, weighing heavily on household consumption and retail sales. Bermuda must import almost everything. Agriculture and industry are limited due to the small size of the island. Still, Bermuda enjoys the fourth highest per capita income in the world, about 70% higher than that of the US.'
    case 'Bhutan':
      return 'Bhutan\'s economy, small and less developed, is based largely on hydropower, agriculture, and forestry, which provide the main livelihood for more than half of the population. Because rugged mountains dominate the terrain and make the building of roads and other infrastructure difficult and expensive, industrial production is primarily of the cottage industry type. The economy is closely aligned with India\'s through strong trade and monetary links and is dependent on India for financial assistance and migrant laborers for development projects, especially for road construction. Multilateral development organizations administer most educational, social, and environment programs, and take into account the government\'s desire to protect the country\'s environment and cultural traditions. For example, the government, in its cautious expansion of the tourist sector, encourages visits by upscale, environmentally conscientious tourists. Complicated controls and uncertain policies in areas such as industrial licensing, trade, labor, and finance continue to hamper foreign investment.'
    case 'Bolivia':
      return 'Bolivia is not a regional financial center but remains vulnerable to money laundering. Criminal proceeds laundered in Bolivia are derived primarily from smuggling contraband and the drug trade. In recent years, Bolivia has enacted several laws and regulations that, taken together, should help the country more actively fight money laundering. Bolivia should continue its implementation of its laws and regulations with the goal of identifying criminal activity that results in investigations, criminal prosecutions, and convictions.'
    case 'Bonaire, Saint Eustatius and Saba':
      return 'The Netherlands Antilles dissolved on October 10, 2010. Six islands in the Caribbean fall under the jurisdiction of the Kingdom of the Netherlands. Bonaire, St. Eustatius, and Saba are special municipalities of the Netherlands. Aruba, Curacao, and St. Maarten are countries within the Kingdom of the Netherlands. The Netherlands provides supervision for the courts and for combating crime and drug trafficking within the Kingdom. As special municipalities, Bonaire, St. Eustatius, and Saba are officially considered “public bodies” under Dutch law. In February 2018, the Sint Eustatius island council (governing body) was dissolved and replaced by a government commissioner to restore the integrity of public administration. According to the Dutch Government, the intervention will be as "short as possible and as long as needed."'
    case 'Bosnia and Herzegovina':
      return 'Bosnia and Herzegovina (BiH) has a primarily cash-based economy and is not an international or regional financial center. BiH is a market and transit point for smuggled commodities, including cigarettes, firearms, counterfeit goods, lumber, and fuel oil and, during the last year, the country faced significant problems with illegal migration. BiH has made substantial progress, not only in strengthening its AML regime, but also harmonizing its laws across its numerous legal systems, including laws related to money laundering and asset forfeiture. BiH has a complex legal and regulatory framework with criminal codes and financial sector laws at the state and entity levels (Federation of BiH (FBiH) and Republika Srpska (RS)), and in the Brčko District (BD). However, the BiH justice sector still performs relatively poorly in the areas of money laundering and forfeiture. Specifically, prosecutor’s offices and law enforcement oftentimes lack expertise to conduct large-scale financial investigations. Furthermore, judges often have a hard time comprehending expert testimony, which leads to poor verdicts. International donors conduct ongoing capacity building activities that aim to improve the level of effectiveness of judges and prosecutors.'
    case 'Botswana':
      return 'Botswana has maintained one of the world\'s highest economic growth rates since independence in 1966. Diamond mining has fueled much of the expansion and currently accounts for one quarter of GDP, approximately 85% of export earnings, and about one-third of the government\'s revenues. Tourism is the secondary earner of foreign exchange and many Batswana engage in subsistence farming and cattle raising. Through fiscal discipline and sound management, Botswana has transformed itself from one of the poorest countries in the world to a middle-income country. Two major investment services rank Botswana as the best credit risk in Africa. Botswana\'s economy is highly correlated with global economic trends because of its heavy reliance on a single luxury export. According to official government statistics, unemployment is 19.5%, but unofficial estimates run much higher.'
    case 'Bouvet Island':
      return 'This uninhabited, volcanic, Antarctic island is almost entirely covered by glaciers making it difficult to approach; it is recognized as the most remote island on Earth. (It is furthest in distance from any other point of land, 1,639 km from Antarctica.) Bouvet Island was discovered in 1739 by a French naval officer after whom it is named. No claim was made until 1825, case British flag was raised. A few expeditions visited the island in the late 19th century. In 1929, the UK waived its claim in favor of Norway, which had occupied the island two years previously. In 1971, Norway designated Bouvet Island and the adjacent territorial waters a nature reserve. Since 1977, Norway has run an automated meteorological station and studied foraging strategies and distribution of fur seals and penguins on the island. In February 2006, an earthquake weakened the station\'s foundation causing it to be blown out to sea in a winter storm. Norway erected a new research station in 2014 that can hold six people for periods of two to four months.'
    case 'Brazil':
      return 'Brazil’s economy remains the second largest in the Western Hemisphere in 2019 and among the ten largest in the world. Brazil is a major drug transit country and one of the world’s largest drug consumers. Transnational criminal organizations operate throughout Brazil and launder proceeds from trafficking operations and human smuggling. A multi-billion dollar contraband trade occurs in the Tri-Border Area (TBA) where Brazil shares borders with Paraguay and Argentina. Illicit networks in the TBA provide financial support to Hizballah, a U.S. Department of State-designated Foreign Terrorist Organization and a U.S. Department of the Treasury Specially Designated Global Terrorist. Public corruption is law enforcement’s primary money laundering priority, followed by narcotics trafficking. In February, Brazil’s Congress passed legislation to remedy CFT deficiencies related to the implementation of targeted sanctions for terrorist financiers designated by the UN Security Council.'
    case 'British Indian Ocean Territory':
      return 'Formerly administered as part of the British Crown Colony of Mauritius, the British Indian Ocean Territory (BIOT) was established as an overseas territory of the UK in 1965. A number of the islands of the territory were later transferred to the Seychelles case ttained independence in 1976. Subsequently, BIOT has consisted only of the six main island groups comprising the Chagos Archipelago. Only Diego Garcia, the largest and most southerly of the islands, is inhabited. It contains a joint UK-US naval support facility and hosts one of four dedicated ground antennas that assist in the operation of the Global Positioning System (GPS) navigation system (the others are on Kwajalein (Marshall Islands), at Cape Canaveral, Florida (US), and on Ascension Island (Saint Helena, Ascension, and Tristan da Cunha)). The US Air Force also operates a telescope array on Diego Garcia as part of the Ground-Based Electro-Optical Deep Space Surveillance System (GEODSS) for tracking orbital debris, which can be a hazard to spacecraft and astronauts.'
    case 'Brunei':
      return 'Brunei is an energy-rich sultanate on the northern coast of Borneo in Southeast Asia. Brunei boasts a well-educated, largely English-speaking population; excellent infrastructure; and a stable government intent on attracting foreign investment. Crude oil and natural gas production account for approximately 65% of GDP and 95% of exports, with Japan as the primary export market. Per capita GDP is among the highest in the world, and substantial income from overseas investment supplements income from domestic hydrocarbon production. Bruneian citizens do not pay personal income taxes, and the government provides free medical services and free education through the university level. The Bruneian Government wants to diversify its economy away from hydrocarbon exports to other industries such as information and communications technology and halal manufacturing.'
    case 'Bulgaria':
      return 'Bulgaria, a former communist country that entered the EU on 1 January 2007, averaged more than 6% annual growth from 2004 to 2008, driven by significant amounts of bank lending, consumption, and foreign direct investment. Successive governments have demonstrated a commitment to economic reforms and responsible fiscal planning, but the global downturn sharply reduced domestic demand, exports, capital inflows, and industrial production. GDP contracted by 5.5% in 2009, and has been slow to recover in the years since. Despite a favorable investment regime, including low, flat corporate income taxes, significant challenges remain. Corruption in public administration, a weak judiciary, and the presence of organized crime continue to hamper the country\'s investment climate and economic prospects.'
    case 'Burkina Faso':
      return 'Burkina Faso is a poor, landlocked country that depends on adequate rainfall. About 80% of the population is engaged in subsistence farming and cotton is the main cash crop. The country has few natural resources and a weak industrial base. Cotton and gold are Burkina Faso’s key exports - gold has accounted for about three-quarters of the country’s total export revenues. Burkina Faso’s economic growth and revenue depends on global prices for the two commodities. The Burkinabe economy experienced high levels of growth over the last few years, and the country has seen an upswing in gold exploration, production, and exports. A new Political insecurity in neighboring Mali, unreliable energy supplies, and poor transportation links pose long-term challenges.'
    case 'Burma (Myanmar)':
      return 'Burma’s economy and financial sector are underdeveloped, and most currency is still held outside of the formal banking system. Burma has porous borders and significant natural resources, many of which are in parts of the country that the government does not fully control. Recently, the Burmese government made progress regarding its AML/CFT regulatory oversight. The government issued two regulations to improve its AML regulatory framework and passed new legislation to regulate casinos. In addition, the government of Burma demonstrated renewed political will to address money laundering issues, including the establishment of a new AML task force. Burma is designated as a jurisdiction of “primary money laundering concern” under Section 311 of the USA PATRIOT Act, but the U.S. Department of Treasury began waiving the legal ramifications in 2012 and issued an administrative exception in 2016, allowing U.S. financial institutions to provide correspondence services to Burmese banks.'
    case 'Burundi':
      return 'Burundi is a landlocked, resource-poor country with an underdeveloped manufacturing sector. Agriculture accounts for over 40% of GDP and employs more than 90% of the population. Burundi\'s primary exports are coffee and tea, which account for 90% of foreign exchange earnings. Thus, Burundi\'s export earnings - and its ability to pay for imports - rest primarily on weather conditions and international coffee and tea prices, although exports are a relatively small share of GDP. Burundi is heavily dependent on aid from bilateral and multilateral donors. Political stability, aid flows, and economic activity improved following the end of the civil war, but underlying weaknesses - a high poverty rate, poor education rates, a weak legal system, a poor transportation network, overburdened utilities, and low administrative capacity – have prevented the government from implementing planned economic reforms. Government corruption has also hindered the development of a private sector as companies have to deal with ever changing rules. The purchasing power of most Burundians has decreased as wage increases have not kept pace with inflation.'
    case 'Cambodia':
      return 'Cambodia is neither a regional nor an offshore financial center. Cambodia’s money laundering vulnerabilities include a weak AML regime; a cash-based, largely dollarized economy; porous borders; loose oversight of casinos; and the National Bank of Cambodia’s limited capacity to oversee the fast-growing financial and banking industries. A weak, deeply politicized judicial system and corruption also constrain effective enforcement. Cambodia has a significant black market for smuggled goods, including drugs and imported substances for local production of methamphetamine. Both legal and illicit transactions, regardless of size, are frequently conducted outside of formal financial institutions and are difficult to monitor. Cash proceeds from crime are readily channeled into land, housing, luxury goods and vehicles, and other forms of property, without passing through the formal banking sector. Casinos along the Thailand and Vietnam borders are other potential avenues to launder money.'
    case 'Cameroon':
      return 'Modest oil resources and favorable agricultural conditions provide Cameroon with one of the best-endowed primary commodity economies in Sub-Saharan Africa. Oil remains Cameroon’s main export commodity, and despite falling global oil prices, still accounts for nearly 40% of export earnings. Cameroon’s economy suffers from factors that often impact underdeveloped countries, such as stagnant per capita income, a relatively inequitable distribution of income, a top-heavy civil service, endemic corruption, continuing inefficiencies of a large parastatal system in key sectors, and a generally unfavorable climate for business enterprise. Cameroon devotes significant resources to several large infrastructure projects currently under construction, including a deep sea port in Kribi and the Lom Pangar Hydropower Project. Cameroon’s energy sector continues to diversify, recently opening a natural gas powered electricity generating plant. Cameroon continues to seek foreign investment to improve its inadequate infrastructure, create jobs, and improve its economic footprint, but its unfavorable business environment remains a significant deterrent to foreign investment.​'
    case 'Canada':
      return 'Money laundering in Canada involves the proceeds of illegal drug trafficking, fraud, corruption, counterfeiting and piracy, and tobacco smuggling and trafficking, among others. Foreign generated proceeds of crime are laundered in Canada, and professional, third-party money laundering is a key concern. Transnational organized crime groups and professional money launderers are key threat actors. Although the legislative framework does not allow law enforcement to have direct access to Canada’s FIU databases, financial intelligence is received and disclosed effectively. The government should take steps to increase enforcement and prosecution.'
    case 'Cape Verde':
      return 'Cape Verde’s mid-Atlantic location and its land-to-water ratio make it vulnerable to narcotics trafficking between West Africa, the Americas, and Europe. Its financial system is primarily composed of the banking sector. Although Cabo Verde’s AML regime has flaws, the government has revised its laws, policies, and regulations to create the tools to curb illicit financial activities. The AML framework, established in 2009, led to improved shipping container monitoring and information sharing. Cabo Verde receives international support to fight drug trafficking, money laundering, and other crimes.'
    case 'Cayman Islands':
      return 'The Cayman Islands, a UK overseas territory, is a major international financial center. It is the seventh largest foreign holder of U.S. Treasury securities and the 12th largest holder of international assets and liabilities. As of September 2019, the Cayman Islands had 122 banks;  144 trust company licenses; 144 licenses for company management and corporate service providers; 776 insurance-related licenses; and five MSBs that provide a range of services including:  banking, structured finance, investment funds, trusts and company formation, and management. There are 110,451 companies incorporated or registered in the Cayman Islands and 10,937 licensed/registered mutual funds. The Cayman Islands has an established AML/CFT/counter-proliferation financing regime. The government is committed to strengthening its AML/CFT framework.'
    case 'Central African Republic':
      return 'Subsistence agriculture, together with forestry and mining, remains the backbone of the economy of the Central African Republic (CAR), with about 60% of the population living in outlying areas. The agricultural sector generates more than half of GDP. Timber and diamonds account for most export earnings, followed by cotton. Important constraints to economic development include the CAR\'s landlocked geography, poor transportation system, largely unskilled work force, and legacy of misdirected macroeconomic policies. Factional fighting between the government and its opponents remains a drag on economic revitalization. Distribution of income is extraordinarily unequal. Grants from France and the international community can only partially meet humanitarian needs.'
    case 'Chad':
      return 'Chad’s landlocked location results in high transportation costs for imported goods and dependence on neighboring countries. Oil and agriculture are mainstays of Chad’s economy. Oil provides about 60% of export revenues, while cotton, cattle, livestock, and gum arabic provide the bulk of Chad\'s non-oil export earnings. The services sector contributes about one-third of GDP and has attracted foreign investment mostly through telecommunications and banking. Nearly all of Chad’s fuel is provided by one domestic refinery, and unanticipated shutdowns occasionally result in shortages. The country regulates the price of domestic fuel, providing an incentive for black market sales. Chad’s fiscal position is encumbered by declining oil prices, though high oil prices and strong local harvests supported the economy in recent years. Chad relies on foreign assistance and foreign capital for much public and private sector investment. Chad\'s investment climate remains challenging due to limited infrastructure, a lack of trained workers, extensive government bureaucracy, and corruption.'
    case 'Chile':
      return 'Chile has a market-oriented economy characterized by a high level of foreign trade and a reputation for strong financial institutions and sound policy that have given it the strongest sovereign bond rating in South America. Exports of goods and services account for approximately one-third of GDP, with commodities making up some 60% of total exports. Copper alone provides 20% of government revenue. Chile deepened its longstanding commitment to trade liberalization with the signing of a free trade agreement with the US, which took effect on 1 January 2004. Chile has 22 trade agreements covering 60 countries including agreements with the EU, Mercosur, China, India, South Korea, and Mexico. In May 2010, Chile signed the OECD Convention, becoming the first South American country to join the OECD. The Chilean Government has generally followed a countercyclical fiscal policy, accumulating surpluses in sovereign wealth funds during periods of high copper prices and economic growth, and generally allowing deficit spending only during periods of low copper prices and growth.'
    case 'China':
      return 'The development of China’s financial sector has required increased enforcement efforts to keep pace with the sophistication and reach of criminal networks. Chinese authorities continue to identify new money laundering methods, including illegal fundraising activity; cross-border telecommunications fraud; weapons of mass destruction, proliferation, and other illicit financial activity linked to North Korea; and corruption in the banking, securities, and transportation sectors. While China continues to make improvements to its AML legal and regulatory framework, there are shortcomings in implementing laws and regulations effectively and ensuring transparency, especially in the context of international cooperation. China should cooperate with international law enforcement in investigations regarding indigenous Chinese underground financial systems, virtual currencies, shell companies, and trade-based value transfers that are used for illicit transfers.'
    case 'Christmas Island':
      return 'Although Europeans had sighted the island at least as early as 1615, it was only named in 1643 for the day of its rediscovery. The island was annexed and settlement began by the UK in 1888 with the discovery of the island\'s phosphate deposits. Following the Second World War, Christmas Island came under the jurisdiction of the new British Colony of Singapore. The island existed as a separate Crown colony from 1 January 1958 to 1 October 1958 case transfer to Australian jurisdiction was finalized. That date is still celebrated on the first Monday in October as Territory Day. Almost two-thirds of the island has been declared a national park. The main economic activities on Christmas Island are the mining of low grade phosphate, limited tourism, the provision of government services and, since 2005, the construction and operation of the Immigration Detention Center. The government sector includes administration, health, education, policing, customs, quarantine, and defense.'
    case 'Cocos (Keeling) Islands':
      return 'There are 27 coral islands in the group. Captain William Keeling discovered the islands in 1609, but they remained uninhabited until the 19th century. From the 1820s to 1978, members of the Clunies-Ross family controlled the islands and the copra produced from local coconuts. Annexed by the UK in 1857, the Cocos Islands were transferred to the Australian Government in 1955. Apart from North Keeling Island, which lies 30 kilometers north of the main group, the islands form a horseshoe-shaped atoll surrounding a lagoon. North Keeling Island was declared a national park in 1995 and is administered by Parks Australia. The population on the two inhabited islands generally is split between the ethnic Europeans on West Island and the ethnic Malays on Home Island. Coconuts, grown throughout the islands, are the sole cash crop. Small local gardens and fishing contribute to the food supply, but additional food and most other necessities must be imported from Australia. There is a small tourist industry.'
    case 'Colombia':
      return 'Colombia has one of Latin America’s most rigorous AML regimes, but money laundering persists throughout its economy, involving proceeds from drug trafficking, illegal mining, extortion, and corruption. Colombia has an impressive ability to detect money laundering but should continue to improve interdiction, prosecution, and interagency cooperation in order to disrupt drug trafficking and other illegal activities and deprive criminals of ill-gotten gains.'
    case 'Comoros':
      return 'One of the world\'s poorest countries, Comoros is made up of three islands that are hampered by inadequate transportation links, a young and rapidly increasing population, and few natural resources. The low educational level of the labor force contributes to a subsistence level of economic activity and a heavy dependence on foreign grants and technical assistance. Agriculture, including fishing, hunting, and forestry, accounts for 50% of GDP, employs 80% of the labor force, and provides most of the exports. Export income is heavily reliant on the three main crops of vanilla, cloves, and ylang-ylang; and Comoros\' export earnings are easily disrupted by disasters such as fires and extreme weather. Despite agriculture’s importance to the economy, the country imports roughly 70% of its food; rice, the main staple, accounts for the bulk of imports.'
    case 'Congo, Democratic Republic of the':
      return 'The economy of the Democratic Republic of the Congo - a nation endowed with vast natural resource wealth - is slowly recovering after decades of decline. Systemic corruption since independence in 1960, combined with countrywide instability and conflict that began in the early-90s, has dramatically reduced national output and government revenue and increased external debt. With the installation of a transitional government in 2003 after peace accords, economic conditions slowly began to improve as the transitional government reopened relations with international financial institutions and international donors, and President KABILA began implementing reforms. Progress has been slow to reach the interior of the country although clear changes are evident in Kinshasa and Lubumbashi. Renewed activity in the mining sector, the source of most export income, has boosted Kinshasa\'s fiscal position and GDP growth in recent years, although recent commodity price declines threaten to erase progress. An uncertain legal framework, corruption, and a lack of transparency in government policy are long-term problems for the large mining sector and for the economy as a whole.'
    case 'Congo, Republic of the':
      return 'The economy is a mixture of subsistence farming and hunting, an industrial sector based largely on oil and support services, and government spending. Oil has supplanted forestry as the mainstay of the economy, providing a major share of government revenues and exports. Natural gas is increasingly being converted to electricity rather than being flared, greatly improving energy prospects. New mining projects, particularly iron ore, may add as much as $1 billion to annual government revenue. Economic reform efforts have been undertaken with the support of international organizations, notably the World Bank and the IMF, including the recently concluded Article IV consultations. The current administration faces difficult economic challenges of stimulating recovery and reducing poverty. The recent drop in oil prices has constrained government spending; lower oil prices forced the government to cut more than $1 billion in planned spending.'
    case 'Cook Islands':
      return 'Like many other South Pacific island nations, the Cook Islands\' economic development is hindered by the isolation of the country from foreign markets, the limited size of domestic markets, lack of natural resources, periodic devastation from natural disasters, and inadequate infrastructure. Agriculture, employing more than one-quarter of the working population, provides the economic base with major exports of copra and citrus fruit. Black pearls are the Cook Islands\' leading export. Manufacturing activities are limited to fruit processing, clothing, and handicrafts. Trade deficits are offset by remittances from emigrants and by foreign aid overwhelmingly from New Zealand. In the 1980s and 1990s, the country lived beyond its means, maintaining a bloated public service and accumulating a large foreign debt. Subsequent reforms, including the sale of state assets, the strengthening of economic management, the encouragement of tourism, and a debt restructuring agreement, have rekindled investment and growth.'
    case 'Costa Rica':
      return 'Transnational criminal organizations leverage Costa Rica as a base for financial crimes due to enforcement challenges, resource limitations, and its geographic location on a key transit route for narcotics and illicit goods. Costa Rica has sustained improvement of its supervision framework and approved legislation to strengthen enforcement capacity and money laundering prevention mechanisms. Gaps remain, however, and additional funding for key units, improved asset forfeiture provisions, and swifter procedures for records and information sharing could mitigate current challenges.'
    case 'Cte d\'Ivoire':
      return 'Côte d\'Ivoire is heavily dependent on agriculture and related activities, which engage roughly two-thirds of the population. Cote d\'Ivoire is the world\'s largest producer and exporter of cocoa beans and a significant producer and exporter of coffee and palm oil. Consequently, the economy is highly sensitive to fluctuations in international prices for these products and in climatic conditions. Cocoa, oil, and coffee are the country\'s top export revenue earners, but the country is also mining gold. Following the end of more than a decade of civil conflict in 2011, Cote d’Ivoire has experienced a boom in foreign investment and economic growth. In June 2012, the IMF and the World Bank announced $4.4 billion in debt relief for Cote d\'Ivoire under the Highly Indebted Poor Countries Initiative.'
    case 'Croatia':
      return 'Though still one of the wealthiest of the former Yugoslav republics, Croatia\'s economy suffered badly during the 1991-95 war. The country\'s output during that time collapsed, and Croatia missed the early waves of investment in Central and Eastern Europe that followed the fall of the Berlin Wall. Between 2000 and 2007, however, Croatia\'s economic fortunes began to improve with moderate but steady GDP growth between 4% and 6% led by a rebound in tourism and credit-driven consumer spending. Inflation over the same period remained tame and the currency, the kuna, stable. Croatia experienced an abrupt slowdown in the economy in 2008 and has yet to recover; economic growth was stagnant or negative in each year since 2009. Difficult problems still remain including a stubbornly high unemployment rate, uneven regional development, and a challenging investment climate. Croatia continues to face reduced foreign investment.'
    case 'Cuba':
      return 'Cuba is not a regional financial center. Cuban financial practices and U.S. sanctions continue to prevent Cuba’s banking system from fully integrating into the international financial system. The government-controlled banking sector renders Cuba an unattractive location for large-scale money laundering through financial institutions. The centrally-planned economy allows for little, and extremely regulated, private activity. However, a significant black market operates parallel to the heavily subsidized and rationed formal market dominated by the state and which state authorities actively participate in and benefit from. The Government of Cuba does not identify money laundering as a major problem. The Cuban government and state-controlled businesses actively engage in money laundering in order to evade U.S. sanctions. Cuba should increase the transparency of its financial sector and continue to increase its engagement with the regional and international AML/CFT communities to expand its capacity to fight illegal activities. Cuba should increase the transparency of criminal investigations and prosecutions.'
    case 'Curacao':
      return 'Curacao’s prominent position as a regional financial center is declining, but it is still considered a transshipment point for drugs and gold from South America. Money laundering occurs through the sale of illegal narcotics, unlicensed moneylenders, online gaming, and the transfer of gold from South America. Curacao is an autonomous entity within the Kingdom of the Netherlands (Kingdom). The Kingdom retains responsibility for foreign policy and defense, including signing international conventions, with the approval of the local parliament. The law enforcement MOU between the Kingdom and the United States for joint training activities and sharing of information in the area of criminal investigation, law enforcement, and interdicting money laundering operations remains active and includes Curacao.'
    case 'Cyprus':
      return 'The Republic of Cyprus (ROC) is the only internationally recognized government on the island, but since 1974 the northern part of Cyprus has been administered by Turkish Cypriots. The north proclaimed itself the “Turkish Republic of Northern Cyprus” (“TRNC”) in 1983, but the United States does not recognize the “TRNC,” nor does any country other than Turkey. A buffer zone patrolled by the UN Peacekeeping Force in Cyprus separates the two sides. The ROC continues to upgrade its established AML legal framework. As a regional financial and corporate services center, Cyprus has a significant number of nonresident businesses. By law, all companies registered in Cyprus must disclose their ultimate beneficial owners to authorities. The area administered by Turkish Cypriots lacks the legal and institutional framework necessary to prevent and combat money laundering. Turkish Cypriot authorities have taken steps to address some major deficiencies, although “laws” are not sufficiently enforced to effectively prevent money laundering. The casino sector and the offshore banking sector remain of concern.'
    case 'Czech Republic':
      return 'Czechia is a stable and prosperous market economy that is closely integrated with the EU, especially since the country\'s EU accession in 2004. The auto industry is the largest single industry, and, together with its upstream suppliers, accounts for nearly 24% of Czech manufacturing. Czechia produced more than a million cars for the first time in 2010, over 80% of which were exported. While the conservative, inward-looking Czech financial system has remained relatively healthy, the small, open, export-driven Czech economy remains sensitive to changes in the economic performance of its main export markets, especially Germany. Foreign and domestic businesses alike voice concerns about corruption, especially in public procurement. Other long term challenges include dealing with a rapidly aging population, funding an unsustainable pension and health care system, and diversifying away from manufacturing and toward a more high-tech, services-based, knowledge economy.'
    case 'Denmark':
      return 'This thoroughly modern market economy features a high-tech agricultural sector, advanced industry with world-leading firms in pharmaceuticals, maritime shipping and renewable energy, and a high dependence on foreign trade. Denmark is a net exporter of food, oil, and gas and enjoys a comfortable balance of payments surplus, but depends on imports of raw materials for the manufacturing sector. Danes enjoy a high standard of living and the Danish economy is characterized by extensive government welfare measures and an equitable distribution of income. An aging population will be a major long-term issue. Denmark is a member of the EU; Danish legislation and regulations conform to EU standards on almost all issues. Despite previously meeting the criteria to join the European Economic and Monetary Union, Denmark has negotiated an opt-out with the EU and is not required to adopt the euro. Within the EU, Denmark is among the strongest supporters of trade liberalization.'
    case 'Djibouti':
      return 'Djibouti\'s economy is based on service activities connected with the country\'s strategic location as a deepwater port on the Red Sea. Three-fourths of Djibouti\'s inhabitants live in the capital city; the remainder are mostly nomadic herders. Scant rainfall and less than 4% arable land limits crop production to small quantities of fruits and vegetables, and most food must be imported. Djibouti provides services as both a transit port for the region and an international transshipment and refueling center. Imports, exports, and re-exports represent 70% of port activity at Djibouti\'s container terminal. Reexports consist primarily of coffee from landlocked neighbor Ethiopia. Djibouti has few natural resources and little industry. The nation is, therefore, heavily dependent on foreign assistance to help support its balance of payments and to finance development projects. An official unemployment rate of nearly 50% - with youth unemployment near 80% - continues to be a major problem.'
    case 'Dominica':
      return 'Despite the devastation caused by Hurricanes Irma and Maria in 2017, Dominica made some progress on its AML regime in 2018. Dominica began a national risk assessment (NRA) in 2016. The findings of the NRA will provide a roadmap for the future. Dominica reports there are currently 17 offshore banks regulated by the Financial Services Unit (FSU), which also licenses and supervises credit unions, insurance companies, internet gaming companies, and the country’s economic citizenship program.'
    case 'Dominican Republic':
      return 'The Dominican Republic (DR) is a major transshipment point for illicit narcotics destined for the United States and Europe. The eight international airports, 16 seaports, 800-mile coastline, and a large porous frontier with Haiti present Dominican authorities with serious challenges. The DR is not a major regional financial center, despite having one of the largest economies in the Caribbean. Corruption within the government and the private sector, the presence of international illicit trafficking cartels, a large informal economy, and weak financial controls make the DR vulnerable to money laundering threats. Financial institutions in the DR engage in currency transactions involving international narcotics trafficking proceeds that include significant amounts of U.S. currency or currency derived from illegal drug sales in the United States. Recently, the DR FIU was readmitted to the Egmont Group as a result of a multi-year effort to bring AML procedures and practices into line with Egmont standards'
    case 'Ecuador':
      return 'Ecuador is a major drug transit country. A dollarized, cash-based economy and geographic location between two major drug-producing countries make Ecuador highly vulnerable to money laundering and narcotrafficking. Approximately 61 percent of adults have bank accounts. Money laundering occurs through trade, commercial activity, and cash couriers. Bulk cash smuggling and structuring are common problems. Bureaucratic stove-piping, corruption, and lack of specialized AML expertise in the judiciary, law enforcement, and banking regulatory agencies hinder the government’s efforts to improve AML enforcement and prosecutions. Pursuing public corruption is a top priority for President Moreno’s government. Authorities have investigated and prosecuted high-level government officials for bribery, embezzlement, illicit enrichment, and organized crime. The Attorney General’s Office (AGO) continues to investigate allegations of financial crimes related to state-owned oil company PetroEcuador and the Brazilian construction company Odebrecht.'
    case 'Egypt':
      return 'Egypt is not considered a regional financial center or a major hub for money laundering. The Government of Egypt has shown increased willingness to tackle money laundering, but Egypt remains vulnerable by virtue of its large informal, cash-based economy. There are estimates that as much as two-thirds of the population does not have bank accounts, and the informal economy accounts for approximately 40 percent of the GDP. Consequently, extensive use of cash is common. The central bank and the Federation of Egyptian Banks aim to promote financial inclusion by incentivizing individuals and small businesses to enter the formal financial sector. Investigations of public figures and entities have resulted in the arrests of Alexandria’s deputy governor and the secretary general of Suez on several corruption charges, and the investigation into five members of parliament alleged to have sold hajj visas. The government should continue to build its capacity to successfully investigate and prosecute money laundering offenses. In particular, Egypt needs to build the capacity of the judicial system related to money laundering. Egypt also should work to more effectively manage all aspects of its asset forfeiture regime, including identification, seizure, and forfeiture.'
    case 'El Salvador':
      return 'El Salvador made significant progress in combating money laundering (ML) recently, primarily due to the efforts of the Attorney General’s (AG) office, which increased its capacity to investigate and prosecute money laundering offenses and to seize and forfeit related assets. The AG’s office added 15 prosecutors to money laundering and asset forfeiture units and obtained its first money laundering convictions against MS-13 gang members and associates. New legislation granted the FIU increased independence in accordance with international norms. El Salvador was reinstated in the Egmont group, providing Salvadoran institutions increased access to financial intelligence from foreign partners'
    case 'Equatorial Guinea':
      return 'Exploitation of oil and gas deposits, beginning in the 1990s, has driven economic growth in Equatorial Guinea. Forestry and farming are minor components of GDP. Although preindependence Equatorial Guinea counted on cocoa production for hard currency earnings, the neglect of the rural economy since independence has diminished the potential for agriculture-led growth. Subsistence farming is the dominant form of livelihood. Declining revenue from hydrocarbon production, high levels of infrastructure expenditures, lack of economic diversification, and corruption have pushed the economy into decline in recent years and led to limited improvements in the general population’s living conditions. Foreign assistance programs by the World Bank and the IMF have been cut since 1993 because of corruption and mismanagement, and as a middle income country Equatorial Guinea is now ineligible for most donor assistance. The government has been widely criticized for its lack of transparency and misuse of oil revenues and has attempted to address this issue by working towards compliance with the Extractive Industries Transparency Initiative.'
    case 'Eritrea':
      return 'Since formal independence from Ethiopia in 1993, Eritrea has faced many economic problems, including lack of financial resources and chronic drought, which have been exacerbated by restrictive economic policies. Eritrea has a command economy under the control of the sole political party, the People\'s Front for Democracy and Justice. Like the economies of many African nations, a large share of the population - nearly 80% in Eritrea - is engaged in subsistence agriculture, but the sector only produces a small share of the country\'s total output. While reliable statistics on food security are difficult to obtain, erratic rainfall and the percentage of the labour force tied up in national service continue to interfere with agricultural production and economic development. Eritrea\'s harvests generally cannot meet the food needs of the country without supplemental grain purchases. Copper, potash, and gold production are likely to drive economic growth and government revenue over the next few years, but military spending will continue to compete with development and investment plans. Eritrea\'s economic future will depend on market reform, international sanctions, global food prices, and success at addressing social problems such as refugee emigration.'
    case 'Estonia':
      return 'Estonia, a member of the EU since 2004 and the euro zone since 2011, has a modern market-based economy and one of the higher per capita income levels in Central Europe and the Baltic region. Estonia has a highly developed and transparent banking sector, and its rule of law is recognized as established and mature. Transnational and organized crime groups are attracted to the country for its location between Eastern and Western Europe. Suspicious transaction reports (STRs) show illicit funds from internet crime flowing into Estonia. A review of court decisions related to money laundering show that the most common predicate offenses for money laundering are fraud, computer fraud, and tax-related offenses (both domestic and foreign). Estonia\'s successive governments have pursued a free market, pro-business economic agenda, and sound fiscal policies that have resulted in balanced budgets and low public debt. Estonia is challenged by a shortage of labor, both skilled and unskilled, although the government has amended its immigration law to allow easier hiring of highly qualified foreign workers.'
    case 'Ethiopia':
      return 'Ethiopia has grown at a rate between 8% and 11% annually for more than a decade and the country is the fifth-fastest growing economy among the 188 IMF member countries. This growth has been driven by sustained progress in the agricultural and service sectors. Ethiopia has the lowest level of income-inequality in Africa and one of the lowest in the world, with a Gini coefficient comparable to that of the Scandinavian countries. Yet despite progress toward eliminating extreme poverty, Ethiopia remains one of the poorest countries in the world, due both to rapid population growth and a low starting base. Changes in rainfall associated with world-wide weather patterns resulted in the worst drought in thirty years in 2015/2016, creating food insecurity for millions of Ethiopians. Almost 80% of Ethiopia’s population is still employed in the agricultural sector, but services have surpassed agriculture as the principal source of GDP. Under Ethiopia\'s constitution, the state owns all land and provides long-term leases to tenants. '
    case 'Falkland Islands (Islas Malvinas)':
      return 'Although first sighted by an English navigator in 1592, the first landing (English) did not occur until almost a century later in 1690, and the first settlement (French) was not established until 1764. The colony was turned over to Spain two years later and the islands have since been the subject of a territorial dispute, first between Britain and Spain, then between Britain and Argentina. The UK asserted its claim to the islands by establishing a naval garrison there in 1833. Argentina invaded the islands on 2 April 1982. The British responded with an expeditionary force that landed seven weeks later and after fierce fighting forced an Argentine surrender on 14 June 1982. With hostilities ended and Argentine forces withdrawn, UK administration resumed. In response to renewed calls from Argentina for Britain to relinquish control of the islands, a referendum was held in March 2013, which resulted in 99.8% of the population voting to remain a part of the UK. The economy was formerly based on agriculture, mainly sheep farming, but fishing and tourism currently comprise the bulk of economic activity.'
    case 'Faroe Islands':
      return 'The population of the Faroe Islands is largely descended from Viking settlers who arrived in the 9th century. The islands have been connected politically to Denmark since the 14th century. A high degree of self-government was granted the Faroese in 1948, who have autonomy over most internal affairs while Denmark is responsible for justice, defense, and foreign affairs. The Faroe Islands are not part of the European Union. Aided by an annual subsidy from Denmark, which amounts to about 11% of Faroese GDP , Faroese have a standard of living equal to that of Denmark. The Faroe Islands have bilateral free trade agreements with the EU, Iceland, Norway, Switzerland, and Turkey. Increasing public infrastructure investments are likely to lead to continued growth in the short term, and the Faroese economy is becoming somewhat more diversified. Growing industries include financial services, petroleum-related businesses, shipping, maritime manufacturing services, civil aviation, IT, telecommunications, and tourism.'
    case 'Fiji':
      return 'The Republic of Fiji is a small island state with a population of less than one million. It has significant natural resources and is among the most developed of the Pacific island nations. It is not a regional financial center but serves as a regional hub for transportation and shipping for other Pacific island nations. Currently, there are no operating casinos. Fiji’s geographical location makes it a potential staging point for criminal activities in Australia and New Zealand. Cross-border criminal gangs involving individuals from Asian countries are alleged to operate in Fiji. To encourage investment and create economic opportunities in Fiji’s rural Northern and Maritime Island regions, the government declared certain areas as tax free regions. Benefits include a multi-year corporate tax holiday and import duty exemption on raw materials, machinery, and equipment for initial setup. There is also a tax free region in the North East of Viti Levu targeting agriculture, dairy, and other new investments.'
    case 'Finland':
      return 'Finland has a highly industrialized, largely free-market economy with per capita GDP almost as high as that of Austria, Belgium, the Netherlands, or Sweden. Trade is important, with exports accounting for over one-third of GDP in recent years. Finland is not a regional center for money laundering, financial crime, or illegal commerce. The major sources of illegal proceeds in Finland relate to financial crimes, and the majority of investigated suspicious financial activities have an international dimension. The number of organized criminal groups has grown slightly in the past few years, as has the number of their members (totaling approximately 1,000). Illicit funds are normally laundered through currency exchanges and gaming establishments. According to the National Bureau of Investigation, the use of virtual currency, such as bitcoin, as well as phone transactions have become more common in money laundering cases. The report found that the key risk items of money laundering and terrorist financing in Finland are associated with real estate investments, transport of cash, front companies, online services, online shadow financing markets, and customer fund accounts.'
    case 'France':
      return 'Due to its sizeable economy, political stability, sophisticated financial system and commercial relations, especially with Francophone countries, France is a venue for money laundering. Public corruption, narcotics and human trafficking, smuggling, and other crimes associated with organized crime are sources of illicit proceeds. France can designate portions of its customs territory as free trade zones and free warehouses in return for employment commitments. The French Customs Service administers these zones. France has an informal economic sector, and underground remittance and value transfer systems such as hawala are used by immigrant populations accustomed to such systems in their home countries. There is little information on the scale of such activity. Casinos are regulated. The use of virtual money is growing in France through online gaming and social networks. Sport teams have become another significant source of money laundering.'
    case 'French Guiana':
      return 'In the early 21st century, five French overseas entities - French Guiana, Guadeloupe, Martinique, Mayotte, and Reunion - became French regions and were made part of France proper. French Guiana is located in Northern South America, bordering the North Atlantic Ocean, between Brazil and Suriname. With a tropical climate, it is hot, humid and has little seasonal temperature variation. The land is made up of low-lying coastal plains rising to hills and small mountains. The main natural resources are  gold deposits, petroleum, kaolin, niobium, tantalum, clay.'
    case 'French Polynesia':
      return 'The French annexed various Polynesian island groups during the 19th century. In 1966, the French Government began testing nuclear weapons on the uninhabited Mururoa Atoll; following mounting opposition, the tests were moved underground in 1975. In September 1995, France stirred up widespread protests by resuming nuclear testing after a three-year moratorium. The tests were halted in January 1996. In recent years, French Polynesia\'s autonomy has been considerably expanded. Since 1962, case ce stationed military personnel in the region, French Polynesia has changed from a subsistence agricultural economy to one in which a high proportion of the work force is either employed by the military or supports the tourist industry. With the halt of French nuclear testing in 1996, the military contribution to the economy fell sharply.'
    case 'French Southern Territories':
      return 'In February 2007, the Iles Eparses became an integral part of the French Southern and Antarctic Lands (TAAF). The Southern Lands are now divided into five administrative districts, two of which are archipelagos, Iles Crozet and Iles Kerguelen; the third is a district composed of two volcanic islands, Ile Saint-Paul and Ile Amsterdam; the fourth, Iles Eparses, consists of five scattered tropical islands around Madagascar. They contain no permanent inhabitants and are visited only by researchers studying the native fauna, scientists at the various scientific stations, fishermen, and military personnel. The fifth district is the Antarctic portion, which consists of "Adelie Land," a thin slice of the Antarctic continent discovered and claimed by the French in 1840. Economic activity is limited to servicing meteorological and geophysical research stations, military bases, and French and other fishing fleets. The fish catches landed on Iles Kerguelen by foreign ships are exported to France and Reunion.'
    case 'Gabon':
      return 'Gabon is not a regional financial center. Gabon suffers from porous borders and smuggling, facilitated by organized criminal groups. Despite fiscal management reform efforts, systemic corruption persists. The embezzlement of state funds, including by politically exposed persons (PEPs), reportedly gives rise to money laundering. There is a large expatriate community in Gabon engaged in the oil and gas sector, the timber industry, construction, and general trade. Money or value transfer services, such as hawala, are often used by those expatriates, particularly the large Lebanese community, to avoid strict controls on the repatriation of corporate profits. The Bank of Central African States (BEAC), based in Cameroon, is a regional central bank that serves six Central African countries and supervises Gabon’s banking system. BEAC’s Economic Intervention Service harmonizes the regulation of currency exchanges in the member states of the Central African Economic and Monetary Community.'
    case 'Gambia':
      return 'The Gambia is not a regional financial center, although it is a regional re-export center. The Gambia derives most of its GDP from agriculture, tourism, remittances, and the re-export trade, with most transactions conducted in cash. Goods and capital are freely and legally traded in The Gambia, and, as is the case in other re-export centers, smuggling of goods occurs. Although The Gambia has limited capacity to monitor its porous borders, customs officials cooperate with counterparts in Senegal to combat smuggling along their common border. A lack of resources hinders law enforcement’s ability to combat smuggling more effectively. It is unclear to what extent money laundering is related to narcotics, but seizures of large amounts of cocaine and marijuana two years ago heightened concerns regarding links to international drug trafficking. Concerns are further heightened by the problems of persistently weak bank controls and supervision, the dominance of cash transactions, a poor know-your-customer compliance culture, and significant inflows of tourists.'
    case 'Georgia':
      return 'Georgia is located along a well-established trafficking corridor and faces international money laundering threats. Georgia’s ease of doing business attracts investment, but also facilitates entry of ill-gotten funds into the financial system. Much illegal income in Georgia derives from bank fraud and cybercrime. Virtual currency is unregulated in Georgia, though the Georgian government is beginning to focus on how to address these currencies. Additionally, there is not sufficient oversight of the gaming sector. In October 2019, Georgia adopted new legislation and an assessment tool aimed at creating effective AML/CFT monitoring and enforcement mechanisms. The Russian-occupied territories of South Ossetia and Abkhazia fall outside the control of Georgian authorities.'
    case 'Germany':
      return 'While not an offshore financial center, Germany is one of the largest financial centers in Europe. Germany is a member of the Eurozone, thus making it attractive to organized criminals and tax evaders. Many indicators suggest Germany is susceptible to money laundering and terrorist financing because of its large economy, advanced financial institutions, and strong international linkages. Although not a major drug producing country, Germany continues to be a consumer and a major transit hub for narcotics. Germany allows the use of shell companies, trusts, holdings, and foundations that can help obscure the source of assets and cash. Terrorists have carried out terrorist acts in Germany and in other nations after being based in Germany. Germany is estimated to have a large informal financial sector. Informal value transfer systems, such as hawala, are reportedly used by immigrant populations accustomed to such systems in their home countries and among refugees paying for their travel to Europe/Germany. There is little official data on the scale of this activity.'
    case 'Ghana':
      return 'Ghana continues to make progress strengthening its AML/CFT laws in line with international standards and is working to implement its AML/CFT regime across all sectors and institutions. Ghana is continuing to consolidate its banking and financial sectors, with new capital requirements reducing the number of banks operating in the country. This consolidation, along with an incremental but positive trajectory of improved banking supervision, should aid authorities in prioritizing the allocation of resources. Recently, Ghana developed a National AML/CFT Policy and Action Plan to address all the strategic deficiencies identified in its national risk assessment (NRA) and by international experts. The NRA was first published in April 2016 and reviewed in 2018. Ghana has also conducted a nationwide rollout of AML/CFT sensitization programs for NGOs to raise awareness of AML/CFT issues. Ghana is developing a nationwide capacity-building workshop on AML/CFT and the proliferation of weapons of mass destruction for law enforcement agencies in several regions of the country.'
    case 'Gibraltar':
      return 'Gibraltar, an overseas territory of the UK, is part of the EU. A November 2006 referendum resulted in constitutional reforms transferring powers exercised by the UK government to Gibraltar. Gibraltar has an international financial center, which is small internationally but large in comparison to its domestic economy. The financial services sector has strong ties to London, the Crown Dependencies, and other financial centers. The economy of Gibraltar also generates revenue from tourism, the service industry, and cruises. Bordering Spain and near the north coast of Africa, Gibraltar is adjacent to known drug trafficking and human smuggling routes, but the territory is heavily policed on land and at sea due to the risk of these activities occurring within its borders or territorial waters. Gibraltar is exposed to money launderers located in drug producing centers in Morocco and drug consumption and distribution networks in Spain. Tobacco smuggling over the Gibraltar frontier has been a problem.'
    case 'Greece':
      return 'Greece is a regional financial center for the Balkans, as well as a bridge between Europe and the Middle East. Official corruption, the presence of organized crime, and a large informal economy make the country vulnerable to money laundering and terrorist financing. Greek law enforcement proceedings show that Greece is vulnerable to narcotics trafficking, trafficking in persons, illegal migration, prostitution, smuggling of cigarettes and other contraband, serious fraud or theft, illicit gaming activities, and large scale tax evasion. Evidence suggests financial crimes – especially tax related – have increased in recent years. Criminal organizations, some with links to terrorist groups, are trying to use the Greek banking system to launder illicit proceeds. Criminally-derived proceeds are most commonly invested in real estate, the lottery, and the stock market. Criminal organizations from southeastern Europe, the Balkans, Georgia, and Russia are responsible for a large percentage of the crime that generates illicit funds.'
    case 'Greenland':
      return 'Greenland, the world\'s largest island, is about 80% ice-capped. Vikings reached the island in the 10th century from Iceland; Danish colonization began in the 18th century, and Greenland became an integral part of the Danish Realm in 1953. It joined the European Community (now the EU) with Denmark in 1973 but withdrew in 1985 over a dispute centered on stringent fishing quotas. Greenland remains a member of the Overseas Countries and Territories Association of the EU. Greenland was granted self-government in 1979 by the Danish parliament; the law went into effect the following year. Greenland voted in favor of increased self-rule in November 2008 and acquired greater responsibility for internal affairs case Act on Greenland Self-Government was signed into law in June 2009. Denmark, however, continues to exercise control over several policy areas on behalf of Greenland, including foreign affairs, security, and financial policy in consultation with Greenland\'s Self-Rule Government.'
    case 'Grenada':
      return 'Grenada’s geographic location in the Caribbean places it in close proximity to drug shipment routes from Venezuela to the United States and Europe. As a narcotics transfer point, money laundering in Grenada is principally connected to smuggling and narcotics trafficking by local organized crime rings. Illegal proceeds are laundered through a variety of businesses, as well as through the purchase of real estate, boats, jewelry, and cars. Grenada is not a regional financial center. The Eastern Caribbean Central Bank is the supervisory authority for Grenadian commercial banks, and the Grenada Authority for the Regulation of Financial Institutions is responsible for supervising DNFBPs. Even though IBCs and offshore banking and trust companies are allowed to conduct business in Grenada, none are currently operating. Grenada has no casinos or internet gaming sites. The International Companies Act regulates the establishment and management of IBCs in Grenada and requires registered agents to maintain records of the names and addresses of company directors and beneficial owners of all shares.'
    case 'Guadeloupe':
      return 'In the early 21st century, five French overseas entities - French Guiana, Guadeloupe, Martinique, Mayotte, and Reunion - became French regions and were made part of France proper. Guadeloupe is located in the Caribbean. It consists of islands between the Caribbean Sea and the North Atlantic Ocean, southeast of Puerto Rico. The climate is subtropical and tempered by trade winds with moderately high humidity. Because of the rainy season (June to October), it is vulnerable to devastating cyclones (hurricanes) every eight years on average. Basse-Terre is volcanic in origin with interior mountains, while Grande-Terre is low limestone formation. Most of the seven other islands are volcanic in origin.'
    case 'Guam':
      return 'Spain ceded Guam to the US in 1898. Captured by the Japanese in 1941, it was retaken by the US three years later. The military installations on the island are some of the most strategically important US bases in the Pacific. US national defence spending is the main driver of Guam’s economy, followed by tourism and other services. Total federal spending (defence and non-defence) contributes greatly to GDP. Service exports, mainly spending by foreign tourists while on Guam contribute some to GDP. Despite slow growth, Guam’s economy has been stable over the last decade. National defence spending cushions the island’s economy against fluctuations in tourism. Guam serves as a forward US base for the Western Pacific and is home to thousands of American military personnel. Federal grants also contriute significantly to Guam’s total revenues for the fiscal year.'
    case 'Guatemala':
      return 'Guatemala remains a key transit route for narcotics to the United States and cash returning to South America. The government has challenges combating corruption, money laundering and financial crimes related to narcotics trafficking. With the support of the International Commission Against Impunity in Guatemala (CICIG), Guatemala improved its ability to investigate and prosecute corruption, money laundering, and other financial crimes. Though the Public Ministry (MP) has improved coordination between prosecutors and law enforcement agencies to conduct financial investigations and consider money laundering charges case stigating predicate offenses such as extortion, corruption, and trafficking investigations, more progress is needed. CICIG’s departure from Guatemala recently puts anticorruption gains at risk, given the weakness of Guatemalan institutions, and the influence narco-traffickers have over elected officials.'
    case 'Guernsey, C.I.':
      return 'The Bailiwick of Guernsey (the Bailiwick) encompasses a number of the Channel Islands (Guernsey, Alderney, Sark, and Herm). As a Crown Dependency of the UK, it relies on the UK for its defense and international relations. While Alderney and Sark have their own separate parliaments and civil law systems, Guernsey’s parliament legislates in matters of criminal justice for all of the islands in the Bailiwick. Guernsey is a financial center, and as such, there is a risk that proceeds of crime will be invested in or pass through the Bailiwick. In terms of volume, most criminal proceeds arise from foreign predicate offenses; domestic criminal activity, such as drug trafficking, yields the highest overall number of money laundering cases. The principal area of concern or vulnerability remains the risk of abuse of the financial sector to launder the proceeds of overseas criminal activity, primarily financial crimes.'
    case 'Guinea':
      return 'The lack of record-keeping, weak law enforcement, corruption, and the informal, cash-based economy in Guinea provide a fertile environment for money laundering and its predicate offenses. The situation is made more complex by the Economic Community of West African States’ principle of free movement of persons and goods. The country’s openness to the sea and the existence of a large seaport provide a major economic opportunity but also constitute risks that should be addressed by the authorities. The number of unauthorized currency dealers that resist government measures against unlicensed operators continues to grow. Guinea has an extensive black market for smuggled goods, which includes illegal drugs trafficked from Guinea-Bissau and Sierra Leone. The Port of Conakry is also used as a way-point between drug suppliers in South America and consumers in Europe. Local officials believe the sale of counterfeit U.S. currency in Guinea involves money laundering. It is estimated that 80 percent of the pharmaceutical drugs sold in the region are counterfeit, although the government has recently undertaken efforts to address this out of concern for public safety.'
    case 'Guinea-Bissau':
      return 'Chronic political instability and government dysfunction have meant the Bissau-Guinean authorities have made little headway in fighting narcotics trafficking, money laundering, or other crimes. However, recently, the resumption of direct budget support by multilateral institutions has led to greater consistency in payment of salaries for the law enforcement and judicial systems. The Bijagos Archipelago and the extensive riverine geography of the coastline continue to make the country a transshipment center for drugs. Although the government has taken steps to exert more oversight of military spending, civilian control is still lacking. Narcotics traffickers continue to take advantage of Guinea-Bissau’s remoteness, poverty, unemployment, political malaise, and lack of effective customs and law enforcement to route drugs through the country to European markets. Drug traffickers have been able to infiltrate state structures and often operate with impunity. '
    case 'Guyana':
      return 'Guyana is a transit country for South American cocaine destined for Europe, West Africa, the United States, Canada, and the Caribbean. Cocaine is concealed in legitimate commodities and smuggled via commercial maritime vessels, air transport, human couriers, or the postal services. Guyana’s most recent National Risk Assessment concluded it has a medium-to-high money laundering risk and a medium terrorist financing threat. Unregulated currency exchange houses and dealers in precious metals and stones pose a risk to Guyana’s AML/CFT system. Other sectoral vulnerabilities include the banking industry and unregulated attorneys-at-law, real estate agents, used car dealers, and charities. Guyana has made significant progress on the AML/CFT front, but more investigations and successful prosecutions are needed in the future. '
    case 'Haiti':
      return 'Haitian gangs are engaged in international drug trafficking and other criminal and fraudulent activity. While Haiti itself is not a major financial center, regional narcotics and money laundering enterprises utilize Haitian couriers, primarily via maritime routes. Much of the drug trafficking in Haiti, and related money laundering, is connected to the United States. Important legislation was adopted over the past several years, in particular anticorruption and AML laws, but the weakness of the Haitian judicial system, impunity, and a lack of political will leave the country vulnerable to corruption and money laundering.'
    case 'Heard and McDonald Islands':
      return 'The UK transferred these uninhabited, barren, sub-Antarctic islands to Australia in 1947. Populated by large numbers of seal and bird species, the islands have been designated a nature preserve. The islands have no indigenous economic activity, but the Australian Government allows limited fishing in the surrounding waters. Visits to Heard Island typically focus on terrestrial and marine research and infrequent private expeditions.'
    case 'Holy See (Vatican City)':
      return 'The Holy See is an atypical government, being simultaneously the supreme government body of the Catholic Church and a sovereign entity under international law. It operates from Vatican City State, a 0.44 square kilometer (0.17 square mile) territory created to provide a territorial basis for the Holy See. The Institute for Works of Religion (IOR) performs functions similar to those of a bank, so the IOR is commonly referred to as the “Vatican Bank.” However, unlike a normal bank, the IOR does not loan money, its accounts do not collect interest, and the IOR does not make a profit for shareholders or owners. Rather, the IOR acts as a clearinghouse for Vatican accounts, moving funds from Catholic Church sources to Catholic Church destinations. Approximately 15,000 customers have accounts in the IOR; most of the clients are religious orders, Vatican employees, and clergy, including bishops. The population of Vatican City, around 800, consists almost entirely of clergy (Holy See officials), the Swiss Guard, and members of religious orders.'
    case 'Honduras':
      return 'Money laundering in Honduras stems primarily from narcotics trafficking by organized criminal groups and the illicit proceeds of public corruption. Honduras is not a regional or offshore financial center. Honduras has recently established an AML strategy and focused on high-priority offenses, such as money laundering linked to organized crime. Lack of institutional coordination limits the operation of the AML regulatory system, and the Tax Administration Service was the only Honduran agency with an active AML unit that meets Honduran legal requirements. The general lack of investigative capacity regarding complex financial transactions contributes to a favorable money laundering climate. However, Honduras has been able to achieve some results in money laundering and corruption cases and has sought international cooperation. '
    case 'Hong Kong':
      return 'Hong Kong, a Special Administrative Region (SAR) of the People’s Republic of China, is an international financial and trading center. The world’s sixth-largest banking center in terms of external transactions and the fourth-largest foreign exchange trading center, Hong Kong does not differentiate between offshore and onshore entities for licensing and supervisory purposes and has its own U.S. dollar interbank clearing system for settling transactions.'
    case 'Hungary':
      return 'Hungary is not considered a major financial center; however, its EU membership and location make it a link between the former Soviet Union and Western Europe. The country’s primarily cash-based economy and well-developed financial services industry make it attractive to foreign criminal organizations. Law enforcement has observed an increase in organized crime groups using Hungary and the region as a base of operation for cyber-related fraud, including social engineering fraud, and for laundering criminal proceeds through shell companies and the banking system. Hungarian officials believe cash transactions, offshore companies, and front companies are the largest money laundering and terrorism financing risks. The use of cash and false information regarding the identity of the accountholder hinders transparency, making it difficult to track funds derived from criminal activity.'
    case 'Iceland':
      return 'Iceland is not considered a regional financial center. Criminal proceeds tend to derive from domestic organizations with some linkages to foreign groups. Money laundering in Iceland is related primarily to tax evasion, narcotics trafficking, fraud and other economic crimes, and underground casinos. Over the years, very few cases have been registered as pure money laundering cases, mainly due to the unavailability of statistical data. Financial crimes concerning market manipulation have been prosecuted, but the scale of money laundering involved in such activities is not clear. The Economic Crime Unit, which transferred from the National Commissioner of the Icelandic Police (NCIP) and merged with the Office of the Special Prosecutor (OSP), continues to investigate criminal actions in connection with the 2008 collapse of Iceland’s financial system.'
    case 'India':
      return 'Indian Prime Minister Narendra Modi has prioritized curtailing illicit financial activity as part of his administration’s efforts to formalize and digitize India’s financial system to reduce corruption and increase the tax base. Nonetheless, India faces various money laundering vulnerabilities, including informal financing networks that largely serve illiterate, rural citizens; complex onshore and offshore corporate structures; and enforcement capacity constraints.'
    case 'Indonesia':
      return 'Indonesia remains vulnerable to money laundering due to gaps in financial system legislation and regulation, a cash-based economy, weak rule of law, and partially ineffective law enforcement institutions. Risks also stem from corruption and taxation cases, followed by drug trafficking, and to a lesser extent illegal logging, wildlife trafficking, theft, bank fraud, embezzlement, credit card fraud, and the sale of counterfeit goods. Proceeds from these predicate crimes are laundered through the banking, capital markets, real estate, and motor vehicle sectors. Proceeds are also laundered offshore in regional jurisdictions and then repatriated to Indonesia as needed. Indonesia is making progress to counter vulnerabilities as authorities continue to release regulations geared towards a risk-based approach and there is, generally, a high level of technical compliance with AML standards. Areas for improvement remain analytical training for law enforcement, raising judicial authorities’ awareness of relevant offenses, increasing technical capacity to conduct financial investigations as a routine component of criminal cases, and more education for financial services sector personnel. '
    case 'Iran':
      return 'Iran has a large underground economy, spurred in part by uneven taxation, widespread Islamic Revolutionary Guard Corps (IRGC) corruption and smuggling, sanctions evasion, currency exchange controls, and a large Iranian expatriate community. Pervasive corruption continues within Iran’s ruling and religious elite, the IRGC, government ministries, and governmentcontrolled business enterprises. Iran remains a major transit route for opiates smuggled from Afghanistan through Pakistan to the Persian Gulf, Turkey, Russia, and Europe. Most drugs are smuggled into Iran across its land borders with Afghanistan and Pakistan, although maritime smuggling has increased as traffickers seek to avoid Iranian border interdiction efforts. Iran’s minister of interior estimated the combined value of narcotics trafficking and sales in Iran at $6 billion annually.'
    case 'Iraq':
      return 'Iraq’s economy is primarily cash-based and its financial sector is severely underdeveloped. Iraq has about 2,000 financial institutions, most of which are money exchange houses. Although Iraqi law prohibits these entities from transferring funds outside of Iraq, some probably conduct cross-border transfers. Iraqi law enforcement and bank supervisors have made progress in their capabilities to detect and halt illicit financial transactions mostly due to a 2015 AML law. However, the illicit use of some currency exchange networks and the weak compliance capabilities of the banking sector leave the Iraqi financial sector susceptible to abuse. Smuggling is endemic, often involving consumer goods. Bulk cash smuggling is likely common, in part because Iraqi law only allows for the seizure of funds at points of entry, such as border crossings and airports. Narcotics trafficking occurs on a small scale. Corruption is pervasive at all government levels and is widely regarded as a cost of doing business in Iraq. Iraqi authorities have been making strides in combatting money laundering, but almost all of the progress is connected to terrorist financing. Investigations into financial gains from political corruption or other actors remain virtually nonexistent.'
    case 'Ireland':
      return 'Ireland continues to be a significant European financial hub, with a number of international banks and fund administration firms located in Dublin’s International Financial Services Center. These institutions are monitored and regulated by the Central Bank of Ireland (CBI). The primary sources of funds laundered in Ireland are cigarette smuggling, drug trafficking, diversion of subsidized fuel, domestic tax violations, prostitution, and welfare fraud. Irish authorities estimate up to 80 percent of suspicious transaction reports (STRs) that can be linked to predicate crimes involve funds derived from domestic tax violations and social welfare fraud. While money laundering occurs via financial institutions, illicit funds also are laundered through schemes involving remittance companies, lawyers, accountants, used car dealerships, the purchase of high-value goods for cash, transferring funds from overseas through Irish credit institutions, filtering funds via complex company structures, and by basing foreign or domestic real property sales in Ireland. A number of cash seizures have occurred at Dublin International Airport.'
    case 'Isle of Man':
      return 'Isle of Man (IOM) is a British crown dependency, and while it has its own parliament, government, and laws, the UK remains responsible for its defense and international representation. Offshore banking, manufacturing, and tourism are key sectors of the economy, and the government has actively encouraged the diversification of its economy, offering incentives to high-technology companies and financial institutions that locate on the island. Consequently, it now hosts a wide range of sectors including aviation and maritime services, clean-tech and bio-tech, creative industries, e-business and e-gaming, high-tech manufacturing and tourism. Its large and sophisticated financial center is potentially vulnerable to money laundering. Most of the illicit funds in the IOM are from fraud schemes and narcotics trafficking in other jurisdictions, including the UK. Predicate offenses to charge money laundering are minimal within the jurisdiction; however, there is concern over value-added tax crimes and the growing risk of cybercrime in its various forms, including identity theft and internet abuse.'
    case 'Israel':
      return 'Israel is not regarded as a regional financial center. It primarily conducts financial activity with the markets of the United States and Europe, and, to an increasing extent, with Asia. Criminal groups in Israel, either home-grown or with ties to the former Soviet Union, United States, or EU, often utilize a maze of offshore shell companies and bearer shares to obscure ownership. Israel’s illicit drug trade is regionally focused, with Israel being more a market destination for narcotics than a transit country. The majority of money laundered originates from criminal activities abroad, including “carousel fraud,” which takes advantage of international value-added tax loopholes. Proceeds from domestic criminal activity also continue to contribute to money laundering activity. Electronic goods; liquor; cigarettes; cell phones; and pharmaceuticals, especially Viagra and Cialis, have all been seized in recent smuggling operations. Officials continue to be concerned about money laundering in the diamond industry, illegal online gaming rings, retail businesses suspected as money laundering enterprises, and public corruption.'
    case 'Italy':
      return 'Italy’s economy is the ninth largest in the world and the third-largest in the Eurozone. Italy has a sophisticated AML regime and legal framework, but a continued risk of money laundering stems from activities of organized crime and the large, black market economy. According to the Italian National Statistics Institute, the black market economy accounts for 12.1 percent of GDP, or approximately $235 billion (€211 billion). Tax crimes also represent a significant risk and have been identified as accounting for 75 percent of all proceeds-generating crime in Italy. While on the rise, CDD and suspicious transaction reporting remain weak among non-financial sectors, and regulations are inconsistent. Recently, the government published  Regulation n. 44 to implement new provisions on AML/CFT organization, procedures, internal controls, and CDD. The new regulations align with EU efforts and require online entities to adopt controls and procedures.'
    case 'Jamaica':
      return 'Money laundering in Jamaica is largely perpetrated by organized criminal groups, including some with links to powerful Jamaicans. Recently, the country recorded a large number of financial crimes related to advance fee fraud (lottery scams), corruption, counterfeit goods, small arms trafficking, and cybercrime. The Government of Jamaica has enforced the asset forfeiture provisions of the Proceeds of Crime Act (POCA) with moderate success, but the law still is not being implemented to its fullest potential due to difficulties prosecuting and achieving convictions in financial crime cases. Law enforcement, prosecutors, and the judiciary lack sufficient resources and training to investigate and prosecute financial crimes efficiently and effectively. '
    case 'Japan':
      return 'Japan is a regional financial center but not an offshore financial center. The country continues to face substantial risk of money laundering by organized crime, including Japanese organized crime groups (the Yakuza), Mexican drug trafficking organizations, and other domestic and international criminal elements. In the past several years, there has been an increase in financial crimes by citizens of West African countries, such as Nigeria and Ghana, who reside in Japan. The major sources of laundered funds include drug trafficking, fraud, loan sharking (illegal money lending), remittance frauds, the black market economy, prostitution, and illicit gambling. Bulk cash smuggling also is of concern. There is not a significant black market for smuggled goods, and the use of alternative remittance systems is believed to be limited. Japan has one free trade zone, the Okinawa Special Free Trade Zone, established in Naha to promote industry and trade in Okinawa. The zone is regulated by the Department of Okinawa Affairs in the Cabinet Office. Japan also has two free ports, Nagasaki and Niigata. Customs authorities allow the bonding of warehousing and processing facilities adjacent to these ports on a case-by-case basis.'
    case 'Jersey':
      return 'Jersey, the largest of the Channel Islands, is an international financial center offering a sophisticated array of offshore services. Jersey is a self-governing British Crown Dependency with its own parliament, government, legal system, and jurisprudence. The UK is responsible for Jersey’s defense and international representation, while the island has autonomy in relation to its domestic affairs, including taxation and the regulation of its financial services sector. The financial services industry is a key sector, with banking, investment services, and trust and company services accounting for approximately half of Jersey’s total economic activity. As a substantial proportion of customer relationships are with nonresidents, adherence to know-your-customer rules is an area of focus for efforts to limit illicit money from foreign criminal activity. Jersey authorities continue to indicate concern regarding the incidence of domestic drug-related crimes. The customs and law enforcement authorities devote considerable resources to countering these crimes. A large proportion of suspicious activity reporting is tax-related.'
    case 'Jordan':
      return 'The Hashemite Kingdom of Jordan is not a regional or offshore financial center, it has a well- developed financial sector with significant banking relationships in the Middle East. Incidents of reported money laundering are rare, but anecdotal reports indicate Jordan’s real estate sector has been used to launder illicit funds. Jordan’s long and remote desert borders with Iraq, Israel, Saudi Arabia, Syria, and the West Bank make it susceptible to the smuggling of bulk cash, gold, fuel, narcotics, cigarettes, counterfeit goods, and other contraband. Smuggling endeavors tend to be small scale, and there is no discernible connection between black market goods and large scale crime, such as terrorism. Black market cigarettes are widely available, and there is little government effort to curb sales. Jordan Customs sometimes interdicts drivers smuggling cheaper gasoline from Saudi Arabia in false tanks. Border security is becoming more stringent, however, which may have an impact on smuggling. '
    case 'Kazakhstan':
      return 'As a transit country for opiates and cannabis, and one susceptible to TBML, Kazakhstan remains vulnerable to financial crimes. Corruption is an enabler of money laundering. Recently, Kazakhstan completed its national risk assessment (NRA) to evaluate the exposure of its financial sector to money laundering. A follow-up action plan to the assessment makes proposals to bring Kazakhstan more into compliance with international AML standards. Recent changes to the Committee for Financial Monitoring of the Ministry of Finance, the FIU, transformed the agency from an administrative department disseminating investigative leads to law enforcement to an agency that is both a financial intelligence gatherer and investigative body. The change may create difficulties retaining qualified staff and maintaining operational independence. Conversely, it could strengthen cooperation between analytical and investigative staff.'
    case 'Kenya':
      return 'Kenya remains vulnerable to money laundering, financial fraud, and terrorism financing. It is the financial hub of East Africa and is at the forefront of mobile banking. Money laundering occurs in the formal and informal sectors, deriving from domestic and foreign criminal operations. Criminal activities include transnational organized crime, cybercrime, corruption, smuggling, trade invoice manipulation, illicit trade in drugs and counterfeit goods, trade in illegal timber and charcoal, and wildlife trafficking.'
    case 'Kiribati':
      return 'A remote country of 33 scattered coral atolls, Kiribati has few natural resources and is one of the least developed Pacific Island countries. Commercially viable phosphate deposits were exhausted by the time of independence from the United Kingdom in 1979. Earnings from fishing licenses and seafarer remittances are important sources of income, however, remittances and the number of seafarers employed have declined since the global crisis. Recently, fishing license revenues contributed close to half of government’s total revenue and total remittances from seafarers. Economic development is constrained by a shortage of skilled workers, weak infrastructure, and remoteness from international markets. The public sector dominates economic activity, with ongoing capital projects in infrastructure including the road rehabilitation, water and sanitation projects, and renovations to the international airport, spurring some growth.'
    case 'Korea, North':
      return 'The Democratic People’s Republic of Korea (DPRK or North Korea) has a history of involvement in currency counterfeiting, drug trafficking, terrorist financing, and the laundering of related proceeds, as well as the use of deceptive financial practices in the international financial system. The DPRK regime continues to present a range of challenges for the international community through its pursuit of nuclear weapons, weapons trafficking and proliferation, and human rights abuses. The FATF reaffirmed its earlier calls on its members to advise their financial institutions to give special attention to business relationships and transactions with the DPRK, including DPRK companies, financial institutions, and those acting on their behalf.In addition to enhanced scrutiny, the FATF further called on its members and urged all jurisdictions to apply effective countermeasures and targeted financial sanctions in accordance with applicable UNSCRs in order to protect their financial sectors from money laundering and proliferation financing risks emanating from the DPRK.'
    case 'Korea, South':
      return 'The Republic of Korea (South Korea) has an advanced economy that is dominated by large industrial companies. It is not an offshore banking center. While organized crime does not have a large profile, it is linked to the Japanese yakuza. There also are reports that Korean criminals tried to connect with counterparts in the Chinese triads and Nigerian gangs. Most money laundering in South Korea is associated with domestic criminals, official corruption, and ethnic Koreans living abroad. Drug smuggling in South Korea has increased in recent years. South Korean officials have uncovered numerous underground banking systems being used by South Korean nationals, North Korean defectors, and foreign national workers from China and Southeast Asian and Middle Eastern countries. Reports indicate North Korean defectors living in South Korea are remitting more than $10 million per year to family members in North Korea through illegal banking systems between South Korea and China. Gambling is legal but highly regulated and limited to non-citizens.'
    case 'Kosovo':
      return 'Kosovo is not considered a regional financial or offshore center. The country has porous borders that facilitate an active black market for smuggled consumer goods, especially fuel, cigarettes, and pirated products, largely along the Kosovo - Serbian border. Kosovo is a transit point for illicit drugs. Drugs from Afghanistan make their way through Turkey where they are often routed through the Drenica Valley in Kosovo, from where they are smuggled to Western Europe. Proceeds of drug trafficking do not fund the black market of smuggled and pirated items. There are estimates that the Kosovo informal economy approaches 40 percent or more of GDP. Illegal proceeds from domestic and foreign criminal activity are also generated from corruption, tax evasion, customs fraud, organized crime, contraband, human trafficking, and various types of financial crimes. A large amount of money is invested in real estate, restaurants, trading companies, bars, and games of chance operations, such as casinos, slot machines, and sports betting facilities. There is also a tendency to conduct business and to engage in business transactions on private accounts without business registration. Official corruption is believed to be a significant problem, as are resource constraints.'
    case 'Kuwait':
      return 'Kuwait is not a regional financial center. Twenty-three banks operate in Kuwait. Financial crimes, including money laundering, remain concerns. Illicit proceeds are primarily related to cases of fraud, smuggling (especially to/from Iraq), and corruption. Other proceeds- generating crimes are credit card fraud, piracy of goods, insider trading, and market manipulation. The authorities are unaware of the presence of serious organized or transnational crime. Private financial support to terrorist groups, particularly by individuals who operate outside of government-approved charitable-giving mechanisms, also continues to be a concern. In 2015, the Government of Kuwait took several measures to improve the oversight and regulation of charities operating in the country, including monitoring transfers to international beneficiaries. The Ministry of Social Affairs and Labor has also taken steps to monitor social media and regulate online donations.'
    case 'Kyrgyzstan':
      return 'While the Kyrgyz Republic is not a regional financial center, a large shadow economy, corruption, organized crime, and narcotics trafficking make the country vulnerable to financial crimes. Recently, known remittances from migrant workers comprised nearly 33 percent of its GDP, the majority from Russia. A significant portion of remittances entered the Kyrgyz Republic through informal channels or was hand-carried to the Kyrgyz Republic from abroad. The Kyrgyz Republic, however, is recognized as a stable economy for foreign banks and other financial institutions. The Kyrgyz Republic is strengthening its efforts to combat money laundering and financial crimes, but continues to confront challenges in implementing new laws and regulations. In the last two years, the Kyrgyz government passed a new AML law and new criminal legislation to match international standards. Challenges in the implementation of international AML/CFT standards mean the country is making minimal progress in the fight against money laundering.'
    case 'Laos':
      return 'A cash-based economy and limited capacity in the legal, regulatory, and law enforcement sectors make the Laos an attractive environment for criminal networks. Laos completed its AML/CFT National Risk Assessment in 2018 and identified high-risk areas vulnerable to money laundering and possible terrorist financing. A small number of donors and technical assistance providers have been working with Laos.'
    case 'Latvia':
      return 'Latvia is a regional financial center with a large number of commercial banks and a sizeable non-resident deposit base. Foreign depositors account for more than half of the 30 billion euros (approximately $33 billion) in Latvia’s banking system, which markets itself as a gateway to the European Union. Nonresident cash continues to flow across the border from neighboring Russia and other former Soviet states. Nonresident deposits pose a substantial risk in that money obtained from corruption and other crimes committed outside of Latvia can be laundered inside the country. Latvia’s geographic location, large untaxed shadow economy (estimated at about 25 percent of the overall economy), and public corruption make it challenging to combat money laundering. Officials do not consider proceeds from illegal narcotics to be a major source of laundered funds in Latvia. Authorities identify the primary sources of money laundered in Latvia as tax evasion; organized criminal activities, such as prostitution and fraud perpetrated by Russian and Latvian groups; and other forms of financial fraud. Latvian regulatory agencies monitor financial transactions to identify instances of terrorism financing.'
    case 'Lebanon':
      return 'Lebanon is a hub for banking activities in the Middle East and Eastern Mediterranean and has one of the most sophisticated banking sectors in the region. Lebanon has a free-market economy and a strong laissez-faire commercial tradition. The government does not restrict foreign investment; however, the investment climate suffers from red tape, corruption, arbitrary licensing decisions, complex customs procedures, high taxes, tariffs, and fees, archaic legislation, and weak intellectual property rights. The Lebanese economy is service-oriented; main growth sectors include banking and tourism. The Central Bank of Lebanon, together with its Special Investigation Commission (SIC), regularly issues and updates compliance regulations in accordance with international banking standards. The SIC, Lebanon’s FIU, is also the main AML supervisory authority and is empowered to freeze financial transactions and accounts.'
    case 'Lesotho':
      return 'Lesotho is neither a regional nor an offshore financial center. Money laundering is related primarily to corruption and tax evasion. While there is no significant black market for smuggled goods in the country, undeclared and under-declared items pass daily between Lesotho and South Africa over the countries’ extensive and porous land border. The smuggling is low level and largely committed by individuals. Smugglers commonly bring undeclared consumer goods or, occasionally, larger items like automobiles from South Africa into Lesotho. Smaller items are smuggled to avoid paperwork and other bureaucratic requirements, while larger items are smuggled to avoid paying import taxes at the borders. Small, mountainous, and completely landlocked by South Africa, Lesotho depends on a narrow economic base of textile manufacturing, agriculture, remittances, and regional customs revenue. About three-fourths of the people live in rural areas and engage in animal herding and subsistence agriculture, although Lesotho produces less than 20% of the nation\'s demand for food. Agriculture is vulnerable to weather and climate variability.'
    case 'Liberia':
      return 'The Government of Liberia has made efforts to strengthen its AML regime, but significant challenges remain. The Central Bank of Liberia (CBL) does not robustly enforce AML requirements. Interagency coordination has improved, but key stakeholders have not produced actionable financial intelligence, conducted systematic financial investigations, or secured financial crimes convictions. Financial institutions have limited capacity to detect money laundering, and their financial controls remain weak. Liberia’s FIU is under-funded and has experienced recurring budget cuts. The FIU also lacks the equipment and institutional and technical capacity to adequately collect, analyze, and disseminate financial intelligence. Liberia remains a cash-based economy with weak border controls and endemic corruption, leaving the country vulnerable to illicit activities. The government should enhance CBL oversight authority and consistently provide adequate resources to the FIU. Liberia should continue to work with international partners to ensure its AML laws, regulations, and policies meet international standards.​'
    case 'Libya':
      return 'Libya is not a regional or offshore financial center. The government appointed by the Libyan House of Representatives is based in the eastern city of Bayda, while a competing, self- proclaimed, unrecognized “government” operates from Tripoli. The inability of the Libyan government to exercise control over Libya’s territory and institutions led to further degradation of Libya’s security and governance institutions and created increased opportunities for criminals to operate in Libya. In addition to political conflict, armed militias, former revolutionaries, and tribes within Libya engage in criminal activity for profit, including theft, weapons trafficking, and extortion. Libya’s geographic location, porous borders, and limited law enforcement capacity make it an attractive transit point for narcotics. Libya also is a source, destination, and transit point for smuggled goods, including government-subsidized items, such as fuel and food, as well as black market and counterfeit goods from sub-Saharan Africa, Egypt, and China. Corruption remains a serious problem.'
    case 'Liechtenstein':
      return 'The Principality of Liechtenstein is the richest country on earth on a GDP per capita basis. It has a well-developed offshore financial services sector, relatively low tax rates, liberal incorporation and corporate governance rules, and a tradition of bank secrecy. All of these conditions contribute significantly to the ability of financial intermediaries in Liechtenstein to attract funds from abroad. Liechtenstein’s financial services sector includes 16 banks, 117 fund/asset management companies, 381 trust companies/trustees and 44 insurance companies. The three largest banks in Liechtenstein manage 85 percent of the country’s $125 billion in wealth. The business model of Liechtenstein’s financial sector focuses on private banking, wealth management, and mostly nonresident business. It includes the provision of corporate structures such as foundations, companies, and trusts that are designed for wealth management, the structuring of assets, and asset protection. In recent years Liechtenstein banking secrecy has been softened to allow for greater cooperation with other countries to identify tax evasion.'
    case 'Lithuania':
      return 'Lithuania is not a regional financial center. It has adequate legal safeguards against money laundering; however, its geographic location bordering Belarus and Russia makes it a target for smuggled goods and tax evasion. The sale of narcotics does not generate a significant portion of money laundering activity in Lithuania. Value added tax (VAT) fraud is one of the biggest sources of illicit income, through underreporting of goods’ value. Most financial crimes, including VAT embezzlement, cigarette smuggling, illegal production and sale of alcohol, illegal capital flight, and profit concealment, are tied to tax evasion. There are no reports of public corruption contributing to money laundering or terrorism financing.'
    case 'Luxembourg':
      return 'Despite its standing as the second-smallest member of the EU, Luxembourg is one of the largest financial centers in the world. It also operates as an offshore financial center. Although there are a handful of domestic banks operating in the country, the majority of banks registered in Luxembourg are foreign subsidiaries of banks in Germany, Belgium, France, Italy, and Switzerland. While Luxembourg is not a major hub for illicit narcotics distribution, the size and sophistication of its financial sector create opportunities for money laundering, tax evasion, and other financial crimes. Hundreds of well-known multinationals have secured deals in Luxembourg that allow them to legally slash their taxes in their home countries. In some cases the Luxembourg subsidiaries of multinationals, that on paper handle hundreds of millions of dollars in business, maintain only a token presence or a simple front address. While corporate tax avoidance is technically legal, in many jurisdictions tax evasion is illegal and a predicate offense for money laundering. The international standards include tax crimes as designated predicate crimes for money laundering.'
    case 'Macau':
      return 'Macau, a Special Administrative Region (SAR) of the People’s Republic of China, is not a significant regional financial center. Its financial system, which services a mostly local population, includes offshore financial businesses such as credit institutions, insurers, underwriters, and trust management companies. The offshore sector is subject to supervisory requirements similar to those of domestic institutions, and to oversight by Macau’s Monetary Authority.'
    case 'Macedonia':
      return 'Macedonia is a middle income country with a fairly developed financial system. It is not a regional financial center. While most financial transactions are done through the well regulated and supervised banking system, cash transactions of considerable amounts occasionally take place outside the banking system. Money laundering in Macedonia is most often linked to financial crimes such as tax evasion, smuggling, financial fraud, insurance fraud, and corruption. Most of the laundered proceeds come from domestic criminal activities. A small portion of money laundering activity is connected to narcotics trafficking. There is no evidence that narcotics trafficking organizations or terrorist groups control money laundering. Also, there is no evidence that human or weapons traffickers have been involved in money laundering activities using banking or non-banking financial institutions. Money transfers, structuring cash deposits, the purchase of real estate and goods, and the use of legal entities in offshore jurisdictions are frequent money laundering techniques.'
    case 'Madagascar':
      return 'Madagascar is neither a regional financial center nor a major source country for drug trafficking; however, Madagascar’s inadequately monitored 3,000 miles of coastline leave the country vulnerable to smuggling and associated money laundering. Criminal proceeds laundered in Madagascar derive mostly from domestic criminal activity, not generally related to the narcotics trade. The major sources of laundered proceeds are tax evasion, tax appropriation, and customs fraud. Illegal mining and mineral resources smuggling, illegal logging, public corruption, and foreign currency smuggling are also areas of concern. The smuggling of gold, gemstones (predominantly to the Gulf), and protected flora and fauna (predominantly to Asia) generates funds that are laundered through the financial system or through informal channels into which the government has limited reach. There is a significant black market for smuggled or stolen consumer goods, especially in port cities.'
    case 'Malawi':
      return 'Malawi is not a regional financial center. The main source of illegal profits in Malawi derives from public corruption. Malawi is currently addressing a major corruption scandal popularly known as “Cashgate” centering on the looting of government accounts by public officials through fraudulent transactions in the government’s computerized payments system. High ranking officials, including a former Minister of Justice, a former budget director, and a former Defense Force chief have been indicted in the scheme. Another significant source of illicit funds is the production and trade of cannabis sativa (Indian hemp), which is cultivated in some remote areas of the country. Anecdotal evidence indicates Malawi is a transshipment point for other forms of narcotics. Human trafficking, vehicle hijacking, wildlife trafficking, and fraud are also areas of concern. Smuggling and the laundering of funds are exacerbated by porous borders with Mozambique, Zambia, and Tanzania.'
    case 'Malaysia':
      return 'Malaysia is a highly open, upper-middle income economy with exposure to a range of money laundering threats. The country’s porous land and sea borders, visa-free entry policy for nationals from over 160 countries, strategic geographic position, and well-developed financial system increase its vulnerability to domestic and transnational criminal activity, including fraud, corruption, drug trafficking, wildlife trafficking, smuggling, tax crimes, terrorism, and terrorism finance. Malaysia has largely up-to-date AML legislation, well-developed policies, institutional frameworks, and implementation mechanisms. The country has shown continuing progress in efforts to improve AML enforcement by increasing money laundering investigations, prosecutions, and convictions. One key area for development is the prosecution of foreign sourced crimes. '
    case 'Maldives':
      return 'Maldives is comprised of a series of atolls in the Indian Ocean and is bisected by a number of international sea lanes. Authorities have expressed concern the islands are being used as a transit point for money laundering, narcotics trafficking, and illegal immigration to Europe. The country has a small financial market but is susceptible to money laundering and terrorist financing due to limited oversight capacity. No official figures are available, but anecdotal evidence suggests illegal drug trafficking, a large black market for the purchase of dollars, and corruption produce significant amounts of illicit funds. Criminal proceeds reportedly come mainly from domestic sources. Drug trafficking is noted as one of the most frequent asset-generating crimes. Other offenses include human trafficking, piracy, and offenses committed by gangs. Even though the number of corruption cases is low, only a small percentage is prosecuted. Reports indicate the sums involved can be significant. There are indications funds raised in the country have been used to finance terrorism activities abroad.'
    case 'Mali':
      return 'Mali is not a regional financial center and has no free trade zones or offshore sectors. Illegal proceeds derive primarily from rampant trafficking of everyday commodities, people, small arms, and narcotics across the Algerian, Nigerien, and Mauritanian borders. Al-Qaida in the Islamic Maghreb and other al-Qaida-linked and armed groups, known to operate in the sparsely populated north, are involved in smuggling as well as kidnapping for ransom to generate funds. Mali’s economy is largely cash-based, making it difficult to detect illicit financial activity or track the proceeds of crime. Malian authorities believe the proceeds of trafficking activity in Europe may pass through Malian banks as they are returned to South America or elsewhere, but lack the resources to make a definitive determination. Mali is a member of the West African Economic and Monetary Union (WAEMU), which also includes Benin, Burkina Faso, Cote d’Ivoire, Guinea-Bissau, Niger, Senegal, and Togo. All of the WAEMU members share a common currency, the West African CFA, and have developed similar AML/CFT frameworks, including legal and financial intelligence unit (FIU) structures.'
    case 'Malta':
      return 'Malta’s location between North Africa and Italy makes it a transit point for narcotics and human trafficking to Europe. The country’s offshore banking sector is relatively large (eight times GDP), and its ship registry is the largest in Europe. According to the Malta Police Force, the major sources of illegal proceeds are generated through drug trafficking (in particular cocaine, heroin, and cannabis resin) and economic crimes, primarily fraud and misappropriation of public funds. The proceeds generated are not substantial and are primarily based on domestic offenses and eventual self-laundering. Money laundering investigations related to drug trafficking revolve around the suspects living beyond their means and converting the funds by purchasing commodities, such as expensive vehicles, real estate, and other luxury goods. Foreigners who route illicit gains from illegal activity in foreign jurisdictions to local Maltese bank accounts generate a significant volume of laundered funds.'
    case 'Marshall Islands':
      return 'The Republic of the Marshall Islands (RMI) consists of 29 atolls and five islands, covering 70 square miles of land, spread across 750,000 square miles of ocean. The country is economically underdeveloped and has limited resources for private sector development. The RMI signed a Compact of Free Association with the United States in 1986, and relies on the United States for the majority of its economic support. Although the Marshall Islands accounts for less than one percent of the global market for offshore financial services, making it a tiny player compared with other secrecy jurisdictions, the RMI offshore corporate sector is vulnerable to money laundering. There are two banks in the country, the Bank of the Marshall Islands and a branch office of the Bank of Guam.'
    case 'Martinique':
      return 'In the early 21st century, five French overseas entities - French Guiana, Guadeloupe, Martinique, Mayotte, and Reunion - became French regions and were made part of France proper. Martinique is located in the Caribbean. It consists of an island between the Caribbean Sea and the North Atlantic Ocean, north of Trinidad and Tobago. The climate is subtropical and tempered by trade winds with moderately high humidity. Because of the rainy season (June to October), it is vulnerable to devastating cyclones (hurricanes) every eight years on average. It is built on a dormant volcano;.'
    case 'Mauritania':
      return 'The Islamic Republic of Mauritania has a largely informal and under-developed economy. Its economic system suffers from a combination of weak government oversight, lax financial auditing standards, a large informal trade sector, porous borders, and corruption in government and the private sector. Money laundering is difficult to detect in Mauritania because of the informal nature of much of the economy and speculation that large amounts of drug money pass through the economy. The banking system and black market currency exchanges constitute the focus of this activity, which affects the operation of the entire financial sector in the country. There are strong indications that large amounts of money are being pumped into the financial system from outside or suspicious sources, some of which are transported across borders or through ports. Border security is a severe challenge in Mauritania. Mauritania imports almost 70 percent of its food needs. Only an estimated 12 percent of Mauritanian adults have bank accounts, and informal banking and financial systems remain vulnerable to exploitation.'
    case 'Mauritius':
      return 'Although Mauritius has developed a reputation as a well-regulated financial jurisdiction, its regulatory and enforcement scheme has some limitations. Investigations have centered on members of the former government and its financiers. Opposition parties and the media have criticized some of these actions as being politically motivated. The major sources of laundered funds in Mauritius are crimes involving drug trafficking (mainly heroin and the prescription drug subutex), as well as theft of goods, conspiracy, forgery, swindling, Ponzi schemes, and corruption. Media reports indicate money laundering occurs in the banking system, the offshore financial sector, and the non-bank financial sector. Criminal proceeds are derived from both domestic and foreign criminal activities. Mauritius is a significant foreign investment route into the Asian sub-continent and, increasingly, into mainland Africa.'
    case 'Mayotte':
      return 'In the early 21st century, five French overseas entities - French Guiana, Guadeloupe, Martinique, Mayotte, and Reunion - became French regions and were made part of France proper. Mayotte is located in the Southern Indian Ocean. It consists of an island in the Mozambique Channel, about halfway between northern Madagascar and northern Mozambique. The climate is tropical and marine. There is are a rainy season during northeastern monsoon (November to May) and a cooler dry season. The island is mountainous with indented coastline. The terrain is generally undulating, with deep ravines and ancient volcanic peaks.'
    case 'Mexico':
      return 'Billions of dollars of drug trafficking proceeds are laundered through the Mexican financial system annually. Corruption, bulk cash smuggling, kidnapping, extortion, fuel theft, intellectual property rights violations, fraud, human smuggling, and trafficking in persons and firearms are additional sources of laundered funds. Authorities have had some success investigating and freezing accounts of suspected launderers, but have shown extremely limited progress in successfully prosecuting financial crimes. Two Supreme Court rulings curbed the authority of the Financial Intelligence Unit (UIF) and the Federal Prosecutor General’s office (FGR), complicating Mexico’s ability to counter illicit financial activities. As a result, Mexican authorities now must rely on international requests for assistance or judicial seizure orders obtained by FGR to freeze accounts. Money laundering activity continues as the government struggles to prosecute financial crimes and seize illicit assets. To increase the number of money laundering convictions, the government needs to combat corruption and improve investigative and prosecutorial capacity. '
    case 'Micronesia':
      return 'The Federated States of Micronesia (FSM) has a small population of 100,000 people spread over a million square miles of the western Pacific Ocean. The FSM was part of the U.S. Trust Territory of the Pacific after World War II, and is now associated with the United States by the Amended Compact of Free Association. The FSM uses the dollar as its only currency and has only two commercial banks operating in the country, the domestically-owned Bank of FSM and branches of the Bank of Guam. While the FSM’s lack of visibility and ease of moving dollars to and from the United States could potentially make it a back door for nefarious movements of currency, the small scale of its economy and regulatory oversight of the banks by U.S. regulators make the FSM historically a low risk for money laundering, terrorist financing, or smuggling. Public corruption does exist in the area of public contracting and employment of unqualified companies or persons, but there are no accurate estimates of the proceeds derived from cronyism.'
    case 'Moldova':
      return 'Moldova is not a regional financial center. The economy is largely cash-based and remains highly vulnerable to money laundering activities. The Government of Moldova monitors money flows throughout the country, but does not exercise control over the breakaway region of Transnistria. Transnistrian authorities do not adhere to Moldovan financial controls and maintain a banking system independent of, and not licensed by, the National Bank of Moldova (NBM). The breakaway region of Transnistria is highly susceptible to money laundering schemes. Due to the Moldova government’s inability to enforce the laws on this territory, Transnistrian banking and financial laws and regulations are not in compliance with accepted international AML/CFT norms. Criminal proceeds laundered in Moldova derive substantially from tax evasion, contraband smuggling, fraud, and corruption. Money laundering occurs within the banking system, exchange houses, and the offshore financial centers in Transnistria. Currently, 11 banks are operating in Moldova. Neither offshore banks nor shell companies are permitted; despite this ban, shell companies continue to be used to launder illicit proceeds.'
    case 'Monaco':
      return 'The Principality of Monaco is the second-smallest country in Europe but is considered a major banking center that closely guards the privacy of its clients. It has worked in recent years to comply with international requirements for greater openness and sharing of information. It is linked closely to France and to the economic apparatus of the EU through its customs union with France and its use of the euro as its official currency. Monaco’s state budget is based primarily on value-added tax revenue, taxes on legal transactions, income from the real estate sector, and corporate income tax, which account for 78 percent of the total income; casino revenues constitute less than three percent of the state budget. Private banking and fund management dominate the financial sector. Monaco does not have a formal offshore sector, but approximately 60 percent of the banking sector’s total assets and deposits are owned by foreigners.'
    case 'Mongolia':
      return 'Mongolia is not a regional financial center. There are few reported financial and economic crimes, although numbers have increased in the last five years. Mongolia is vulnerable to low- grade transnational crime due to the current level of tourism, investment, and remittances from abroad; however, the overall rate of these crimes has not increased. The risk of domestic corruption remains significant as Mongolia’s rapid economic growth continues. Mongolia’s limited capacity to monitor its extensive borders with Russia and China is a liability in the fight against smuggling and narcotics trafficking, but drug use and trafficking remain limited and unsophisticated. There is a black market for smuggled goods which appears largely tied to tax avoidance. There are no indications international narcotics traffickers exploit the banking system, and no instances of terrorism financing have been reported.'
    case 'Montenegro':
      return 'Montenegro’s geographic location and use of the euro make it an attractive target for money laundering. Public perception of corruption in Montenegro remains widespread. Factors that facilitate Montenegro’s vulnerability to money laundering are the use of cash for many large commercial transactions, weak financial crimes enforcement, and a lack of monetary controls over currency use, as Montenegro uses the euro but is not a Eurozone member country. Additional factors that inhibit the fight against money laundering include corruption, insufficient capacity to conduct financial investigations, weak collaboration among government agencies, and a judicial system susceptible to political influence. Organized crime remains a serious concern in Montenegro and is linked to corruption. Criminal organizations, including sophisticated international narcotics trafficking enterprises, have a presence in Montenegro. Montenegro is a transit country for illegal goods.'
    case 'Montserrat':
      return 'Montserrat has one of the smallest financial sectors of the UK’s Caribbean Overseas Territories. Less than 5,000 people are resident on the island. Montserrat’s operating budget is largely funded by the British government and administered through the Department for International Development. Montserrat has four international banks, two domestic banks, three life insurance companies, and two institutions providing other forms of credit. There are few offenses committed in Montserrat that generate substantial illicit profits, although there is some marijuana cultivation on the island. The low number of transactions generated in the financial sector suggests that criminal monies are not entering the mainstream economy through financial institutions. Montserrat’s international business companies (IBCs) are required to have a locally licensed company manager as registered agent, or in the case of trusts, a locally licensed trust company with responsibility for undertaking customer due diligence procedures and monitoring AML compliance.'
    case 'Morocco':
      return 'Morocco is strengthening its AML regime through legislation and capacity building. Money laundering vulnerabilities in Morocco stem from the prevalence of cash-based transactions, a high volume of remittances, international trafficking networks, public corruption, and deficient AML controls. Morocco serves as an integration point for illicit drug money into the legitimate economy, with an estimated hundreds of millions of dollars laundered through Morocco annually. Financiers of terrorism take advantage of these vulnerabilities.'
    case 'Mozambique':
      return 'Money laundering in Mozambique is driven by misappropriation of state funds, kidnappings, human trafficking, narcotics trafficking, wildlife trafficking, and, potentially, terrorism. With a long, largely unpatrolled coastline, porous land borders, and limited rural law enforcement presence, Mozambique is a major corridor for illicit goods such as hardwoods, gemstones, wildlife products, and narcotics. Narcotics are typically trafficked through Mozambique to other African nations and then on to further destinations, such as Europe and the United States. The Attorney General (PGR) and Bank of Mozambique (BOM) have shown a willingness to address money laundering, and the Government of Mozambique has taken steps to improve the legal framework. However, attorneys, judges, and police lack the technical capacity and resources to combat money laundering successfully. Mozambique would also benefit from better collaboration between AML/CFT enforcement institutions. One international governance NGO assessed that recently Mozambique had the highest risk of money laundering and terrorist financing out of 125 countries surveyed. '
    case 'Namibia':
      return 'Namibia is not a regional financial center, although it has one of the most highly developed financial systems in Africa. Both regional and domestic criminal activities give rise to proceeds that are laundered in Namibia. Falsification or misuse of identity documents, customs violations, trafficking of precious metals and gems, and trafficking in wildlife, illegal drugs, and stolen vehicles, mostly from South Africa, are regional problems that affect the level of money laundering in Namibia. Organized criminal groups involved in smuggling activities generally use Namibia as a transit point. Domestically, real estate as well as minerals and gems are suspected of being used as vehicles for money laundering. Namibian authorities believe the proceeds of criminal activities are laundered through Namibian financial institutions, but on a small scale. The Namibian government has set up Export Processing Zones (EPZs). Companies with EPZ status can set up operations anywhere in Namibia.'
    case 'Nauru':
      return 'Nauru is a small central Pacific island nation with a population of approximately 9,400. A member of the British Commonwealth, Nauru is an independent republic but uses Australian currency. The only banking institutions operating on Nauru are the Bendigo Bank offering traditional, internet, and telephone banking deposit and withdrawal services, and Western Union, which provides wire transfer services. The economy remains largely cash-based and reliant on formal and informal remittances. Nauru has high unemployment and is a low-crime jurisdiction. The very narrow economic base, the lack of financial institutions, and very strict land tenure and associated restrictions on foreign investment discourage the introduction of criminal proceeds into the formal Nauru economy. Nauru has a relatively small offshore company registry with 59 operating corporations. In the past ten years no new trust company licenses have been issued, although 15 unit trusts have been formed under the 11 existing licenses. The offshore companies and trusts represent a concern, although the very low rate of company and trust formation in the sector may indicate the risks are relatively low.'
    case 'Nepal':
      return 'Nepal is not a regional financial center. Government corruption, a large and open border with weak border enforcement, limited financial sector regulations, and a large informal economy continue to make the country vulnerable to money laundering and terrorist financing. Nepal is not a significant producer of narcotic drugs; however, hashish, heroin, and domestically produced cannabis and opium are trafficked to and through Nepal. Relatively porous borders also are used to facilitate the trafficking of persons. Other major sources of illegally earned income include tax evasion, corruption, counterfeit currency, smuggling, and invoice manipulation. While government and banking industry officials report that most remittances flow through formal banking channels, a portion is believed to flow through informal channels. Additionally, the government has limited ability to determine whether the source of money ostensibly sent as remittances from abroad is licit or illicit. An open border with India and inadequate security screening make it difficult to detect currency and gold flowing in and out of the country.'
    case 'Netherlands':
      return 'The Netherlands is an active player in the field of AML/CFT. Nevertheless, as a major trade and financial center the Netherlands is an attractive venue for laundering funds generated from illicit activities, including those related to the sale of drugs. A recent government-commissioned study estimated that around $17.7 billion is laundered annually in the Netherlands. Six islands in the Caribbean fall under the jurisdiction of the Kingdom of the Netherlands: Bonaire, St. Eustatius, and Saba are special municipalities of the Netherlands; and Aruba, Curacao, and Sint Maarten are autonomous countries within the Kingdom. The Netherlands provides supervision for the courts and for combating crime and drug trafficking within the Kingdom.'
    case 'Netherlands Antilles':
      return 'The Netherlands Antilles dissolved on October 10, 2010. Six islands in the Caribbean fall under the jurisdiction of the Kingdom of the Netherlands. Bonaire, St. Eustatius, and Saba are special municipalities of the Netherlands. Aruba, Curacao, and St. Maarten are countries within the Kingdom of the Netherlands. The Netherlands provides supervision for the courts and for combating crime and drug trafficking within the Kingdom. As special municipalities, Bonaire, St. Eustatius, and Saba are officially considered “public bodies” under Dutch law. '
    case 'New Caledonia':
      return 'Settled by both Britain and France during the first half of the 19th century, the island became a French possession in 1853. It served as a penal colony for four decades after 1864. Agitation for independence during the 1980s and early 1990s ended in the 1998 Noumea Accord, which over two decades transferred an increasing amount of governing responsibility from France to New Caledonia. New Caledonia has 11% of the world\'s nickel reserves, representing the second largest reserves on the planet. Only a small amount of the land is suitable for cultivation, and food accounts for about 20% of imports. In addition to nickel, substantial financial support from France - equal to more than 15% of GDP - and tourism are keys to the health of the economy. With the gradual increase in the production of two new nickel plants, average production of metallurgical goods stood at a record level of 94 thousand tons. However, the sector is exposed to the high volatility of nickel prices, which have been in decline.'
    case 'New Zealand':
      return 'New Zealand is not a major regional or offshore financial center. Money laundering cases are infrequent in New Zealand. However, authorities note it is difficult to estimate the extent of money laundering activities, since every serious crime that generates proceeds could lead to a money laundering offense. Money laundering generally occurs through the financial system, but the purchase of real estate and other high-value assets as well as the use of foreign exchange dealers have become increasingly popular methods of laundering money. Narcotics proceeds (mostly from methamphetamine and cannabis sales) and fraud-associated activity (primarily internet banking fraud) are the primary sources of illicit funds. International organized criminal elements, mostly from Asia, are known to operate in New Zealand, but not to a wide extent. Local gangs represent a disproportionate number of homicides and drug offenses, and have been implicated in money laundering cases. New Zealand is a low threat environment for terrorist finance. New Zealand has a small number of casinos, which operate gaming machines and a variety of table games.'
    case 'Nicaragua':
      return 'Nicaragua is not a regional financial center but remains vulnerable to money laundering as a transit country for illegal narcotics. Factors that increase the risk for financial abuse and international organized crime include the current socio-political crisis, law enforcement corruption, the deterioration of democratic institutions, and the politicization of AML/CFT regulators. The government’s lack of political will to fight corruption and organized crime, and the ineffectiveness of its regulatory bodies, remain hindrances to regional security efforts. The areas of greatest concern include the government’s unwillingness to investigate sanctioned individuals and its decision to harbor high-profile former foreign officials accused of corruption and money laundering. Its potential to domestically weaponize AML/CFT laws for use against political opponents is particularly dangerous.'
    case 'Niger':
      return 'Niger is one of the poorest and least developed countries in the world. Niger is not a regional financial center; its banking sector is rudimentary. It is a member of the Central Bank of West African States (BCEAO), and so shares its central bank and currency, the CFA Franc, with other countries in the region. High transaction costs deter businesses from placing large amounts of cash in the banking system. Most economic activity takes place in the informal, cash-based financial sector; and informal remitters and other money and value transfer services are widespread. With porous borders and a large, under-governed territory, Niger provides an ideal transit point for various criminal organizations and terrorist groups are commonplace in Niger. Illegal proceeds derive from trafficking of drugs, small arms, people, and everyday commodities across the Algerian and Libyan borders in the sparsely-populated north of the country. The regional smuggling of huge quantities of cigarettes across the Sahel is also very lucrative for traffickers in Niger. Cigarettes from counterfeit plants, mainly from Nigeria, are distributed in the region, the Maghreb, the Middle East, and Europe. Kidnapping for ransom is a significant fundraising method for terrorist groups.​'
    case 'Nigeria':
      return 'Despite the various measures taken by the Nigerian government to combat financial crimes, Nigeria is a major drug trans-shipment point and a significant center for financial crime and cyber-crimes. Nigeria has made concerted efforts in recent times to address some of the challenges it faces implementing its AML/CFT regime. The Nigerian Financial Intelligence Unit (NFIU) is the national AML/CFT coordinator for Nigeria.'
    case 'Niue':
      return 'Niue is not a regional financial center and has no free trade zones. Niue is a self-governing democracy, operating in free association with New Zealand. The Government of Niue relies heavily on New Zealand to assist with external and economic affairs. The country has experienced a significant decline in population, largely from the emigration of its population to New Zealand. The economy suffers from the typical Pacific island problems of geographic isolation, few resources, and a small population. The agricultural sector consists mainly of subsistence gardening, although some cash crops are grown for export. Industry consists primarily of small factories for processing passion fruit, lime oil, honey, and coconut cream. The sale of postage stamps to foreign collectors is an important source of revenue. Government expenditures regularly exceed revenues, and the shortfall is made up by critically needed grants from New Zealand that are used to pay wages to public employees.'
    case 'Norfolk Island':
      return 'Two British attempts at establishing the island as a penal colony (1788-1814 and 1825-55) were ultimately abandoned. In 1856, the island was resettled by Pitcairn Islanders, descendants of the Bounty mutineers and their Tahitian companions. Norfolk Island is suffering from a severe economic downturn. Tourism, the primary economic activity, is the main driver of economic growth. The agricultural sector has become self-sufficient in the production of beef, poultry, and eggs.'
    case 'Northern Mariana Islands':
      return 'Under US administration as part of the UN Trust Territory of the Pacific, the people of the Northern Mariana Islands decided in the 1970s not to seek independence but instead to forge closer links with the US. Negotiations for territorial status began in 1972. A covenant to establish a commonwealth in political union with the US was approved in 1975, and came into force on 24 March 1976. A new government and constitution went into effect in 1978. The economy of the Commonwealth of the Northern Mariana Islands(CNMI) has been on the rebound in the last few years, mainly on the strength of its tourism industry. The Commonwealth is making a concerted effort to broaden its tourism by extending casino gambling from the small Islands of Tinian and Rota to the main Island of Saipan, its political and commercial center. Investment is concentrated on hotels and casinos in Saipan, the CNMI’s largest island and home to about 90% of its population.'
    case 'Norway':
      return 'Although it is a high income country, Norway is not considered a regional financial center. Norway’s significance in terms of money laundering is low. There are illicit proceeds related to narcotics sales and production, prostitution, robberies, smuggling, and white collar crimes, such as embezzlement, tax evasion, and fraud. Criminal proceeds laundered in the jurisdiction derive primarily from domestic criminal activity, often by foreign criminal gangs or guest workers who in turn remit the proceeds home. The main money laundering techniques used in Norway are cash deposits and withdrawals, the use of professional facilitators such as lawyers and accountants, the buying and selling of high-value assets, and the use of cash couriers and money or value transfer services to move funds out of the country. Money and value transfer services pose risks in Norway due to the nature of the activity, combined with limited supervision of the sector.'
    case 'Oman':
      return 'Oman is not a regional or offshore financial center and does not have significant money laundering or terrorism financing concerns. Due to its location on the tip of the Strait of Hormuz, Oman is home to a small number of smugglers operating between Musandam, the northern-most exclave of Oman, and Iran. Omani authorities are aware that growing Iranian overtures toward Oman for increased trade and engagement, particularly in light of potential sanctions relief, may create conditions for money laundering/terrorism financing activity. Trade is generally financed with small amounts of cash and mostly comprises consumer goods. Oman is a regional transit point for narcotics from Afghanistan, Pakistan, Iran, and Tanzania, although the government is proactive in tracking and prosecuting drug traffickers. Sources of illegal proceeds are generally limited and derived from smuggling or drug trafficking activities. Smugglers and drug traffickers are generally expatriates. orruption, primarily in the form of cronyism or insider operations, remains a concern.'
    case 'Pakistan':
      return 'Pakistan’s geographic location and porous borders with Afghanistan, Iran, and China make it vulnerable to narcotics and contraband smuggling. Pakistan’s national risk assessment (NRA) identifies the largest risks associated with illicit finance as corruption, smuggling, drug trafficking, fraud, kidnapping for ransom, and extortion from businesses. The NRA also found the proceeds generated from major crimes in Pakistan are transferred overseas. The black market, informal financial system, and permissive security environment generate substantial demand for money laundering and illicit financial services. The Pakistan Tehreek-e-Insaf (PTI) party has taken anticorruption measures since coming to power, some of which have closed money laundering loopholes. '
    case 'Palau':
      return 'Palau is not a regional or offshore financial center. The primary sources of illegal proceeds are illegal drugs and prostitution. Corruption in the governmental sector includes the misuse of government funds and cronyism, in part due to Palau’s small size and extensive family networks. Palau is a low-risk jurisdiction for organized crime and terrorism financing. Palau has one free trade zone, the Ngardmau Free Trade Zone (NFTZ). A public corporation, Ngardmau Free Trade Zone Authority, oversees the development of the NFTZ and issues licenses for businesses to operate there. NFTZ licensing exempts businesses from Foreign Investment Act requirements and certain import and export taxes. To date, no development has taken place within the designated free trade zone area, and the NFTZ directors continue to search for developers and investors.'
    case 'Palestinian Territories':
      return 'Inhabited since at least the 15th century B.C., the Palestinian Territories have been dominated by many different peoples throughout its history; it was incorporated into the Ottoman Empire in the early 16th century. The Palestinian Territories fell to British forces during World War I, becoming part of the British Mandate of Palestine. Following the 1948 Arab-Israeli War, the West Bank was captured by Transjordan (later renamed Jordan), which annexed the West Bank 1950; both were captured by Israel in the Six-Day War in 1967. Under a series of agreements known as the Oslo accords signed between 1993 and 1999, Israel transferred to the newly created Palestinian Authority (PA) security and civilian responsibility for many Palestinian-populated areas of the Palestinian Territories. In 2000, a violent intifada or uprising began, and in 2001 negotiations to determine the permanent status of the Palestinian Territories stalled. Subsequent attempts to re-start direct negotiations have not resulted in progress toward determining final status of the area.'
    case 'Panama':
      return 'Panama’s geographic location; dollarized economy; status as a regional financial, trade, and logistics hub; and favorable corporate and tax laws render it attractive for money launderers. High-profile international money laundering investigations, originated in Panama or elsewhere, have intensified scrutiny of Panama’s vulnerabilities and ability to address illicit financial activity. Panama has agreed to address money laundering and terrorist financing deficiencies noted by international experts, including by strengthening its understanding of its national and sectoral money laundering and terrorist financing risk; strengthening DNFBP regulation and supervision; applying appropriate sanctions for violations; ensuring access to beneficial ownership information; and improving investigations and prosecutions of foreign tax crimes and money laundering in relation to high-risk areas. Panama has drafted and introduced substantive AML legislation, including draft legislation to create a registry of registered agent and beneficial ownership information. '
    case 'Panama Canal Zone':
      return 'With US backing, Panama seceded from Colombia in 1903 and promptly signed a treaty with the US allowing for the construction of a canal and US sovereignty over a strip of land on either side of the structure (the Panama Canal Zone). The Panama Canal was built by the US Army Corps of Engineers between 1904 and 1914. In 1977, an agreement was signed for the complete transfer of the Canal from the US to Panama by the end of the century. Certain portions of the Zone and increasing responsibility over the Canal were turned over in the subsequent decades. With US help, dictator Manuel NORIEGA was deposed in 1989. The entire Panama Canal, the area supporting the Canal, and remaining US military bases were transferred to Panama by the end of 1999. An ambitious expansion project to more than double the Canal\'s capacity - by allowing for more Canal transits and larger ships - was carried out between 2007 and 2016.'
    case 'Papua New Guinea':
      return 'Papua New Guinea (PNG) is richly endowed with natural resources, but exploitation has been hampered by rugged terrain, land tenure issues, and the high cost of developing infrastructure. The economy has a small formal sector, focused mainly on the export of those natural resources, and an informal sector, employing the majority of the population. Agriculture provides a subsistence livelihood for 85% of the people. The global financial crisis had little impact because of continued foreign demand for PNG\'s commodities. Mineral deposits, including copper, gold, and oil, account for nearly two-thirds of export earnings. Natural gas reserves amount to an estimated 155 billion cubic meters. A consortium led by a major American oil company is constructing a liquefied natural gas (LNG) production facility. In recent years, the government has opened up markets in telecommunications and air transport, making both more affordable to the people.'
    case 'Paraguay':
      return 'Paraguay’s economy returned to a growth trajectory after a brief economic recession recently as agricultural export revenue fell due to drought conditions and lower international commodity prices. The Tri-Border Area (TBA), comprised of the shared border areas of Paraguay, Argentina, and Brazil, is home to a multi-billion-dollar illicit goods trade, including marijuana cultivation and the trafficking of Andean cocaine and arms, which facilitates significant money laundering in Paraguay. The government has worked to reduce the criminal use of Paraguay’s financial system to launder illicit proceeds by taking steps to address corruption, eliminate bureaucratic inefficiencies, and enhance interagency coordination. President Mario Abdo Benitez’s administration has continued Paraguay’s focus on these efforts, showing results in new legislation and arrests but modest outcomes in terms of implementation and convictions. '
    case 'Peru':
      return 'The Peruvian government identified $1.75 billion in potentially illicit funds flowing through Peru. Illegal mining produced over $880 million in illicit proceeds and makes up 45 percent of all money laundered in Peru in the past nine years, by far the largest sector. Drug traffickers frequently launder profits through illegal mining activities and gold transactions. The government has implemented steps to strengthen its AML regime, including implementing a new law to improve oversight of savings and loan cooperatives, issuing regulations to implement its civil asset forfeiture law and creating 22 specialized asset forfeiture courts. Peru also continued implementing its National Plan to Combat Money Laundering (National AML Plan). Peru struggles to effectively enforce and implement its strong AML legal regime. Poor interagency coordination and limited information sharing impedes enforcement efforts, as well as lack of expertise, high turnover, and corruption within the justice sector. Weak regulatory enforcement and oversight of the small-scale mining sector continues to be a concern. '
    case 'Philippines':
      return 'The Philippines’ growing economy and geographic location within key trafficking routes place it at elevated risk of money laundering and terrorism financing. Recent growth in the online gaming industry also presents increased risk. Corruption and human trafficking constitute some of the principal sources of criminal proceeds. Insurgent groups operating in the Philippines derive funding from kidnapping for ransom and narcotics and arms trafficking. Additionally, the large volume of remittances from Filipinos living abroad increases the monitoring burden on AML authorities. Despite the challenges, the Philippine government is improving its AML posture. The government has taken additional steps to build the capacity of its FIU and interagency cooperation. The Philippines should build on the recent efforts to improve interagency coordination and seek to bolster its financial investigations and criminal convictions.'
    case 'Pitcairn Islands':
      return 'Pitcairn Island was discovered in 1767 by the British and settled in 1790 by the Bounty mutineers and their Tahitian companions. Pitcairn was the first Pacific island to become a British colony (in 1838) and today remains the last vestige of that empire in the South Pacific. Outmigration, primarily to New Zealand, has thinned the population from a peak of 233 in 1937 to less than 50 today. The inhabitants of this tiny isolated economy exist on fishing, subsistence farming, handicrafts, and postage stamps. The fertile soil of the valleys produces a wide variety of fruits and vegetables, including citrus, sugarcane, watermelons, bananas, yams, and beans. Bartering is an important part of the economy. The major sources of revenue are the sale of postage stamps to collectors and the sale of handicrafts to passing ships.'
    case 'Poland':
      return 'Poland is a high-income economy according to World Bank definitions and the eighth largest economy in the European Union. Poland lies directly along one of the main routes used by narcotics traffickers and organized crime groups between the former Soviet Union republics and Western Europe. However, Poland is not considered a regional financial center, nor is it considered a particularly important international destination for money laundering. According to the Government of Poland, evasion of customs duties and taxes by organized Polish criminal elements and others remains the largest source of illegal funds. Authorities identified virtual currencies, specifically bitcoin, as an increasingly significant avenue for money laundering. Authorities continue to report that Asian (primarily Chinese and Vietnamese) organized criminal elements are increasingly remitting profits from tax evasion and the sale of counterfeit goods via money transfers and couriers. The majority of Asian organized crime activity occurs at the Chinese Trade Center located in Wolka Kosowska, approximately 25 kilometers from Warsaw.'
    case 'Portugal':
      return 'Portugal has AML laws and enforcement mechanisms that meet international standards and has taken steps to further improve AML legislation. The majority of money laundered in Portugal is narcotics-related, according to Portuguese officials, who have noted significant criminal proceeds also come from corruption, trafficking in works of art and cultural artifacts, extortion, embezzlement, tax offenses, smuggling, prostitution, organized crime, gambling, and aiding or facilitating illegal immigration. Suspicious funds from Angola continue to be used to purchase Portuguese businesses and real estate. Portugal has taken steps to strengthen its AML legislation; new laws decrease the size of payments that can legally be made in cash, create a national registry of transaction recipients, and compel attorneys to report suspected money laundering activities to authorities. Portugal proposed laws relaxing bank secrecy and allowing tax inspectors access to information on bank accounts suspected of being affiliated with money laundering.'
    case 'Puerto Rico':
      return 'Puerto Rico had one of the most dynamic economies in the Caribbean region until 2006; however, growth has been negative for the past years. The downturn coincided with the phase out of tax preferences that had led US firms to invest heavily in the Commonwealth since the 1950s, and a steep rise in the price of oil, which generates most of the island\'s electricity. Closing the budget deficit while restoring economic growth and employment remain the central concerns of the government. The gap between revenues and expenditures narrowed, although analysts believe that not all expenditures have been accounted for in the budget and a better accounting of costs would yield an overall deficit of roughly 5% of GDP. Public debt rose to 105% of GDP, about $17,000 per person, or nearly three times the per capita debt of the State of Connecticut, the highest in the US. Much of that debt was issued by state-run schools and public corporations, including water and electric utilities.'
    case 'Qatar':
      return 'Qatar has become an increasingly important banking and financial services center in the Gulf region. Despite the growth of the banking sector and increasing options for financial services, Qatar still has a largely cash economy. The expansion of the financial and trade sectors, the large number of expatriate laborers who send remittances to their home countries, the liberalization and growth in the real estate sector, uneven corporate oversight, and Iran’s efforts to bypass sanctions through Gulf economies make Qatar increasingly vulnerable to the threat of money laundering. The exploitation of charities and private donations to finance terrorism continues to be a concern, as does the ability of individuals to bypass the formal financial sector for illicit financing.'
    case 'Reunion':
      return 'In the early 21st century, five French overseas entities - French Guiana, Guadeloupe, Martinique, Mayotte, and Reunion - became French regions and were made part of France proper. Reunion is located around the Southern Africa. It consists of an island in the Indian Ocean, east of Madagascar. The climate is tropical, but temperature moderates with elevation. There is are a dry season that is cooler (May to November) and a hot and rainy season (November to April). The island ismostly rugged and mountainous. However, there are fertile lowlands along coast.'
    case 'Romania':
      return 'Romania’s geographical location makes it a natural transit country for trafficking in narcotics, arms, stolen vehicles, and persons by transnational organized criminal groups. As a result, Romania is vulnerable to financial activities associated with such crimes, including money laundering. Romania’s economy remains to a large extent cash-based, and the size of the shadow economy is approximately 22 percent of GDP. Fiscal evasion and proceeds of crime generated in Romania are estimated to be approximately 15 percent of GDP. Though Romania is not a major financial hub and its exposure to foreign proceeds of crime may be limited, there are nevertheless indicators suggesting that organized criminal groups from the neighboring countries and Italy invest in Romanian assets. Romanian organized criminal groups participate in a wide range of criminal activities in Europe, including prostitution, cigarette smuggling, extortion, and trafficking in narcotics, and have collaborated to establish international criminal networks for internet fraud activities and related money laundering schemes. Romania has some of the highest rates of cybercrime and online credit card fraud in the world.'
    case 'Russia':
      return 'Russia has developed a robust AML/CFT legal framework with Rosfinmonitoring, the FIU, at its center. Corruption, misappropriation and embezzlement of public funds, tax evasion, fraud, and drug trafficking generate significant amounts of proceeds. There is a large shadow economy approaching 13 percent of Russian GDP, although cash in circulation has declined from 12.4 percent of GDP in 2007 to less than 10 percent in recent years, Financial flows from illicit activity linked to Russia have threatened weak financial institutions in neighboring countries; however, criminal proceeds from Russia also make their way to global financial centers, often through opaque shell companies. To shield sanctioned Russian individuals and entities from the effects of financial sanctions, the Russian government softened some reporting requirements leading to a decrease in transparency. Although many Russian banks controlled by criminal factions have been closed, supervision over remaining institutions, including a significant portion of stateowned banks, is not wholly risk-based or adequate from an AML standpoint. '
    case 'Rwanda':
      return 'Rwanda is not a major or offshore financial center. The Rwandan financial system remains relatively unsophisticated, although the number of electronic fund transfers and credit card transactions is rising. Money transfers by cell phone are common. While the black market for smuggled goods is limited, the smuggling of tin, tantalum, tungsten, and gold from neighboring Democratic Republic of Congo (DRC) generates funds that may be laundered through Rwanda’s financial system. The scope of this smuggling is difficult to quantify; it has been noted that continued smuggling of minerals from the DRC to Rwanda by way of Lake Kivu and the Ruzizi River. Rwanda is a rural country with about 90% of the population engaged in subsistence agriculture and some mineral and agro-processing. Tourism, minerals, coffee and tea are Rwanda\'s main sources of foreign exchange. Despite Rwanda\'s fertile ecosystem, food production often does not keep pace with demand, requiring food imports. Energy shortages, instability in neighbouring states, and lack of adequate transportation linkages to other countries continue to handicap private sector growth.'
    case 'Saint Barthelemy':
      return 'Discovered in 1493 by Christopher Columbus who named it for his brother Bartolomeo, Saint Barthelemy was first settled by the French in 1648. In 1784, the French sold the island to Sweden, which renamed the largest town Gustavia, after the Swedish King GUSTAV III, and made it a free port; the island prospered as a trade and supply center during the colonial wars of the 18th century. France repurchased the island in 1877 and took control the following year. It was placed under the administration of Guadeloupe. Saint Barthelemy retained its free port status along with various Swedish appellations such as Swedish street and town names, and the three-crown symbol on the coat of arms. In 2003, the islanders voted to secede from Guadeloupe, and in 2007, the island became a French overseas collectivity. In 2012, it became an overseas territory of the EU, allowing it to exert local control over the permanent and temporary immigration of foreign workers including non-French European citizens. The economy of Saint Barthelemy is based upon high-end tourism and duty-free luxury commerce, serving visitors primarily from North America.'
    case 'Saint Helena':
      return 'Uninhabited case t discovered by the Portuguese in 1502, Saint Helena was garrisoned by the British during the 17th century. It acquired fame as the place of Napoleon Bonaparte\'s exile from 1815 until his death in 1821, but its importance as a port of call declined after the opening of the Suez Canal in 1869. During the Anglo-Boer War in South Africa, several thousand Boer prisoners were confined on the island between 1900 and 1903. Saint Helena is one of the most remote populated places in the world. The British Government committed to building an airport on Saint Helena in 2005. After more than a decade of delays and construction, a commercial air service to South Africa via Namibia was inaugurated in October of 2017. The weekly service to Saint Helena from Johannesburg via Windhoek in Namibia takes just over six hours (including the refueling stop in Windhoek) and replaces the mail ship that had made a five-day journey to the island every three weeks.;'
    case 'Saint Kitts and Nevis':
      return 'St. Kitts and Nevis is a federation composed of two islands in the Eastern Caribbean. Its economy is reliant on tourism and its economic citizenship program, offshore financial services sector, corporate registries, and shipping registry. St. Kitts and Nevis is making progress in its AML regime. The Financial Services Regulatory Commission (FSRC) (St. Kitts branch) is responsible for the licensing, regulation, and supervision of the non-bank financial sector in St. Kitts. The FSRC (Nevis branch) is responsible for the licensing, regulation, and supervision of regulated persons and entities in Nevis that conduct fiduciary and international financial services businesses.'
    case 'Saint Lucia':
      return 'St. Lucia’s main sources of revenue are tourism and the offshore banking sector. St. Lucia is progressing with its AML regime. The island nation has been able to attract foreign business and investment, especially in its offshore banking and tourism industries. Tourism is Saint Lucia\'s main source of jobs and income - accounting for 65% of GDP - and the island\'s main source of foreign exchange earnings. The manufacturing sector is the most diverse in the Eastern Caribbean area. Crops such as bananas, mangos, and avocados continue to be grown for export, but St. Lucia\'s once solid banana industry has been devastated by strong competition.'
    case 'Saint Martin':
      return 'Although sighted by Christopher Columbus in 1493 and claimed for Spain, it was the Dutch who occupied the island in 1631 to exploit its salt deposits. The Spanish retook the island in 1633, but continued to be harassed by the Dutch. The Spanish finally relinquished Saint Martin to the French and Dutch, who divided it between themselves in 1648. Friction between the two sides caused the border to frequently fluctuate over the next two centuries, with the French eventually holding the greater portion of the island (about 61%). The cultivation of sugar cane introduced African slavery to the island in the late 18th century; the practice was not abolished until 1848. The island became a free port in 1939; the tourism industry was dramatically expanded during the 1970s and 1980s. In 2003, the populace of Saint Martin voted to secede from Guadeloupe and in 2007, the northern portion of the island became a French overseas collectivity. In 2010, the southern Dutch portion of the island became the independent nation of Sint Maarten within the Kingdom of the Netherlands. On 6 September 2017, Hurricane Irma passed over the island of Saint Martin causing extensive damage to roads, communications, electrical power, and housing; the UN estimated that 90% of the buildings were damaged or destroyed.'
    case 'Saint Pierre and Miquelon':
      return 'First settled by the French in the early 17th century, the islands represent the sole remaining vestige of France\'s once vast North American possessions. They attained the status of an overseas collectivity in 2003. The inhabitants have traditionally earned their livelihood by fishing and by servicing fishing fleets operating off the coast of Newfoundland. The economy has been declining, however, because of disputes with Canada over fishing quotas and a steady decline in the number of ships stopping at Saint Pierre. The services sector accounts for 86% of GDP. Government employment accounts for than 46% of the GDP, and 78% of the population is working age. The government hopes an expansion of tourism will boost economic prospects. Fish farming, crab fishing, and agriculture are being developed to diversify the local economy. Recent test drilling for oil may pave the way for development of the energy sector. Trade is the second largest sector in terms of value added created, where it contributes significantly to economic activity. The extractive industries and energy sector is the third largest sector of activity in the archipelago, attributable in part to the construction of a thermal power plant.'
    case 'Saint Vincent and the Grenadines':
      return 'St. Vincent and the Grenadines made some progress on its AML regime. The FIU has a good reputation in the Eastern Caribbean and cooperates regularly with the United States. In December 2017, the country began a national risk assessment. St. Vincent and the Grenadines’ economy is dependent on tourism and its offshore financial services sector. There are no FTZs or economic citizenship programs. Gaming is legal, but there are no casinos in operation. IBCs can be incorporated in less than 24 hours from receipt of application. The International Business Companies (Amendment and Consolidation) (Amendment) Act removes direct tax exemptions and stops further bearer share issuance by IBCs incorporated in the country. The Financial Services Authority (FSA) is the regulatory body with the mandate to supervise the offshore financial sector, and the SVGFIU is the supervisory authority for DNFBPs. '
    case 'Samoa':
      return 'The Independent State of Samoa is not known to have major organized crime, fraud, or drug problems and due to the small size of the local economy and the banking sector, Samoa has not become a haven for money laundering or terrorism financing. There is no significant evidence of large scale public corruption or black market activity. The most common financial crimes within the jurisdiction appear to be low-level fraud and theft. According to Samoan law enforcement, criminal organizations based in Hawaii and California are involved in the trafficking of cocaine and crystal methamphetamine into Pacific island nations, including Samoa. Additionally, South American and Australian-based organizations use the South Pacific islands as transshipment locations for cocaine being shipped from South America into Australia and New Zealand. Samoa is an offshore financial jurisdiction administered by the Samoa International Finance Authority (SIFA). For entities registered or licensed under the various Offshore Finance Centre acts, there are no currency or exchange controls, and no foreign exchange levies payable on foreign currency transactions.'
    case 'San Marino':
      return 'The Republic of San Marino is an extremely small country surrounded by Italy. Stricter monitoring regulations appear to have resulted in a decrease overall in financial crimes. Money laundering occurs in both the formal and informal financial sectors, and is primarily trade-based and perpetrated by foreigners to avoid higher taxes in their home countries. There are no free trade zones or casinos in San Marino, nor is there a significant market for illegal or smuggled goods. San Marino\'s economy relies heavily on tourism, banking, and the manufacture and export of ceramics, clothing, fabrics, furniture, paints, spirits, tiles, and wine. The manufacturing and financial sectors account for more than half of San Marino\'s GDP. The per capita level of output and standard of living are comparable to those of the most prosperous regions of Italy. Difficulties in the banking sector, the recent global economic downturn, and the sizable decline in tax revenues have contributed to negative real GDP growth.'
    case 'Sao Tome and Principe':
      return 'Sao Tome and Principe (STP) has a small banking sector and is not a regional financial center. The economy is almost entirely cash-based, though limited automated teller machine service is available. There is no evidence that significant money laundering or illicit financial activity linked to the drug trade, contraband smuggling, or terrorism occurs in STP. The country’s AML/CFT framework contains a number of strategic deficiencies. This small, poor island economy has become increasingly dependent on cocoa since independence in 1975. Cocoa production has substantially declined in recent years because of drought and mismanagement. Sao Tome and Principe has to import fuels, most manufactured goods, consumer goods, and food, making it vulnerable to fluctuations in global commodity prices. Maintaining control of inflation, fiscal discipline, and increasing flows of foreign direct investment into the oil sector are major economic problems facing the country. The government also has attempted to reduce price controls and subsidies. '
    case 'Saudi Arabia':
      return 'The Kingdom of Saudi Arabia is a rapidly expanding financial center in the Gulf region and the second largest source of remittances in the world. There is no indication of significant narcotics- related money laundering. Bulk cash smuggling and money transfers from individual donors and Saudi-based charities have reportedly been a significant source of financing for extremist and terrorist groups over the past 25 years. Despite serious and effective efforts to counter the funding of terrorism originating within the Kingdom, Saudi Arabia is still home to individuals and entities that continue to serve as sources of financial support for Sunni-based extremist groups. Funds are allegedly collected in secret and illicitly transferred out of the country in cash, often via pilgrims performing Hajj and Umrah. The government has responded in recent years and increased policing to counter this smuggling. Recent regional turmoil and sophisticated usage of social media have facilitated charities outside of Saudi Arabia with ties to extremists to solicit donations from Saudi donors.'
    case 'Senegal':
      return 'Senegal’s strategic coastal location makes it a regional business center for Francophone West Africa. Illicit proceeds are derived from both domestic and foreign crimes. According to the money laundering national risk assessment (NRA), Senegal is exposed to risks from organized crime, drug trafficking, internet and other fraud, and a large informal, cash-based sector. Major sources of illicit proceeds include narcotics, trafficking in persons, illegal trade in wildlife and timber, and public corruption. Senegal has drafted an AML/CFT strategy and action plan, which address the AML/CFT framework, DNFBP reporting obligations and supervision, procedural and sanctioning mechanisms, international cooperation, and AML/CFT metrics. The government also aims to expand financial inclusion. Open issues include training for law enforcement, prosecutors, and judges; lack of a nonconviction-based forfeiture law; awareness-raising programs for stakeholders; designation of a DNFBP supervisor; and improved legislation on the management and disposal of seized property.'
    case 'Serbia':
      return 'Serbia has a transitional economy largely dominated by market forces, but the state sector remains significant in certain areas and many institutional reforms are needed. The economy relies on manufacturing and exports, driven largely by foreign investment. High unemployment and stagnant household incomes are ongoing political and economic problems. Structural economic reforms needed to ensure the country\'s long-term prosperity have largely stalled since the onset of the global financial crisis. Growing budget deficits constrain the use of stimulus efforts to revive the economy and contribute to growing concern of a public debt crisis. Serbia\'s concerns about inflation and exchange-rate stability preclude the use of expansionary monetary policy. Major challenges ahead include: high unemployment rates and the need for job creation; high government expenditures for salaries, pensions, healthcare, and unemployment benefits; a growing need for new government borrowing; rising public and private foreign debt; attracting new foreign direct investment; and getting the IMF program back on track. Other serious longer-term challenges include an inefficient judicial system, high levels of corruption, and an aging population.'
    case 'Seychelles':
      return 'Seychelles is not a major financial center, although it aspires to be. The Seychellois authorities consider drug trafficking, parallel market operations, theft, and fraud to be the major sources of illegal proceeds. Corruption is also a problem. In the past, Seychelles was negatively affected by piracy off the coast of Somalia. Seychelles is a consumer country for narcotics. To diversify its economy beyond tourism and fisheries, and to increase foreign exchange earnings, the Government of Seychelles developed an offshore financial sector. It actively markets itself as an offshore financial and business center that allows the registration of nonresident business companies. The government is aware these activities increase the risk of money laundering. The government proposed to facilitate the further development of the financial services sector through active promotion of Seychelles as an offshore jurisdiction, with emphasis on international business companies (IBCs), mutual funds, special license companies, insurance companies, and private foundations.'
    case 'Sierra Leone':
      return 'Sierra Leone is not a regional financial center. Loose oversight of financial institutions, weak regulations, pervasive corruption, and lack of financial crimes enforcement has made the country vulnerable to money laundering. Due in part to its large seaport, Sierra Leone is an attractive trans-sea shipment point for illegal drugs and other forms of illegal commerce. Smuggling of pharmaceuticals, foodstuffs, gold, and diamonds occurs across porous land borders. There is little evidence drug smuggling is a significant source of laundered money. The small-scale artisanal diamond mining industry is exploited by domestic groups and individuals rather than by transnational cartels. The trade in stolen automobiles, many originating in the United States, continues to be a concern. Most financial transactions, including currency exchanges and remittances, are unregulated and vulnerable to money laundering. There is no indication money laundering activity in Sierra Leone is tied to the financing of terrorism.'
    case 'Singapore':
      return 'Singapore’s openness as an international financial, investment, and transport hub exposes it to money laundering and terrorist financing risks. The country’s position as the most stable and prominent financial center in South East Asia, coupled with a regional history of transnational organized crime, large-scale corruption in neighboring states, and a range of other predicate offenses in those states increase the risk that Singapore will be viewed as an attractive destination for criminals to launder their criminal proceeds. Limited large currency reporting requirements and the size and growth of Singapore’s private banking and asset management sectors also pose inherent risks. Among the types of illicit activity noted in the region are fund flows associated with illegal activity in Australia that transit Singapore financial service providers for other parts of Asia.'
    case 'Sint Maarten':
      return 'Sint Maarten is an autonomous entity within the Kingdom of the Netherlands (Kingdom). The Kingdom retains responsibility for foreign policy and defense, including entering into international conventions, with approval of the local parliament. Sint Maarten has been recognized by the OECD as a jurisdiction that has implemented internationally-agreed tax standards. The law enforcement MOU between the Kingdom and the United States for joint training activities and sharing of information in the area of criminal investigation, law enforcement, and interdicting money laundering operations remains active and includes Sint Maarten. The CFATF issued a public statement asking its members to consider the risks arising from the deficiencies in Sint Maarten’s AML/CFT regime. The statement followed CFATF’s acknowledgement that Sint Maarten had not made sufficient progress to fulfill its action plan to address AML deficiencies, including legislative reforms.'
    case 'Slovakia':
      return 'Criminal activity in the Slovak Republic (Slovakia) is characterized by a moderate to high level of domestic and foreign organized crime, mainly originating from eastern and southeastern Europe. Slovakia is a transit and destination country for counterfeit and smuggled goods, auto theft, value-added tax (VAT) fraud, and trafficking in persons, weapons, and illegal drugs. Many of the same organized crime groups are involved in laundering funds raised from these criminal activities. The Slovak Ministry of Interior’s Financial Intelligence Unit (FIU) has identified unusual transactions suspected of perpetrating tax fraud and evasion. A common tactic is the use of shell companies to execute complex commercial transactions through a system of “carousel trading” and, ultimately, to claim either unauthorized VAT refunds or evade tax payments. Trade-based money laundering and possible terrorist financing are concerns.'
    case 'Slovenia':
      return 'Slovenia is not a major drug producer, but it is a transit country for drugs moving via the Balkan route to Western Europe. The Government of Slovenia is aware that Slovenia’s geographic position makes it an attractive potential transit country for drug smugglers, and it continues to pursue active counter-narcotics policies. Other predicate offenses of concern include business and tax fraud. There are continuing efforts to continue with the privatization process. With excellent infrastructure, a well-educated work force, and a strategic location between the Balkans and Western Europe, Slovenia has one of the highest per capita GDPs in Central Europe, despite having suffered a protracted recession in 2008-2009 in the wake of the global financial crisis. Slovenia became the first 2004 EU entrant to adopt the euro (on 1 January 2007) and has experienced one of the most stable political transitions in Central and South-eastern Europe. In March 2004, Slovenia became the first transition country to graduate from borrower status to donor partner at the World Bank.'
    case 'Solomon Islands':
      return 'Solomon Islands is not considered a major financial center. It has a relatively stable banking system closely integrated with the financial systems of Australia and New Zealand. In general, the risk of money laundering and terrorism financing in the Solomon Islands is very low given the country’s isolated geographic location and very small community, which preclude anonymity. Corruption and fraud by government employees continues to be the main source of illegal proceeds. Smuggling, environmental crimes, and the proliferation of counterfeit goods also are problems in the country. Historically, money laundering is often associated with fraud, illegal logging and fishing, and robbery. Foreign destinations for the laundered proceeds include China, Australia, Malaysia, and Singapore. The SIFIU suspects Asian logging vessels (particularly Malaysian) bring counterfeit currency into the Solomon Islands to finance forestry operations. Customs fraud and tax evasion are also common. The bulk of the population depends on agriculture, fishing, and forestry for at least part of its livelihood. Most manufactured goods and petroleum products must be imported.'
    case 'Somalia':
      return 'Somalia’s financial system is informal, operating mostly outside of government oversight, either via the black market or unsupervised money remittance firms (hawaladars). An estimated $1.3 billion in remittances is sent to Somalia every year, primarily by the Somali diaspora that fled the country during two decades of conflict. That amount is roughly one quarter of Somalia’s gross domestic product, eclipsing all international aid to the country. Most remittances are routed through financial centers in the Gulf. The World Bank estimates 40 percent of all Somalis depend on remittances for their basic needs. With its long land borders and extensive coastline, the smuggling of currency and goods into and out of Somalia remains common, due mainly to customs and border security officials’ lack of capacity to control points of entry. The UN Security Council reports piracy has declined significantly, with no large commercial vessels hijacked or held for ransom by Somali pirates in the last two years, resulting in a decrease of ransom payments.'
    case 'South Africa':
      return 'South Africa’s position as the financial center of the continent, its sophisticated banking and financial sector with a high volume of transactions, and its large, cash-based market make it a target for transnational and domestic crime syndicates. The Financial Intelligence Centre (FIC), South Africa’s FIU, works closely with other governmental organizations on AML enforcement. The Illicit Financial Flows Task Team (FTT), composed of six agencies, including a U.S. law enforcement representative, coordinates a national approach to investigate and prosecute money laundering activities. President Zuma recently signed an amendment to the Financial Intelligence Centre Act, and the FIC, in collaboration with the National Treasury, the South African Reserve Bank (SARB), and the Financial Services Board, published guidance to implement the new law. South Africa is a middle-income emerging market with an abundant supply of natural resources; well-developed financial, legal, communications, energy, and transport sectors; and a stock exchange that is Africa’s largest and among the top 20 in the world.'
    case 'South Georgia and the South Sandwich Islands':
      return 'The islands, with large bird and seal populations, lie approximately 1,000 km east of the Falkland Islands and have been under British administration since 1908 - except for a brief period in 1982 case ntina occupied them. Grytviken, on South Georgia, was a 19th and early 20th century whaling station. Famed explorer Ernest SHACKLETON stopped there in 1914 en route to his ill-fated attempt to cross Antarctica on foot. He returned some 20 months later with a few companions in a small boat and arranged a successful rescue for the rest of his crew, stranded off the Antarctic Peninsula. He died in 1922 on a subsequent expedition and is buried in Grytviken. Today, the station houses scientists from the British Antarctic Survey. Recognizing the importance of preserving the marine stocks in adjacent waters, the UK, in 1993, extended the exclusive fishing zone from 12 nm to 200 nm around each island. Some fishing takes place in adjacent waters. Harvesting finfish and krill are potential sources of income. The islands receive income from postage stamps produced in the UK, the sale of fishing licenses, and harbor and landing fees from tourist vessels. Tourism from specialized cruise ships is increasing rapidly.'
    case 'South Sudan':
      return 'While the Republic of South Sudan had begun to develop prior to the outbreak of civil conflict, much remains to be accomplished in this fledgling state. The country has a cash-based economy, with a small, poorly developed financial system. Corruption and the flow of illicit funds; the offshoring of assets by elites; large-scale abuse and mismanagement of the extractives industry, particularly oil; financial and trade-based fraud; the convergence of licit and illicit systems; disguised beneficial ownership; and regulatory evasion have all combined to create a kleptocratic governing system. Lacking an AML/CFT regime and possessing long, porous borders, South Sudan is vulnerable to exploitation by criminals of every type, including those seeking overland routes for bulk cash smuggling, those financing terrorist activities, and those wishing to perpetrate other forms of financial crime. Reports of money laundering by Somali nationals through foreign exchange bureaus in South Sudan are persistent, though unconfirmed.'
    case 'Spain':
      return 'Spain proactively identifies, assesses, and understands its money laundering vulnerabilities and works to mitigate risks. Organized crime groups based in Africa, Latin America, and the former Soviet Union use Spain as a logistical hotspot. Illicit drugs entering Europe from North Africa and South America use Spain as a transshipment point. Spain largely complies with international AML standards and, in general, has updated AML regulations and competent authorities. The government continues to build on its already strong measures to combat money laundering. Spain has approved measures to modify its money laundering legislation to comply with the EU’s Fourth AML Directive. Recently, Spain joined five other EU member states to call for the establishment of a new supervisory authority to lead the bloc’s AML efforts as well as updated AML regulations.'
    case 'Sri Lanka':
      return 'Sri Lanka is neither an important regional financial center nor a preferred center for money laundering  Nevertheless, the lack of transparent tender mechanisms in government projects, past experience with terrorism, tax evasion, and a large informal economy make the country vulnerable to money laundering and terrorism financing. Corruption and drug-related proceeds pose the highest money laundering risks. Local authorities report that drug trafficking, primarily of heroin, is becoming an increasing problem. Terrorism financing activity, by all accounts, has diminished significantly since the end of Sri Lanka’s civil war in 2009. As a major transshipment port, Sri Lanka receives 70 percent of all vessels sailing to and from South Asia, exposing Sri Lanka to associated drug and human trafficking. Authorities believe the proceeds of drug trafficking are mostly laundered back to their source jurisdictions, and those for human smuggling, to end destinations or transit points. Overall, Sri Lanka is not considered an end destination for foreign proceeds of crime.'
    case 'Sudan':
      return 'Sudan has been designated a State Sponsor of Terrorism by the United States. In November 1997, the United States imposed comprehensive economic, trade, and financial sanctions against Sudan, which have limited Sudan’s access to international financial markets and banking institutions. Following the Treasury Department’s fine and penalties on BNP Paribas, most banks in Saudi Arabia, the Arab Gulf states, and Europe ceased processing financial transactions from Sudan or with Sudanese banks. The trafficking of narcotics is a source of concern, especially with the increase of smuggling operations across the extended land and sea borders of Sudan. Traders and legitimate business persons often carry large sums of cash because Sudan is largely a cash-based society and electronic transfer of money outside of Sudan is challenging. This dependence on cash complicates enforcement and makes Sudan’s banking system vulnerable to money laundering. Sudan is vulnerable to trade-based money laundering. Corruption is widespread in government and commerce and facilitates criminal activity and money laundering.'
    case 'Suriname':
      return 'Money laundering in Suriname is linked with transnational criminal activity related to the transshipment of cocaine, primarily to Europe and Africa. Casinos, real estate, foreign exchange companies, car dealerships, and the construction sector remain vulnerable to money laundering due to lax enforcement of regulations, though Suriname’s FIU has increased its engagement with DNFBPs. Public corruption also contributes to money laundering. Profits from small-scale gold mining and related industries fuel a thriving informal sector. Much of the money within this sector does not pass through the formal banking system. In Suriname’s undeveloped interior, bartering with gold is the norm. The economy is dominated by the mining industry, with exports of oil, gold, and alumina accounting for about 85% of exports and 27% of government revenues, making the economy highly vulnerable to mineral price volatility.'
    case 'Svalbard and Jan Mayen Islands':
      return 'The archipelago may have been first discovered by Norse explorers in the 12th century; the islands served as an international whaling base during the 17th and 18th centuries. Norway\'s sovereignty was internationally recognized by treaty in 1920, and five years later it officially took over the territory. In the 20th century coal mining started and today a Norwegian and a Russian company are still functioning. Travel between the settlements is accomplished with snowmobiles, aircraft, and boats. Coal mining, tourism, and international research are Svalbard\'s major industries. Coal mining has historically been the dominant economic activity, and the Spitzbergen Treaty of 9 February 1920 gives the 45 countries that so far have ratified the treaty equal rights to exploit mineral deposits, subject to Norwegian regulation. Although US, UK, Dutch, and Swedish coal companies have mined in the past, the only companies still engaging in this are Norwegian and Russian. Since the 1990s, the tourism and hospitality industry has grown rapidly, and Svalbard now receives 60,000 visitors annually.'
    case 'Swaziland':
      return 'The Kingdom of Swaziland is not considered a regional financial center. The financial sector in the Kingdom is small and dominated by subsidiaries of South African financial institutions. The small size of the country, the limited capacity of its police and financial regulators, and its proximity to major cities in Mozambique and South Africa make it a transit country for illegal operations in those countries and, to some extent, for the rest of the southern African region. Large sums of money are moved via cross-border transactions involving banks, casinos, investment companies, motor vehicle dealers, and savings and credit cooperatives. Proceeds from the sale or trade of marijuana, a large illicit export, are laundered in Swaziland. Income from public corruption, particularly in public procurement, is also laundered in Swaziland. Cash gained from illegal activities is sometimes used to buy commercial goods and to build houses on non-titled land. There is a significant black market for smuggled consumer goods, such as cigarettes, liquor, and pirated CDs and DVDs, transited across the porous borders of Mozambique, South Africa, and Swaziland. There is a general belief that trade-based money laundering and value transfer exists in Swaziland.'
    case 'Sweden':
      return 'Sweden has achieved an enviable standard of living with its combination of free-market capitalism and extensive welfare benefits. Sweden remains outside the euro zone largely out of concern that joining the European Economic and Monetary Union would diminish the country’s sovereignty over its welfare system. Timber, hydropower, and iron ore constitute the resource base of an economy heavily oriented toward foreign trade. Economic growth slowed, as a result of continued economic weakness in Sweden’s European trading partners; Sweden’s economy experienced modest growth after this, with real GDP growth above 2%, but continues to struggle with deflationary pressure.'
    case 'Switzerland':
      return 'Switzerland is a major international financial center where illicit financial activity occurs. Historically, foreign narcotics trafficking organizations, often based in Russia, the Balkans, and Eastern Europe, have dominated attempts at narcotics-related money laundering operations in Switzerland. Switzerland has made progress in implementing KYC procedures in its financial industry, but needs to further improve oversight over actors that are vulnerable to money laundering, as well as over new players in the financial industry. The most recent Swiss government statement of intent of June 2017 acknowledges the need to increase the scope of AML measures to all relevant actors and to strengthen the Swiss regime against criminal activity. Switzerland, a country that espouses neutrality, is a prosperous and modern market economy with low unemployment, a highly skilled labour force, and a per capita GDP among the highest in the world. Switzerland\'s economy benefits from a highly developed service sector, led by financial services, and a manufacturing industry that specializes in high-technology, knowledge-based production.'
    case 'Syria':
      return 'A lack of control and authority by the Syrian regime over significant parts of the country, a lack of necessary legislation and poor enforcement of existing laws contribute to significant money laundering and terrorist financing vulnerabilities in Syria’s banking and non-bank financial sectors. Estimates of the volume of business Syrian money changers conduct in the black market range between $15 and $70 million per day. Syria’s borders are porous, most official border crossings are outside the control of the regime, and several are currently controlled by armed opposition groups, including the Kurdish People’s Protection Units (YPG), as well as the Islamic State of Iraq and the Levant (ISIL), a designated terrorist organization. Furthermore, regional hawala networks, intertwined with smuggling and trade-based money laundering, raise significant concerns, including involvement in the financing of terrorism. The unprecedented volume of participants making payments for illegal services to human traffickers, human smugglers, and document traffickers, including both fraudulent and genuine Syrian passports, entail enormous sums of money.'
    case 'Taiwan':
      return 'Taiwan’s modern financial sector, strategic location within the Asia-Pacific international shipping lanes, expertise in high-technology production, and role as an international trade hub make it vulnerable to transnational crimes, including money laundering, drug trafficking, telecom fraud, and trade fraud. Domestic money laundering is generally related to tax evasion, drug trafficking, public corruption, and a range of economic crimes. Official channels exist to remit funds, which greatly reduce the demand for unofficial remittance systems; however, although illegal in Taiwan, a large volume of informal financial activity takes place through unregulated, and possibly organized crime-linked, non-bank channels. Taiwan has a dynamic capitalist economy with gradually decreasing government guidance on investment and foreign trade. Exports, led by electronics, machinery, and petrochemicals have provided the primary impetus for economic development. This heavy dependence on exports exposes the economy to fluctuations in world demand. Taiwan\'s diplomatic isolation, low birth rate, and rapidly aging population are other major long-term challenges.​'
    case 'Tajikistan':
      return 'Money laundering associated with Tajikistan’s drug trade remains a particular concern. Tajikistan lies on a major drug smuggling route connecting Afghanistan with Russian and Eastern European markets. In addition, a substantial amount of cash entering financial institutions in the country stems from pervasive corruption in Tajikistan, including bribes obtained from the drug trade. Tajikistan is a poor, mountainous country with an economy dominated by minerals extraction, metals processing, agriculture, and reliance on remittances from citizens working abroad. The 1992-97 civil war severely damaged an already weak economic infrastructure and caused a sharp decline in industrial and agricultural production, and today, Tajikistan has one of the lowest per capita GDPs among the 15 former Soviet republics. Less than 7% of the land area is arable and cotton is the most important crop. Tajikistan imports approximately 60% of its food. Mineral resources include silver, gold, uranium, antimony, and tungsten. Industry consists mainly of small obsolete factories in food processing and light industry, substantial hydropower facilities, and a large aluminium plant - currently operating well below its capacity.'
    case 'Tanzania':
      return 'Tanzania is vulnerable to money laundering and financial crimes due to its underdeveloped financial sector and limited capacity to address such criminal activity. Money laundering laws are used as political tools, which dilutes their efficacy in combating crime. Criminal activities with nexuses to money laundering include transnational organized crime, tax evasion, corruption, smuggling, trade invoice manipulation, illicit trade in drugs and counterfeit goods, and wildlife trafficking. There are Tanzanian links to regional terrorist financing. Recently, Tanzania updated its AML regulations to include stricter KYC requirements and requirements for reporting entities to carry out money laundering and terrorist financing risk assessments and enhanced due diligence. Tanzania’s central bank, the Bank of Tanzania, fined five commercial banks over $800,000 for noncompliance with these regulations. Despite high-profile arrests for money laundering, there are very few convictions. '
    case 'Thailand':
      return 'Thailand is emerging as a logistics and financial hub within Southeast Asia  The country’s porous borders and uneven law enforcement make it vulnerable to money laundering, drug trafficking, and other categories of transnational crime. Thailand is a source, transit, and destination country for illicit smuggling, trafficking in persons, a production and distribution center for counterfeit consumer goods, and a center for the production and sale of fraudulent travel documents. The proceeds of illegal gaming, official corruption, underground lotteries, and prostitution are laundered through the country’s informal financial channels.'
    case 'Timor-Leste':
      return 'Timor-Leste is a small economy, with limited data available regarding illicit funds and limited awareness, even by stakeholders, of money laundering issues. The most prevalent source of illicit proceeds is corruption, which a recent assessment described as “endemic” in the public sector. Capacity is low in government entities that supervise, enforce, and investigate suspicious financial transactions. The government has committed to increasing that capacity, as well as increasing awareness among the public and private sectors. A few years ago, Timor-Leste published its first National Risk Assessment of Money Laundering and Terrorist Financing (NRA) and adopted a National Action Plan (NAP) to address the areas of concern identified in the NRA.'
    case 'Togo':
      return 'Togo’s porous borders, susceptibility to corruption, and large informal sector make it vulnerable to illicit transshipments and small-scale money laundering. Most narcotics passing through Togo are destined for European markets. Drug and wildlife trafficking, trafficking in persons, corruption, misappropriation of funds, tax evasion, and smuggling are the major crimes in Togo. The country is used as a transit point for contraband ivory smuggling. The country’s small financial infrastructure, dominated by regional banks, makes it a less attractive venue for money laundering through financial institutions. This small, sub-Saharan economy depends heavily on both commercial and subsistence agriculture, which provides employment for a significant share of the labour force. Some basic foodstuffs must still be imported. Cocoa, coffee, and cotton generate about 40% of export earnings with cotton being the most important cash crop. Togo is among the world\'s largest producers of phosphate and seeks to develop its carbonate phosphate reserves.'
    case 'Tokelau':
      return 'Originally settled by Polynesian emigrants from surrounding island groups, the Tokelau Islands were made a British protectorate in 1889. They were transferred to New Zealand administration in 1925. Referenda held in 2006 and 2007 to change the status of the islands from that of a New Zealand territory to one of free association with New Zealand did not meet the needed threshold for approval. Tokelau\'s small size (three villages), isolation, and lack of resources greatly restrain economic development and confine agriculture to the subsistence level. The principal sources of revenue are from sales of copra, postage stamps, souvenir coins, and handicrafts. Money is also remitted to families from relatives in New Zealand. The people rely heavily on aid from New Zealand - about $15 million annually - to maintain public services. New Zealand\'s support amounts to 80% of Tokelau\'s recurrent government budget. An international trust fund, currently worth nearly $32 million, was established in 2004 by New Zealand to provide Tokelau an independent source of revenue.'
    case 'Tonga':
      return 'Tonga is an archipelago located in the South Pacific. With only five commercial banks, Tonga is neither a financial center nor an offshore jurisdiction. Remittances from Tongans living and working abroad have remained high, despite a slight decline in recent months, and remain the largest source of hard currency earnings, followed by tourism. Historically, Tonga has not been a major narcotics transit point. There are, however, unconfirmed allegations that Tongan citizens may have links to transnational drug cartels, but the scale of this is unknown. Local police authorities deem Tonga to be vulnerable to smuggling and money laundering due to inadequate and under-resourced border controls. Tonga has a small, open, island economy and is the last constitutional monarchy among the Pacific Island countries. It has a narrow export base in agricultural goods. Squash, vanilla beans, and yams are the main crops. Agricultural exports, including fish, make up two-thirds of total exports. Tourism is the second-largest source of hard currency earnings following remittances. The country must import a high proportion of its food, mainly from New Zealand.'
    case 'Trinidad and Tobago':
      return 'Trinidad and Tobago’s geographic location in the Southern Caribbean, developed financial systems, and its use by criminal organizations as a transshipment point for narcotics and other illicit goods make it vulnerable to money laundering. In recent years, Trinidad and Tobago developed an action plan to address deficiencies in its AML regime noted by international experts. Trinidad and Tobago continued to make progress on this action plan. Trinidad and Tobago continued efforts to strengthen its legislative, law enforcement, and judicial capacities to enforce its AML regime. As a result, four persons were convicted or pled guilty for money laundering offenses after many years without a successful prosecution. However, vulnerabilities related to the country’s slow judicial system, prevalence of drug trafficking, corruption, and illegal gaming are reasons for concern. Sustained political will, continued legislative and institutional reforms, including full implementation of laws and regulations, and adequate resources to detect, investigate, and prosecute money laundering-related offenses, are needed to ensure the proper enforcement of Trinidad and Tobago’s AML regime.'
    case 'Tunisia':
      return 'Tunisia is not considered a regional financial center. Tunisia has strict currency exchange controls, which authorities believe mitigate the risk of international money laundering. The primary domestic criminal activities that generate laundered funds are clandestine immigration, trafficking in stolen vehicles, and narcotics. Weapons, narcotics, and suspect cash have been seized in many Tunisian cities, some of which are near the borders with Libya or Algeria. Reports of corruption and financial crimes have been increasing since the 2011 revolution. The smuggling of weapons and contraband through Tunisia is used to support terrorist groups, including al-Qaida in the Islamic Maghreb. Money laundering occurs through the financial sector, especially through informal economic activity involving smuggled goods. Since Tunisia has strict currency controls, it is likely that underground remittance systems such as hawala are prevalent. Trade-based money laundering is also a concern. Throughout the region, invoice manipulation and customs fraud are often involved in hawala counter-valuation.'
    case 'Turkey':
      return 'Turkey’s strategic location between Europe and Asia, its significant trade with both continents and with the United States, and its commercial relationships and geographical proximity to politically turbulent and undemocratic countries make Turkey vulnerable to illicit finance risks. Recent conflicts on Turkey’s southern border have also increased illicit finance risks. Turkey is a hub for licensed and unlicensed money remitters, many of which serve the approximately 4 million refugees in Turkey. To confront illicit finance risks, Turkey’s criminalization of money laundering and its legal authority for asset forfeiture are in line with international standards; however, Turkey continues to have few effective money laundering prosecutions and forfeiture actions. Most forfeiture is focused on a group that poses a political threat to the ruling party.'
    case 'Turkmenistan':
      return 'Turkmenistan is not a regional financial center. There are five international banks and a small, underdeveloped domestic financial sector. The largest state banks include the State Bank for Foreign Economic Relations, Dayhanbank, Turkmenbashy Bank, Turkmenistan Bank, and Halk Bank. There are two smaller state banks, Senagat Bank, which provides general banking services, and Rysgal Bank, which was created by the Union of Entrepreneurs and Industrialists for its members. There are also five foreign commercial banks: a joint Turkmen-Turkish bank, a branch of the National Bank of Pakistan, the German Deutsche Bank and Commerzbank, and Saderat Bank of Iran. The two German banks provide European bank guarantees for companies and the Government of Turkmenistan; they do not provide general banking services. The country’s significant mineral and hydrocarbon exports are paid for through offshore accounts with little public scrutiny or accounting. Since the government introduced numerous limitations on foreign currency exchange, converting local currency (manat) into foreign currency has become very difficult. '
    case 'Turks and Caicos Islands':
      return 'The Turks and Caicos Islands is a British Overseas Territory. The economy depends greatly on tourism and the well-developed financial sector. The Turks and Caicos Islands is vulnerable to money laundering due to its significant offshore financial services sector and deficiencies in its AML/CFT regime. Corruption is a problem, and the country’s geographic location makes it a transshipment point for narcotics traffickers. The former Premier of Turks and Caicos and several members of his cabinet are currently on trial for corruption and money laundering. According to press reports, the former Premier and ministers allegedly misappropriated over $20 million between 2003 and 2009. According to the Turks and Caicos Financial Services Commission’s (TCIFSC) website, there are six licensed banks, four licensed money transmitters, 10 licensed trust companies, six international insurance managers, and 18 domestic insurance companies. The TCIFSC, an independent statutory body, is tasked with supervising the financial services sector, including the offshore sector, and is responsible for the oversight of company formation and registration.'
    case 'Tuvalu':
      return 'Tuvalu consists of a densely populated, scattered group of nine coral atolls with poor soil. Only eight of the atolls are inhabited. It is one of the smallest countries in the world, with its highest point at 4.6 meters above sea level. The country is isolated, almost entirely dependent on imports, particularly of food and fuel, and vulnerable to climate change and rising sea levels, which pose significant challenges to development. The public sector dominates economic activity. Tuvalu has few natural resources, except for its fisheries. Earnings from fish exports and fishing licenses for Tuvalu’s territorial waters are a significant source of government revenue. Recently, revenues from fishing licenses doubled and totalled more than 45% of GDP. Official aid from foreign development partners has also increased. Tuvalu has substantial assets abroad. The Tuvalu Trust Fund, an international trust fund established in 1987 by development partners, has grown to $141 million and is an important cushion for meeting shortfalls in the government\'s budget. While remittances are another substantial source of income, the value of remittances has declined since the 2008-2009 global financial crisis. Growing income inequality is one of many concerns for the nation.'
    case 'Uganda':
      return 'Uganda’s banking and financial sectors are growing in size and sophistication. The country has a total of 25 commercial banks, 84 percent of which are foreign-owned, and more than 300 non- bank financial institutions. Only 20 percent of Ugandans have deposits in the formal banking sector, with the rest of the populace relying on cash transactions or alternative forms of banking. Annual remittances are one of Uganda’s largest sources of foreign currency. Uganda’s cash-based informal economy provides a fertile environment for money laundering. Its lack of intellectual property rights legislation feeds a large black market for smuggled and/or counterfeit goods. Currently, most laundered money comes from domestic proceeds, much of which stems from unchecked corruption. Real estate and casino operations are of particular concern. Uganda’s inability to monitor formal and informal financial transactions, particularly informal trade along porous borders with South Sudan, Kenya, Tanzania, and the Democratic Republic of Congo, could render Uganda vulnerable to more advanced money laundering activities and potential terrorist financing.'
    case 'Ukraine':
      return 'Money laundering remains a significant problem in Ukraine. The authorities made little progress recently, though the passage of new AML legislation is pending. Corruption is the primary source of laundered funds. Launderers register as ultimate beneficial owners under aliases and integrate laundered money into legal businesses. Ineffective state institutions and an ineffective criminal justice system continue to allow criminal proceeds to go undetected. Although authorities are aware of the seriousness of the problem and are implementing measures to address it, law enforcement rarely targets large-scale corruptionrelated money laundering. The ongoing major reform of the Ukrainian prosecution authority appears to be a major step toward effective criminal enforcement. While the National Anti-corruption Bureau (NABU), working with the Office of the Prosecutor General (OPG) and Specialized Anti-corruption Prosecution Office (SAPO), has made significant progress in pursuing cases against high-ranking officials in a relatively short period of time, the newly-established High Anti-Corruption Court is only beginning to consider the cases.​'
    case 'United Arab Emirates':
      return 'The United Arab Emirates (UAE) is a regional hub for trade and financial activity that has aggressively expanded its financial services business. Illicit actors may take advantage of the open business environment, multitude of global banks, exchange houses, and global transportation links to engage in unlawful financial activity. Additionally, the several overlapping and distinct jurisdictional regimes for supervision and enforcement across the seven Emirates, federal system, and commercial and financial free zones create exposure to regulatory arbitrage. In recent years, the government has taken some steps to enhance its AML/CFT program. However, the relevant authorities need to streamline internal mechanisms to improve the interagency decision-making process. Additionally, the UAE should work to enhance efforts to investigate money laundering and terrorist financing and take proactive steps to implement and enforce its laws. '
    case 'United Kingdom':
      return 'The UK plays a leading role in European and world finance and remains one of the strongest global actors in combatting illicit finance. Money laundering presents a significant risk to the UK because of the size, sophistication, and reputation of its financial and real estate markets. UK law enforcement combats cash-based money laundering, the drug trade, and high-end money laundering through the financial sector and professional services. The UK has published its Economic Crime Plan, which describes public and private sector actions over the next three years to protect the UK against economic crime. The UK should follow through on plans to strengthen the capabilities of the FIU, reduce inconsistencies in the supervisory regime, and increase its international reach to tackle money laundering.'
    case 'United States':
      return 'The United States has a mature legal and institutional AML/CFT framework, having first criminalized money laundering in 1986. The framework covers most requirements of the FATF recommendations. There are many agencies involved in combating ML and FT at both federal and state levels encompassing regulatory, law enforcement, prosecutorial, and other roles. The United States is a founding member of the FATF. The FATF assessment in 2006 found that the United States had implemented an AML/CFT system that was broadly in line with the international standard. It had significantly strengthened its AML/CFT regime since the previous mutual evaluation (June 1997), including through enhanced legislation, subjecting most deposit-taking institutions to the full range of AML/CFT requirements, aggressive law enforcement action, and good cooperation domestically and internationally. However, shortcomings were identified, notably in relation to some specific requirements for undertaking customer due diligence (CDD), the availability of corporate ownership information, and the requirements applicable to certain designated non-financial businesses and professions (DNFBPs).'
    case 'United States Minor Outlaying Islands':
      return 'All of the following US Pacific island territories except Midway Atoll constitute the Pacific Remote Islands National Wildlife Refuge (NWR) Complex and as such are managed by the Fish and Wildlife Service of the US Department of the Interior. Midway Atoll NWR has been included in a Refuge Complex with the Hawaiian Islands NWR and also designated as part of Papahanaumokuakea Marine National Monument. These remote refuges are the most widespread collection of marine- and terrestrial-life protected areas on the planet under a single country\'s jurisdiction. They sustain many endemic species including corals, fish, shellfish, marine mammals, seabirds, water birds, land birds, insects, and vegetation not found elsewhere. The islands consist of Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Islands, and Palmyra Atoll.'
    case 'Uruguay':
      return 'Uruguay uses the U.S. dollar, often as a business currency; about 75 percent of deposits and 55 percent of credits are denominated in U.S. dollars. Laundered criminal proceeds are derived primarily from foreign activities related to drug trafficking. Local drug dealers participate in a range of other illicit activities, including violent crimes. Law enforcement officials and the judiciary assess that Colombian, Italian, Mexican, Paraguayan, and Russian criminal organizations operate locally. Officials are concerned about growing transnational organized crime originating from Brazil and Peru. Uruguay continues to make progress combating money laundering by passing new legislation, enforcing laws, and strengthening relevant regulatory agencies. The result is an overall improvement in fiscal transparency and international cooperation. Uruguay transitioned from the inquisitorial legal system to the accusatorial penal code system, a change the government believes will help advance AML/CFT investigations. Uruguay needs to increase transparency regarding non-financial entities, improve its AML system of analysis, provide for criminal liability for legal persons, and improve the management and disposition of seized assets and funds.'
    case 'Uzbekistan':
      return 'The effectiveness of Uzbekistan’s efforts to comply with international AML/CFT standards is hindered by corruption, the susceptibility of law enforcement to political influence, and the ease with which well-connected individuals bypass existing AML regulations. The government has made some improvements in legislation, but its refusal to share data regarding prosecutions makes judging progress difficult, with the best guess that the overall trend is largely one of staying in place. Key recommendations include increasing transparency regarding AML/CFT data and statistics, reducing turnover in key technical positions, improving implementation and enforcement of laws related to public disclosure, and lowering the barriers to working-level cooperation with international partners.'
    case 'Vanuatu':
      return 'Vanuatu has an agricultural and tourism-based economy; it is closely tied to the economies of Australia and New Zealand. Vanuatu is an offshore financial sector with a relatively small number of licensed banks, totaling five domestic and eight international banks. Vanuatu is known for strict secrecy provisions that have prevented law enforcement agencies and regulators from obtaining the beneficial owner information of entities registered in the offshore sector, except under a court order. As a result, legal entities (companies) and arrangements formed in Vanuatu face criminal misuse and are vulnerable to money laundering. Vanuatu’s money laundering threats are primarily from foreign predicate offenses (including foreign tax crimes), illicit cross-border currency movements, domestic bribery and corruption, fraud (particularly value added tax (VAT) evasion), and drug offenses. High-risk sectors include remittances and currency exchange businesses, trust and company service providers, casinos, and interactive gaming businesses. Political instability, with frequent government leadership changes, has an impact on high level political commitment to establish and maintain an efficient and effective AML/CFT system.'
    case 'Venezuela':
      return 'Venezuela is characterized by rampant illicit financial activity and endemic public corruption. The situation continued to worsen, particularly as the illegitimate regime refused to cede power to Interim President Juan Guaidó. Nicolás Maduro and his regime rely on illicit activities – money laundering, drug trafficking, illegal mining, fraud, and public corruption – to fund their illegitimate rule. Venezuela’s proximity to drug-producing countries and its status as a significant drug transit country, combined with practically nonexistent AML supervision, enforcement, and international cooperation, make for a jurisdiction riddled with money laundering and financial crimes. In response to the deep economic crisis caused by its mismanagement, the regime relaxed foreign exchange controls, as well as price and import controls, leading to the rapid dollarization of the country and availability of goods for those who have dollars. However, the economy remains deeply unstable and hyperinflated, which is driving a complex humanitarian crisis. A robust black market continues to function in the porous border regions of Venezuela and Colombia, and to some extent Brazil, with the smuggling of gasoline.'
    case 'Vietnam':
      return 'Vietnam has made some progress in reducing the risks of money laundering over the last year. This includes new revisions to the penal code and increased international cooperation. However, impressive economic growth; increased international trade; long, porous borders; inadequate customs enforcement; and several newly-licensed casinos all suggest Vietnam’s exposure to illicit finance will increase. Vietnam needs to continue to develop overall AML capabilities, especially within key enforcement ministries, the State Bank of Vietnam (SBV), and the National AML Steering Committee. Vietnam will need political will and better coordination within the government to improve enforcement of existing AML laws. '
    case 'Virgin Islands, British':
      return 'First inhabited by Arawak and later by Carib Indians, the Virgin Islands were settled by the Dutch in 1648 and then annexed by the English in 1672. The islands were part of the British colony of the Leeward Islands from 1872-1960; they were granted autonomy in 1967. The economy is closely tied to the larger and more populous US Virgin Islands to the west; the US dollar is the legal currency. The economy, one of the most stable and prosperous in the Caribbean, is highly dependent on tourism, which generates an estimated 45% of the national income. More than 934,000 tourists, mainly from the US, visited the islands annually. Because of traditionally close links with the US Virgin Islands, the British Virgin Islands has used the US dollar as its currency since 1959. Livestock raising is the most important agricultural activity; poor soils limit the islands\' ability to meet domestic food requirements.'
    case 'Virgin Islands, U.S.':
      return 'Tourism, trade, and other services are the primary economic activities, accounting for nearly 60% of the Virgin Island\'s GDP and about half of total civilian employment. The islands host nearly 3 million tourists per year, mostly from visiting cruise ships. The islands are vulnerable to damage from storms. The agriculture sector is small, with most food being imported. Industry and government each account for about one-fifth of GDP. The manufacturing sector consists of rum distilling, electronics, pharmaceuticals, and watch assembly. A refinery on St. Croix, one of the world’s largest, processed 350,000 barrels of crude oil a day until it was shut down, after operating for 45 years. Federal programs and grants, totalling $241.4 million, contributed 19.7% of the territory’s total revenues. The economy remains relatively diversified. Along with a vibrant tourism industry, rum exports, trade, and services will be major income sources in future years.'
    case 'Wallis and Futuna':
      return 'The Futuna island group was discovered by the Dutch in 1616 and Wallis by the British in 1767, but it was the French who declared a protectorate over the islands in 1842, and took official control of them between 1886 and 1888. Notably, Wallis and Futuna was the only French colony to side with the Vichy regime during World War II, a phase that ended in May of 1942 with the arrival of 2,000 American troops. In 1959, the inhabitants of the islands voted to become a French overseas territory and officially assumed that status in 1961. In 2003, Wallis and Futuna\'s designation changed to that of an overseas collectivity. The economy is limited to traditional subsistence agriculture, with about 80% of labor force earnings coming from agriculture (coconuts and vegetables), livestock (mostly pigs), and fishing. However, roughly 70% of the labor force is employed in the public sector, although only about a third of the population is in salaried employment. Revenues come from French Government subsidies, licensing of fishing rights to Japan and South Korea, import taxes, and remittances from expatriate workers in New Caledonia.'
    case 'Western Sahara':
      return 'Western Sahara is a non-self-governing territory on the northwest coast of Africa bordered by Morocco, Mauritania, and Algeria. After Spain withdrew from its former colony of Spanish Sahara in 1976, Morocco annexed the northern two-thirds of Western Sahara and claimed the rest of the territory in 1979, following Mauritania\'s withdrawal. A guerrilla war with the Polisario Front contesting Morocco\'s sovereignty ended in a 1991 cease-fire and the establishment of a UN peacekeeping operation. As part of this effort, the UN sought to offer a choice to the peoples of Western Sahara between independence (favored by the Polisario Front) or integration into Morocco. A proposed referendum on the question of independence never took place due to lack of agreement on voter eligibility. The approximately 1,600 km- (almost 1,000 mi-) long defensive sand berm, built by the Moroccans from 1980 to 1987 and running the length of the territory, continues to separate the opposing forces, with Morocco controlling the roughly three-quarters of the territory west of the berm. There are periodic ethnic tensions between the native Sahrawi population and Moroccan immigrants. Morocco maintains a heavy security presence in the territory.'
    case 'Yemen':
      return 'Yemen is not considered a regional financial center. The financial system in Yemen is underdeveloped, and the extent of money laundering is not well known. The government’s collapse and Houthi ascendancy to control much of the country render Yemen vulnerable to money laundering and other financial abuses, including terrorism financing. The profitability of the smuggling of goods and contraband has led to a large informal economy in Yemen. Criminal proceeds in Yemen tend to emanate from foreign criminal activity, including smuggling by criminal networks and terrorist groups operating locally. There have been a number of U.S. investigations of qat (a mild narcotic) smuggling from Yemen and East Africa into the United States, with profits laundered and repatriated via hawala networks. The ongoing conflict in Yemen has greatly reduced U.S. government investigative efforts and cooperation with Yemeni authorities.'
    case 'Zambia':
      return 'Zambia is not a major financial center. The proceeds of narcotics transactions, bribery, and public corruption are the main sources of laundered funds. Wildlife trafficking, human trafficking, and the timber trade also are problems. Banks, real estate agents, insurance companies, casinos, and law firms are the institutions most commonly used to launder money. Criminals in Zambia have used structuring, currency exchanges, monetary instruments, gaming, under-valuing assets, procurement fraud, and front companies to launder illicit proceeds. Other devices include securities, debit and credit cards, bulk cash smuggling, wire transfers, false currency declarations, and trade-based money laundering (TBML) via the purchase of luxury goods, such as vehicles and real estate, and abusive trade mis-invoicing of general trade goods. Zambia is not considered an offshore financial center. The Government of the Republic of Zambia is developing a number of multi-facility economic zones that are similar to free trade zones.'
    case 'Zimbabwe':
      return 'Zimbabwe is not a regional financial center, but it does face problems related to money laundering and corruption. Serious financial crime in Zimbabwe generally appears in the form of various violations of exchange control rules; underground banking; cross-border crime; organized syndicates, both domestic and international; non-transparency in diamond production receipts; and increased cooperation among criminal networks and links with legal business activity, resulting in corruption and bribery. Regulatory and enforcement deficiencies in Zimbabwe’s AML/CFT regime expose the country to illicit finance risks, but there are no reliable data as to the actual extent of the problem. Commercial banks, building societies, moneylenders, insurance brokers, realtors, and lawyers in Zimbabwe are all vulnerable to exploitation by money launderers. The United States, Canada, Australia, and the EU have imposed targeted financial sanctions and travel restrictions on some political leaders and a limited number of private companies and state-owned enterprises for complicity in human rights abuses or for undermining democratic processes or institutions in Zimbabwe.'
    default:
      return
  }
}

function CountrySource (props) {
  switch (props) {
    case
    'Afghanistan':
      return 'KnowYourCountry Limited'
    case
    'Albania':
      return 'KnowYourCountry Limited'
    case
    'Algeria':
      return 'KnowYourCountry Limited'
    case
    'American Samoa':
      return 'Central Intelligence Agency'
    case
    'Andorra':
      return 'KnowYourCountry Limited'
    case
    'Angola':
      return 'KnowYourCountry Limited'
    case
    'Anguilla':
      return 'KnowYourCountry Limited'
    case
    'Antarctica':
      return 'Central Intelligence Agency'
    case
    'Antigua and Barbuda':
      return 'KnowYourCountry Limited'
    case
    'Argentina':
      return 'KnowYourCountry Limited'
    case
    'Armenia':
      return 'KnowYourCountry Limited'
    case
    'Aruba':
      return 'KnowYourCountry Limited'
    case
    'Australia':
      return 'KnowYourCountry Limited'
    case
    'Austria':
      return 'KnowYourCountry Limited'
    case
    'Azerbaijan':
      return 'KnowYourCountry Limited'
    case
    'Bahamas':
      return 'KnowYourCountry Limited'
    case
    'Bahrain':
      return 'KnowYourCountry Limited'
    case
    'Bangladesh':
      return 'KnowYourCountry Limited'
    case
    'Barbados':
      return 'KnowYourCountry Limited'
    case
    'Belarus':
      return 'KnowYourCountry Limited'
    case
    'Belgium':
      return 'KnowYourCountry Limited'
    case
    'Belize':
      return 'KnowYourCountry Limited'
    case
    'Benin':
      return 'KnowYourCountry Limited'
    case
    'Bermuda':
      return 'KnowYourCountry Limited'
    case
    'Bhutan':
      return 'KnowYourCountry Limited'
    case
    'Bolivia':
      return 'KnowYourCountry Limited'
    case
    'Bonaire, Saint Eustatius and Saba':
      return 'Central Intelligence Agency'
    case
    'Bosnia and Herzegovina':
      return 'KnowYourCountry Limited'
    case
    'Botswana':
      return 'KnowYourCountry Limited'
    case
    'Bouvet Island':
      return 'Central Intelligence Agency'
    case
    'Brazil':
      return 'KnowYourCountry Limited'
    case
    'British Indian Ocean Territory':
      return 'Central Intelligence Agency'
    case
    'Brunei':
      return 'KnowYourCountry Limited'
    case
    'Bulgaria':
      return 'KnowYourCountry Limited'
    case
    'Burkina Faso':
      return 'KnowYourCountry Limited'
    case
    'Burma (Myanmar)':
      return 'KnowYourCountry Limited'
    case
    'Burundi':
      return 'KnowYourCountry Limited'
    case
    'Cambodia':
      return 'KnowYourCountry Limited'
    case
    'Cameroon':
      return 'KnowYourCountry Limited'
    case
    'Canada':
      return 'KnowYourCountry Limited'
    case
    'Cape Verde':
      return 'KnowYourCountry Limited'
    case
    'Cayman Islands':
      return 'KnowYourCountry Limited'
    case
    'Central African Republic':
      return 'KnowYourCountry Limited'
    case
    'Chad':
      return 'KnowYourCountry Limited'
    case
    'Chile':
      return 'KnowYourCountry Limited'
    case
    'China':
      return 'KnowYourCountry Limited'
    case
    'Christmas Island':
      return 'Central Intelligence Agency'
    case
    'Cocos (Keeling) Islands':
      return 'Central Intelligence Agency'
    case
    'Colombia':
      return 'KnowYourCountry Limited'
    case
    'Comoros':
      return 'KnowYourCountry Limited'
    case
    'Congo, Democratic Republic of the':
      return 'KnowYourCountry Limited'
    case
    'Congo, Republic of the':
      return 'KnowYourCountry Limited'
    case
    'Cook Islands':
      return 'KnowYourCountry Limited'
    case
    'Costa Rica':
      return 'KnowYourCountry Limited'
    case
    'Cte d\'Ivoire':
      return 'KnowYourCountry Limited'
    case
    'Croatia':
      return 'KnowYourCountry Limited'
    case
    'Cuba':
      return 'KnowYourCountry Limited'
    case
    'Curacao':
      return 'KnowYourCountry Limited'
    case
    'Cyprus':
      return 'KnowYourCountry Limited'
    case
    'Czech Republic':
      return 'KnowYourCountry Limited'
    case
    'Denmark':
      return 'KnowYourCountry Limited'
    case
    'Djibouti':
      return 'KnowYourCountry Limited'
    case
    'Dominica':
      return 'KnowYourCountry Limited'
    case
    'Dominican Republic':
      return 'KnowYourCountry Limited'
    case
    'Ecuador':
      return 'KnowYourCountry Limited'
    case
    'Egypt':
      return 'KnowYourCountry Limited'
    case
    'El Salvador':
      return 'KnowYourCountry Limited'
    case
    'Equatorial Guinea':
      return 'KnowYourCountry Limited'
    case
    'Eritrea':
      return 'KnowYourCountry Limited'
    case
    'Estonia':
      return 'KnowYourCountry Limited'
    case
    'Ethiopia':
      return 'KnowYourCountry Limited'
    case
    'Falkland Islands (Islas Malvinas)':
      return 'Central Intelligence Agency'
    case
    'Faroe Islands':
      return 'Central Intelligence Agency'
    case
    'Fiji':
      return 'KnowYourCountry Limited'
    case
    'Finland':
      return 'KnowYourCountry Limited'
    case
    'France':
      return 'KnowYourCountry Limited'
    case
    'French Guiana':
      return 'Central Intelligence Agency'
    case
    'French Polynesia':
      return 'Central Intelligence Agency'
    case
    'French Southern Territories':
      return 'Central Intelligence Agency'
    case
    'Gabon':
      return 'KnowYourCountry Limited'
    case
    'Gambia':
      return 'KnowYourCountry Limited'
    case
    'Georgia':
      return 'KnowYourCountry Limited'
    case
    'Germany':
      return 'KnowYourCountry Limited'
    case
    'Ghana':
      return 'KnowYourCountry Limited'
    case
    'Gibraltar':
      return 'KnowYourCountry Limited'
    case
    'Greece':
      return 'KnowYourCountry Limited'
    case
    'Greenland':
      return 'Central Intelligence Agency'
    case
    'Grenada':
      return 'KnowYourCountry Limited'
    case
    'Guadeloupe':
      return 'Central Intelligence Agency'
    case
    'Guam':
      return 'KnowYourCountry Limited'
    case
    'Guatemala':
      return 'KnowYourCountry Limited'
    case
    'Guernsey, C.I.':
      return 'KnowYourCountry Limited'
    case
    'Guinea':
      return 'KnowYourCountry Limited'
    case
    'Guinea-Bissau':
      return 'KnowYourCountry Limited'
    case
    'Guyana':
      return 'KnowYourCountry Limited'
    case
    'Haiti':
      return 'KnowYourCountry Limited'
    case
    'Heard and McDonald Islands':
      return 'Central Intelligence Agency'
    case
    'Holy See (Vatican City)':
      return 'KnowYourCountry Limited'
    case
    'Honduras':
      return 'KnowYourCountry Limited'
    case
    'Hong Kong':
      return 'KnowYourCountry Limited'
    case
    'Hungary':
      return 'KnowYourCountry Limited'
    case
    'Iceland':
      return 'KnowYourCountry Limited'
    case
    'India':
      return 'KnowYourCountry Limited'
    case
    'Indonesia':
      return 'KnowYourCountry Limited'
    case
    'Iran':
      return 'KnowYourCountry Limited'
    case
    'Iraq':
      return 'KnowYourCountry Limited'
    case
    'Ireland':
      return 'KnowYourCountry Limited'
    case
    'Isle of Man':
      return 'KnowYourCountry Limited'
    case
    'Israel':
      return 'KnowYourCountry Limited'
    case
    'Italy':
      return 'KnowYourCountry Limited'
    case
    'Jamaica':
      return 'KnowYourCountry Limited'
    case
    'Japan':
      return 'KnowYourCountry Limited'
    case
    'Jersey':
      return 'KnowYourCountry Limited'
    case
    'Jordan':
      return 'KnowYourCountry Limited'
    case
    'Kazakhstan':
      return 'KnowYourCountry Limited'
    case
    'Kenya':
      return 'KnowYourCountry Limited'
    case
    'Kiribati':
      return 'KnowYourCountry Limited'
    case
    'Korea, North':
      return 'KnowYourCountry Limited'
    case
    'Korea, South':
      return 'KnowYourCountry Limited'
    case
    'Kosovo':
      return 'KnowYourCountry Limited'
    case
    'Kuwait':
      return 'KnowYourCountry Limited'
    case
    'Kyrgyzstan':
      return 'KnowYourCountry Limited'
    case
    'Laos':
      return 'KnowYourCountry Limited'
    case
    'Latvia':
      return 'KnowYourCountry Limited'
    case
    'Lebanon':
      return 'KnowYourCountry Limited'
    case
    'Lesotho':
      return 'KnowYourCountry Limited'
    case
    'Liberia':
      return 'KnowYourCountry Limited'
    case
    'Libya':
      return 'KnowYourCountry Limited'
    case
    'Liechtenstein':
      return 'KnowYourCountry Limited'
    case
    'Lithuania':
      return 'KnowYourCountry Limited'
    case
    'Luxembourg':
      return 'KnowYourCountry Limited'
    case
    'Macau':
      return 'KnowYourCountry Limited'
    case
    'Macedonia':
      return 'KnowYourCountry Limited'
    case
    'Madagascar':
      return 'KnowYourCountry Limited'
    case
    'Malawi':
      return 'KnowYourCountry Limited'
    case
    'Malaysia':
      return 'KnowYourCountry Limited'
    case
    'Maldives':
      return 'KnowYourCountry Limited'
    case
    'Mali':
      return 'KnowYourCountry Limited'
    case
    'Malta':
      return 'KnowYourCountry Limited'
    case
    'Marshall Islands':
      return 'KnowYourCountry Limited'
    case
    'Martinique':
      return 'Central Intelligence Agency'
    case
    'Mauritania':
      return 'KnowYourCountry Limited'
    case
    'Mauritius':
      return 'KnowYourCountry Limited'
    case
    'Mayotte':
      return 'Central Intelligence Agency'
    case
    'Mexico':
      return 'KnowYourCountry Limited'
    case
    'Micronesia':
      return 'KnowYourCountry Limited'
    case
    'Moldova':
      return 'KnowYourCountry Limited'
    case
    'Monaco':
      return 'KnowYourCountry Limited'
    case
    'Mongolia':
      return 'KnowYourCountry Limited'
    case
    'Montenegro':
      return 'KnowYourCountry Limited'
    case
    'Montserrat':
      return 'KnowYourCountry Limited'
    case
    'Morocco':
      return 'KnowYourCountry Limited'
    case
    'Mozambique':
      return 'KnowYourCountry Limited'
    case
    'Namibia':
      return 'KnowYourCountry Limited'
    case
    'Nauru':
      return 'KnowYourCountry Limited'
    case
    'Nepal':
      return 'KnowYourCountry Limited'
    case
    'Netherlands':
      return 'KnowYourCountry Limited'
    case
    'Netherlands Antilles':
      return 'KnowYourCountry Limited'
    case
    'New Caledonia':
      return 'Central Intelligence Agency'
    case
    'New Zealand':
      return 'KnowYourCountry Limited'
    case
    'Nicaragua':
      return 'KnowYourCountry Limited'
    case
    'Niger':
      return 'KnowYourCountry Limited'
    case
    'Nigeria':
      return 'KnowYourCountry Limited'
    case
    'Niue':
      return 'KnowYourCountry Limited'
    case
    'Norfolk Island':
      return 'Central Intelligence Agency'
    case
    'Northern Mariana Islands':
      return 'Central Intelligence Agency'
    case
    'Norway':
      return 'KnowYourCountry Limited'
    case
    'Oman':
      return 'KnowYourCountry Limited'
    case
    'Pakistan':
      return 'KnowYourCountry Limited'
    case
    'Palau':
      return 'KnowYourCountry Limited'
    case
    'Palestinian Territories':
      return 'Central Intelligence Agency'
    case
    'Panama':
      return 'KnowYourCountry Limited'
    case
    'Panama Canal Zone':
      return 'Central Intelligence Agency'
    case
    'Papua New Guinea':
      return 'KnowYourCountry Limited'
    case
    'Paraguay':
      return 'KnowYourCountry Limited'
    case
    'Peru':
      return 'KnowYourCountry Limited'
    case
    'Philippines':
      return 'KnowYourCountry Limited'
    case
    'Pitcairn Islands':
      return 'Central Intelligence Agency'
    case
    'Poland':
      return 'KnowYourCountry Limited'
    case
    'Portugal':
      return 'KnowYourCountry Limited'
    case
    'Puerto Rico':
      return 'KnowYourCountry Limited'
    case
    'Qatar':
      return 'KnowYourCountry Limited'
    case
    'Reunion':
      return 'Central Intelligence Agency'
    case
    'Romania':
      return 'KnowYourCountry Limited'
    case
    'Russia':
      return 'KnowYourCountry Limited'
    case
    'Rwanda':
      return 'KnowYourCountry Limited'
    case
    'Saint Barthelemy':
      return 'Central Intelligence Agency'
    case
    'Saint Helena':
      return 'Central Intelligence Agency'
    case
    'Saint Kitts and Nevis':
      return 'KnowYourCountry Limited'
    case
    'Saint Lucia':
      return 'KnowYourCountry Limited'
    case
    'Saint Martin':
      return 'Central Intelligence Agency'
    case
    'Saint Pierre and Miquelon':
      return 'Central Intelligence Agency'
    case
    'Saint Vincent and the Grenadines':
      return 'KnowYourCountry Limited'
    case
    'Samoa':
      return 'KnowYourCountry Limited'
    case
    'San Marino':
      return 'KnowYourCountry Limited'
    case
    'Sao Tome and Principe':
      return 'KnowYourCountry Limited'
    case
    'Saudi Arabia':
      return 'KnowYourCountry Limited'
    case
    'Senegal':
      return 'KnowYourCountry Limited'
    case
    'Serbia':
      return 'KnowYourCountry Limited'
    case
    'Seychelles':
      return 'KnowYourCountry Limited'
    case
    'Sierra Leone':
      return 'KnowYourCountry Limited'
    case
    'Singapore':
      return 'KnowYourCountry Limited'
    case
    'Sint Maarten':
      return 'KnowYourCountry Limited'
    case
    'Slovakia':
      return 'KnowYourCountry Limited'
    case
    'Slovenia':
      return 'KnowYourCountry Limited'
    case
    'Solomon Islands':
      return 'KnowYourCountry Limited'
    case
    'Somalia':
      return 'KnowYourCountry Limited'
    case
    'South Africa':
      return 'KnowYourCountry Limited'
    case
    'South Georgia and the South Sandwich Islands':
      return 'Central Intelligence Agency'
    case
    'South Sudan':
      return 'KnowYourCountry Limited'
    case
    'Spain':
      return 'KnowYourCountry Limited'
    case
    'Sri Lanka':
      return 'KnowYourCountry Limited'
    case
    'Sudan':
      return 'KnowYourCountry Limited'
    case
    'Suriname':
      return 'KnowYourCountry Limited'
    case
    'Svalbard and Jan Mayen Islands':
      return 'Central Intelligence Agency'
    case
    'Swaziland':
      return 'KnowYourCountry Limited'
    case
    'Sweden':
      return 'KnowYourCountry Limited'
    case
    'Switzerland':
      return 'KnowYourCountry Limited'
    case
    'Syria':
      return 'KnowYourCountry Limited'
    case
    'Taiwan':
      return 'KnowYourCountry Limited'
    case
    'Tajikistan':
      return 'KnowYourCountry Limited'
    case
    'Tanzania':
      return 'KnowYourCountry Limited'
    case
    'Thailand':
      return 'KnowYourCountry Limited'
    case
    'Timor-Leste':
      return 'KnowYourCountry Limited'
    case
    'Togo':
      return 'KnowYourCountry Limited'
    case
    'Tokelau':
      return 'Central Intelligence Agency'
    case
    'Tonga':
      return 'KnowYourCountry Limited'
    case
    'Trinidad and Tobago':
      return 'KnowYourCountry Limited'
    case
    'Tunisia':
      return 'KnowYourCountry Limited'
    case
    'Turkey':
      return 'KnowYourCountry Limited'
    case
    'Turkmenistan':
      return 'KnowYourCountry Limited'
    case
    'Turks and Caicos Islands':
      return 'KnowYourCountry Limited'
    case
    'Tuvalu':
      return 'KnowYourCountry Limited'
    case
    'Uganda':
      return 'KnowYourCountry Limited'
    case
    'Ukraine':
      return 'KnowYourCountry Limited'
    case
    'United Arab Emirates':
      return 'KnowYourCountry Limited'
    case
    'United Kingdom':
      return 'KnowYourCountry Limited'
    case
    'United States':
      return 'KnowYourCountry Limited'
    case
    'United States Minor Outlaying Islands':
      return 'Central Intelligence Agency'
    case
    'Uruguay':
      return 'KnowYourCountry Limited'
    case
    'Uzbekistan':
      return 'KnowYourCountry Limited'
    case
    'Vanuatu':
      return 'KnowYourCountry Limited'
    case
    'Venezuela':
      return 'KnowYourCountry Limited'
    case
    'Vietnam':
      return 'KnowYourCountry Limited'
    case
    'Virgin Islands, British':
      return 'Central Intelligence Agency'
    case
    'Virgin Islands, U.S.':
      return 'KnowYourCountry Limited'
    case
    'Wallis and Futuna':
      return 'Central Intelligence Agency'
    case
    'Western Sahara':
      return 'Central Intelligence Agency'
    case
    'Yemen':
      return 'KnowYourCountry Limited'
    case
    'Zambia':
      return 'KnowYourCountry Limited'
    case
    'Zimbabwe':
      return 'KnowYourCountry Limited'
    default:
      return
  }
}

export { CountryInfo, CountrySource }
