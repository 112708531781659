import React from 'react'
import {
  Typography,
  makeStyles,
  Fab,
  useScrollTrigger,
  Zoom
} from '@material-ui/core'
import PropTypes from 'prop-types'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

function ScrollTop (props) {
  const { children } = props
  const classes = useStyles()

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Zoom in={useScrollTrigger()}>
      <div onClick={handleClick} role='presentation' className={classes.root}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func
}

export default function BackToTop (props) {
  return (
    <>
      <ScrollTop {...props}>
        <Fab
          variant='extended'
          color='primary'
          size='medium'
          aria-label='scroll back to top'
        >
          <Typography variant='overline'>Scroll To Top</Typography>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  )
}
