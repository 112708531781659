// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// Link is the primary method of switching 'web pages' inside of the application to another web page in the Main folder
//import { Link } from "react-router-dom"
// We use the Material-UI React library as our front end design framework
import { Grid } from "@material-ui/core"
//import { Typography } from "@material-ui/core"
//import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
//import MenuIcon from "@material-ui/icons/Menu"
// We load our application css by only bringing in the styles needed for the current page
import { homeStyles } from "../Config/theme"

// The core function for the landing page of an authenticated user - THIS IS A STUB AND HAS NOT BEEN DEVELOPED - Low hanging fruit
export default function Home() {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = homeStyles()()
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={6}
        className={classes.root}
      >
        <Grid item>
          {/* <Typography variant="body1">
            Navigate to a client by using the menu above (<MenuIcon />) or from
            The{" "}
            <Link color="inherit" to="/clients">
              <AccountBalanceIcon />
              Clients
            </Link>{" "}
            page.
          </Typography> */}
        </Grid>
      </Grid>
    </>
  )
}
