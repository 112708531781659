// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState, useContext } from 'react' // Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useParams } from 'react-router-dom'
// We use Auth0 as our current authentication and authorization solution
import { AuthContext } from '../Auth'
// All interactions with the remote server are driven from this file
import { useFileUploads, CreateFileUploadRows, DeleteRow, handleDownloadRequest } from '../Utils/api'
// We use the Material-UI React library as our front end design framework
import { Grid, Button, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// We load our application css by only bringing in the styles needed for the current page
import { fileUploadsStyles } from '../Config/theme'
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from '../Components/Loading/LoadingRing'
// We use Material-Table for CRUD operations on application resources
import MaterialTable from 'material-table'
import DataTableIcons from '../Components/Icons/DataTableIcons'
// The following are HTML/CSS components that are present on this page
import FileUploader from '../Components/FileUpload/FileUploader'
import { colors } from '../Config/colors'


// The core function for the File Uploads "web page"
export default function FileUploads () {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = fileUploadsStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // When hasFile = true, the fileUploader does not display
  const [hasFile, setHasFile] = useState(false)
  // The CRUD operations are handled by Material-Table which has its entire state driven by this gridData variable. You can
  // force a rerender of the HTML and CSS by using the setGridData function
  // The long term persistent data retrieved from your database goes into the data attribute
  // The display information for the table goes in the columns attribute. You need to look in the npm modules to see the default
  // HTML and CSS used by Material-Table. If you see a render: attribute that will contain custom HTML and CSS written by our
  // team to override the default Material-Table HTML and CSS
  const [gridData, setGridData] = useState({
    data: [],
    columns: [
      {
        title: 'Original Filename',
        field: 'original_filename',
        grouping: false
      }
    ]
  })
  // Auth context allows us to access our headers and authenticate with the API when calling handleSubmitSchemaMatch
  const contextValue = useContext(AuthContext)
  // Material-Table options
  const options = {
    headerStyle: {
      backgroundColor: colors.MIDNIGHT_BLUE.hex,
      color: colors.BACKGROUND_GRAY.hex
    },
    sorting: true,
    rowHover: true,
    actionsColumnIndex: -1,
    filterType: 'dropdown',
    pageSize: 10,
    exportButton: true,
    addRowPosition: 'first'
  }
  // Material-Table names and labels overrides
  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}'
    },
    toolbar: {
      nRowsSelected: '{0} row(s) selected'
    },
    header: {
      actions: 'Actions'
    },
    body: {
      emptyDataSourceMessage: 'No file uploads to display',
      filterRow: {
        filterTooltip: 'Filter'
      }
    }
  }
  // Material-Table CRUD communication with the API
  const handleFilesUploaded = (fileUploads) => {
    CreateFileUploadRows(
      gridData,
      setGridData,
      setHasFile,
      'fileUploads',
      fileUploads
    )()
  }
  const onRowDelete = DeleteRow(gridData, setGridData, 'fileUploads', setHasFile)
  // Material-Table CRUD actions configuration
  const editable = {
    isDeletable: (rowData) => true,
    onRowDelete: onRowDelete
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useFileUploads(params, setGridData, setIsLoading, setHasFile)

  console.log(gridData.data)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='flex-start'
            spacing={3}
            style={{ width: '100%', margin: 'auto' }}
          >
            <Grid item xs={6}>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/`,
                  state: {
                    clientId: params.clientId
                  }
                }}
              >
                <Button
                  title='Back to Engagements'
                  className={classes.leftSideNavigationButton}
                >
                  <ArrowBackIcon />
                  {'Back to Engagements'}
                </Button>
              </Link>
            </Grid>

            <Grid item xs={6}>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/countries`,
                  state: {
                    clientId: params.clientId,
                    engagementId: params.engagementId
                  }
                }}
              >
                <Button
                  title='Countries'
                  className={classes.rightSideNavigationButton}
                >
                  {'Navigate to Editing Country Profiles'}
                  <ArrowForwardIcon />
                </Button>
              </Link>
            </Grid>
            {hasFile ? <Grid item xs={12}>
              <Typography>There is already a file uploaded. Delete the current file to replace it with a different file.</Typography>
            </Grid> : (
              <>
                <Grid item xs={12}>
                  <Grid item className='dashboard-step-one-row active'>
                    <FileUploader
                      engagementId={params.engagementId}
                      setGridData={setGridData}
                      gridData={gridData}
                      handleFilesUploaded={handleFilesUploaded}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ maxWidth: '100%' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleDownloadRequest(contextValue, params)}
                    >
                      {'Download Template'}
                    </Button>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                  isLoading={isLoading}
                  data={gridData.data}
                  icons={DataTableIcons}
                  title='Country Information Uploaded'
                  columns={gridData.columns}
                  options={options}
                  localization={localization}
                  editable={editable}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </AuthContext.Consumer>
  )
}
