import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { green } from "@material-ui/core/colors"
import { Button, Fab, Tooltip } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import SaveIcon from "@material-ui/icons/Save"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default function AnimatedButton(props) {
  const classes = useStyles()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.success,
  })

  return (
    <div className={classes.root}>
      {props.fab ? (
        <div className={classes.wrapper}>
          <Fab
            aria-label="save"
            color="primary"
            className={buttonClassname}
            onClick={props.onClick}
          >
            {props.success ? <CheckIcon /> : <SaveIcon />}
          </Fab>
          {props.loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Tooltip title={props.tooltip}>
            <span>
              <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                onClick={props.onClick}
                startIcon={props.iconStyle}
                disabled={props.loading || props.disabled}
              >
                {props.success ? "Downloaded!" : props.buttonName}
              </Button>
            </span>
          </Tooltip>

          {props.loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      )}
    </div>
  )
}
