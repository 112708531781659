import styled from 'styled-components'
import React from 'react'
import { Typography, Link, Box } from '@material-ui/core'

const Footer = styled.div`
  text-align: center;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }
  a:hover {
    text-decoration: underline;
  }
`

function Copyright () {
  return (
    <Box pt={4}>
      <Footer>
        <Typography variant='body2' color='textSecondary' align='center'>
          {'Copyright © '}
          <Link color='inherit' href='https://kaufmanrossin.com/'>
            {'Kaufman Rossin'}
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Footer>
    </Box>
  )
}

export default Copyright
