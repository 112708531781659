// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState, useEffect } from 'react'
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useLocation } from 'react-router-dom'
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from '../../Auth'
// We use the Material-UI React library as our front end design framework
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  List,
  Menu,
  MenuItem,
  Toolbar,
  CssBaseline,
  ListSubheader,
  IconButton,
  ListItemIcon
} from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
// We load our application css by only bringing in the styles needed for the current page
import { topNavWithSideDrawerStyles } from '../../Config/theme'
// The following are HTML/CSS components that are present on this page
import ClientListItem from './ClientListItem'
// The application and firm logos
import krLogo from '../../assets/images/firm_logo.png'
import geoLogo from '../../assets/images/GeoRI_logo.png'

// This component serves as the navigation wrapper for all of the components that render on every single web page
export default function TopNavWithSideDrawer (props) {
  // Access the current location so that we can open the side navigation bar for users at the root of the application
  const location = useLocation()
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = topNavWithSideDrawerStyles()()
  // This element keeps track of where to attach the profile menu that pops out when you click on your email address
  const [anchorEl, setAnchorEl] = useState(null)
  // This variable tracks whether the sidebar is currently in open or close position and is used for conditional rendering
  const [open, setOpen] = useState(false)
  // Keeps track of whether the profile menu is open or not
  const isMenuOpen = Boolean(anchorEl)

  // When the user is at the root of the application we want to open the side navigation bar by default, otherwise it should stay closed
  useEffect(() => {
    if (location.pathname === '/') {
      setOpen(true)
    }
  }, [location])
  // Based on whether the sidebar is open or closes, set it to the opposite giving us one controlling function we can pass aroudn to all
  // of our links and other triggers
  const handleDrawerClick = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }
  // Helper functions for setting the state of the profile menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <>
      <AuthContext.Consumer>
        {({ logout, auth }) => (
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position='fixed' className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleDrawerClick}
                  edge='start'
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>

                <Link to='/'>
                  <img
                    src={krLogo}
                    alt='kaufman rossin'
                    className={classes.logo}
                  />
                </Link>

                <MenuItem>
                  <ListItemIcon>
                    {/* <Link to="/help"> */}
                    {/*  <ListItem button key="Help"> */}
                    {/*    <ListItemIcon color="inherit"> */}
                    {/* <Button */}
                    {/*  id="accountButton" */}
                    {/*  variant="outlined" */}
                    {/*  className={classes.button} */}
                    {/*  startIcon={<HelpIcon />} */}
                    {/* > */}
                    {/*  {'Help'} */}
                    {/* </Button> */}
                    {/*    </ListItemIcon> */}
                    {/*  </ListItem> */}
                    {/* </Link> */}
                  </ListItemIcon>
                </MenuItem>

                <div className={classes.grow} />

                <MenuItem className={classes.statMenu}>
                  <Link to='/'>
                    <img
                      src={geoLogo}
                      alt='GeoRI'
                      className={classes.statLogo}
                    />
                  </Link>
                </MenuItem>

                <div className={classes.grow} />

                <ListItemIcon
                  aria-label='account of current user'
                  aria-controls='account-menu'
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                >
                  <label htmlFor='accountButton'>
                    <Button
                      id='accountButton'
                      variant='outlined'
                      className={classes.button}
                      startIcon={<AccountCircleIcon />}
                    >
                      {auth.user.email}
                    </Button>
                  </label>
                </ListItemIcon>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  id='account-menu'
                  keepMounted
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={logout}>Log Out</MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
            <Drawer
              variant='persistent'
              className={classes.drawer}
              anchor='left'
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <Divider />
              <List
                component='nav'
                aria-labelledby='nested-list-subheader'
                subheader={
                  <ListSubheader
                    onClick={handleDrawerClick}
                    component={Link}
                    to='/clients'
                    id='nested-list-subheader'
                    className={classes.sidebarSubHeader}
                    disableSticky
                  >
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.manageResourceButton}
                    >
                      Manage Clients
                    </Button>
                  </ListSubheader>
                }
                className={classes.sideBarList}
              >
                {props.data.clients.map((client, i) => {
                  return (
                    <ClientListItem
                      key={i}
                      client={client}
                      handleDrawerClick={handleDrawerClick}
                    />
                  )
                })}
              </List>
            </Drawer>
          </div>
        )}
      </AuthContext.Consumer>
    </>
  )
}
